import classNames from "classnames";
import moment from 'moment';
import momentZone from "moment-timezone";
import axios from 'axios';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import * as appActions from '../../reducers/app';
import arrowIcon from '../../resources/icons/up-arrow.svg';
import copyIcon from '../../resources/icons/copy.svg';
import deleteIcon from '../../resources/icons/delete.svg';
import downloadIcon from '../../resources/icons/download.svg';
import editIcon from '../../resources/icons/edit_icon.svg';
import linkIcon from '../../resources/icons/link.svg';
import moreIcon from '../../resources/icons/more-vertical.svg';
import statsIcon from '../../resources/icons/statistics.svg';
import closeIcon from '../../resources/icons/x.svg';
import firebaseClient from '../../utils/FirebaseClient';
import {
    getQueryVariable,
    getTextParticipant,
    withRouter,
    checkRecordingAvailable,
    checkRecordingProcessing,
    getTenantId
} from '../../utils/HelperFunctions';
import Analytics from "../Widget/Analytics";
import ClickOutside from '../Widget/ClickOutside';
import Loader from '../Widget/Loader';
import Modal from "./Modal";
import config from '../../config';
import Spinner from 'react-bootstrap/Spinner';
import fileDownload from "js-file-download";

class MyEvents extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.state = {
            tab: 'upcoming',
            moreId: null,
            mobileMoreId: null,
            items: [],
            upcomingItems: [],
            completedItems: [],
            loadedItems: false,
            analyticsItem: null,
            columnToSort: null,
            columnSortDirection: 'asc',
            filterStr: '',
            openModal: false,
            errorText: '',
            modalButtonDisabled: false
        }

        this.refContent = [];
        this.refRecording = [];
        this.handleColumnSort = this.handleColumnSort.bind(this);
        this.handleFilterTable = this.handleFilterTable.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    handleSetTab(value) {
        const { user, loadedPublicData } = this.props;
        this.setState({
            tab: value,
            moreId: null,
            mobileMoreId: null
        }, () => {
            if (user && user.eventAdmin && loadedPublicData) {
                firebaseClient.getUserPublicData();
                this.parsedItems();
            }
            this.handleScrollToId(value);
        });
    }

    parsedItems() {
        const { tab } = this.state;
        const { events, streams, user } = this.props;

        let parsedUpcoming = [], parsedCompleted = [], now = moment().valueOf();

        let array = Object.values(events.concat(streams).reduce((r, o) => {
            r[o.id] = o;
            return r;
        }, {}));

        let compareUpcoming = (a, b) => {
            if (a.startDate < b.startDate) {
                return -1;
            }
            if (a.startDate > b.startDate) {
                return 1;
            }
            return 0;
        }

        let compareCompleted = (a, b) => {
            if (a.startDate > b.startDate) {
                return -1;
            }
            if (a.startDate < b.startDate) {
                return 1;
            }
            return 0;
        }

        if (array && array.length) {
            array.map((item) => {
                if (!item || (item.status === 'deleted' || item.status === 'cancelled'))
                    return null;
                let eventEndDate = moment(new Date(item.startDate ? item.startDate : moment().unix())).add(parseInt(item.duration), 'hours').valueOf();
                if ((eventEndDate < now && item.status && item.status !== 'started') || (item.status && item.status === 'ended')) {
                    parsedCompleted.push(item);
                } else {
                    parsedUpcoming.push(item);
                }
                return item;
            });
        }

        if (parsedUpcoming && parsedUpcoming.length) {
            parsedUpcoming.sort(compareUpcoming);
        }

        if (parsedCompleted && parsedCompleted.length) {
            parsedCompleted.sort(compareCompleted);
        }

        this.setState({
            upcomingItems: parsedUpcoming,
            completedItems: parsedCompleted,
            items: tab && tab === 'upcoming' ? parsedUpcoming : tab && tab === 'completed' ? parsedCompleted : []
        }, () => {
            if (user) {
                this.setState({
                    loadedItems: true
                });
            }
        });
    }

    componentDidMount() {
        const { user, userLoaded, showSignIn, loadedPublicData, navigate } = this.props;

        if (!user && userLoaded && showSignIn) {
            showSignIn(true)
        }

        let tab = getQueryVariable('t') || 'upcoming';

        if (tab) {
            this.handleSetTab(tab);
        }

        if (user && user.eventAdmin && loadedPublicData) {
            this.parsedItems();
        } else if (user && !user.eventAdmin && navigate) {
            navigate('/signup');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { events, streams, user, location, userLoaded, showSignIn, loadedPublicData, navigate } = this.props;

        if (!user && userLoaded && prevProps.userLoaded !== userLoaded && showSignIn) {
            showSignIn(true)
        }

        let tab = getQueryVariable('t');

        if (tab && location && location.search && location.search !== prevProps.location.search) {
            this.handleSetTab(tab);
        }

        if ((((events && prevProps.events.length !== events.length) || (streams && prevProps.streams.length !== streams.length) || (loadedPublicData && prevProps.loadedPublicData !== loadedPublicData)) && user && user.eventAdmin)) {
            this.parsedItems();
        }

        if (user && prevProps.user !== user) {
            if (user.eventAdmin && loadedPublicData) {
                this.parsedItems();
            } else if (!user.eventAdmin && navigate) {
                navigate('/signup');
            }
        }
    }

    handleScrollToId(id) {
        if (id) {
            let el = document.getElementById(id);

            if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            }
        }
    }

    handleCopy(event, type, ref) {
        const { user } = this.props;

        let tenantId = getTenantId(user);

        if (event && type) {
            if (ref) {
                ref.disabled = true;
                ref.id = 'disabled';
            }
            firebaseClient.getCopyLink(event, type, tenantId).then((link) => {
                if (link) {
                    ReactTooltip.show(ref);
                    navigator.clipboard.writeText(link);
                    if (ref) {
                        ref.disabled = false;
                        ref.id = '';
                    }
                    setTimeout(() => {
                        ReactTooltip.hide(ref);
                    }, 2000);
                }
            });
        }
    }

    handleDeleteEvent(e, id) {
        e.preventDefault();

        const { openModal } = this.state;
        const { t } = this.props;

        this.setState({
            modalButtonDisabled: true,
        });
        firebaseClient.deleteEvent(id).then(result => {
            if (result) {
                firebaseClient.getUserPublicData().then(() => {
                    this.setState({
                        openModal: false,
                        deleteItemId: "",
                        successText: "",
                        errorText: "",
                        modalButtonDisabled: false
                    });
                });
            }
            else if (openModal) {
                this.setState({
                    errorText: t('delete_event_error_1'),
                });
            }
        });
    }

    openLinks(e, item, ref, type = 'recording') {
        e.preventDefault();
        if (item && item.id && checkRecordingAvailable(item)) {
            if (ref) {
                ref.disabled = true;
                ref.id = 'disabled';
            }
            firebaseClient.getVideoLinks(item, true, true).then((res) => {
                if (res && res.length && res !== 'processing' && res !== 'error') {
                    res.map((video) => {
                        if (video && video.url && video.newRecording) {
                            console.log('VIDEO', video);
                            if (type === 'audio' && video.audioLink) {
                                axios.get(video.audioLink, {
                                    responseType: 'blob',
                                }).then(res => {
                                    fileDownload(res.data, `${item.eventName}${item.startDate ? `(${moment(new Date(item.startDate)).format('MM-DD-YYYY')})` : ''}.mp3`)
                                });
                            } else if (type === 'recording') {
                                axios.get(video.url, {
                                    responseType: 'blob',
                                }).then(res => {
                                    fileDownload(res.data, `${item.eventName}${item.startDate ? `(${moment(new Date(item.startDate)).format('MM-DD-YYYY')})` : ''}.mp4`)
                                });
                            }
                        } else {
                            window.open(video.url);
                        }
                        return video;
                    });

                    if (ref) {
                        ref.disabled = false;
                        ref.id = '';
                    }
                } else {
                    if (ref) {
                        ref.disabled = false;
                        ref.id = '';
                    }
                }
            }).catch((e) => {
                console.error(e);
                if (ref) {
                    ref.disabled = false;
                    ref.id = '';
                }
            });
        }
    }

    handleShowAnalytics(e, item) {
        e.preventDefault();
        if (item && item.id) {
            this.setState({
                analyticsItem: item
            });
        }
    }

    checkAnalytics() {
        const { user } = this.props;

        let retValue = false;

        if (user && user.eventAdmin && ((user.subscription && user.subscription.event_analytics) || (!user.subscription))) {
            retValue = true;
        }

        return retValue;
    }

    checkRecording() {
        const { user } = this.props;

        let retValue = false;

        if (user && user.eventAdmin && ((user.subscription && user.subscription.event_recording) || (!user.subscription))) {
            retValue = true;
        }

        return retValue;
    }

    handleColumnSort(fieldName) {
        this.setState(state => ({
            columnToSort: fieldName,
            columnSortDirection: state.columnToSort === fieldName && state.columnSortDirection === 'asc' ? "desc" : "asc"
        }), () => {
            const { items, columnToSort, columnSortDirection } = this.state;

            let sortedItems = [...items];

            if (columnToSort !== null) {
                sortedItems.sort((a, b) => {
                    if (typeof a[columnToSort] === 'number' | typeof a[columnToSort] === 'number') {
                        if (columnSortDirection === 'asc') {
                            return new Date(parseInt(a[columnToSort] * 1000)) - new Date(parseInt(b[columnToSort] * 1000))
                        }
                        else {
                            return new Date(parseInt(b[columnToSort]) * 1000) - new Date(parseInt(a[columnToSort] * 1000))
                        }
                    }
                    if (a[columnToSort] < b[columnToSort]) {
                        return columnSortDirection === 'asc' ? 1 : -1;
                    }
                    if (a[columnToSort] > b[columnToSort]) {
                        return columnSortDirection === 'desc' ? 1 : -1;
                    }
                    return 0;
                });
            }

            this.setState({
                items: sortedItems
            });
        });
    }

    handleFilterTable(e) {
        this.setState({
            filterStr: e.target.value,
        });
    }

    showModal(e, host) {
        e.preventDefault();
        this.setState({
            openModal: true,
            deleteItemId: host.id,
            successText: "",
            errorText: ""
        });
    };

    hideModal(e) {
        e.preventDefault();
        this.setState({
            openModal: false,
            deleteItemId: "",
            successText: "",
            errorText: "",
            modalButtonDisabled: false
        });
    };

    render() {
        const { tab, moreId, errorText, modalButtonDisabled, columnToSort, columnSortDirection, mobileMoreId,
            filterStr, items, upcomingItems, completedItems, loadedItems, analyticsItem, openModal, deleteItemId } = this.state;
        const { t, user } = this.props;

        return (
            <div>
                {user && user.eventAdmin && loadedItems ?
                    <div className='events-wrapper'>
                        <div className='events-table-wrapper'>
                            <div className='events-table-label'>
                                <Link to={`/my-events?t=upcoming`}>
                                    <button id='upcoming' className={classNames('btn btn-table-label', { 'active': tab === 'upcoming' })}>
                                        {t('upcoming_e')}
                                        <span>{` (${upcomingItems.length})`}</span>
                                    </button>
                                </Link>
                                <Link to={`/my-events?t=completed`}>
                                    <button id='completed' className={classNames('btn btn-table-label', { 'active': tab === 'completed' })}>
                                        {t('completed_e')}
                                        <span>{` (${completedItems.length})`}</span>
                                    </button>
                                </Link>
                                <div className='right-buttons'>
                                    {user && user.subscription && user.subscription.type ?
                                        <a
                                            href={
                                                user.subscription.type === 'pro' ?
                                                    `https://${config.homeSiteDomain}/pro-admin-guides`
                                                    :
                                                    user.subscription.type === 'enterprise' ?
                                                        `https://${config.homeSiteDomain}/enterprise-admin-guides`
                                                        :
                                                        `https://${config.homeSiteDomain}/explorer-admin-guides`
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className='btn btn-border-line'
                                        >
                                            ?
                                        </a>
                                        : null}
                                    {user && user.eventAdmin ?
                                        <Link to="/scheduler">
                                            <button className='btn btn-border-line'>
                                                {t('create_event')}
                                            </button>
                                        </Link>
                                        : null
                                    }
                                </div>
                            </div>
                            <input
                                placeholder={t('search')}
                                type="text"
                                value={filterStr}
                                onChange={this.handleFilterTable}
                                className="input-search"
                            />
                            {user.subscription && user.subscription.type && user.subscription.type === 'explorer' ?
                                <p className='event-table-p'>
                                    <Link to="/my-subscription">{`${t('events_p1')} ${t('pro')}`}</Link>
                                    {` ${t('events_p2')}`}
                                </p>
                                : null}
                            {items && items.filter(item => item && item.title && item.title.toLowerCase && item.title.toLowerCase().includes(this.state.filterStr.toLowerCase())).length ?
                                <table className={classNames("events-table", { "subscription": user.subscription && user.subscription.type && user.subscription.type === 'explorer' })}>
                                    <thead>
                                        <tr>
                                            <th className='date-time'>
                                                <button type="button" className={columnToSort === 'date' ? `sort-button ${columnSortDirection} active` : `sort-button asc`} onClick={() => this.handleColumnSort('date')}>
                                                    {t('date_and_time')}
                                                    <img
                                                        src={arrowIcon}
                                                        loading='lazy'
                                                        alt='clone'
                                                        className='sort-button--icon'
                                                    />
                                                </button>
                                            </th>
                                            <th className='event-name'>
                                                <button type="button" className={columnToSort === 'title' ? `sort-button ${columnSortDirection} active` : `sort-button asc`} onClick={() => this.handleColumnSort('title')}>
                                                    {t('event_title')}
                                                    <img
                                                        src={arrowIcon}
                                                        loading='lazy'
                                                        alt='clone'
                                                        className='sort-button--icon'
                                                    />
                                                </button>
                                            </th>
                                            {tab === 'upcoming' ? <th className='expected'>
                                                <button type="button" className={columnToSort === 'participants' ? `sort-button ${columnSortDirection} active` : `sort-button asc`} onClick={() => this.handleColumnSort('participants')}>
                                                    {t('audience_size')}
                                                    <img
                                                        src={arrowIcon}
                                                        loading='lazy'
                                                        alt='clone'
                                                        className='sort-button--icon'
                                                    />
                                                </button>
                                            </th> : null}
                                            <th className='hosts'>
                                                <button type="button" className={columnToSort === 'hosts.email' ? `sort-button ${columnSortDirection} active` : `sort-button asc`} onClick={() => this.handleColumnSort('hosts.email')}>
                                                    {t('host_s')}
                                                    <img
                                                        src={arrowIcon}
                                                        loading='lazy'
                                                        alt='clone'
                                                        className='sort-button--icon'
                                                    />
                                                </button>
                                            </th>
                                            <th className={classNames('expected', { 'disabled': !this.checkAnalytics() })}>{t('attendee_analytics')}</th>
                                            <th className='edit'>{t('clone')}</th>
                                            {tab === 'upcoming' ? <th className='edit'>{t('edit_event')}</th> : null}
                                            {tab === 'upcoming' ? <th className='copy-presenter'>{t('copy_host_link')}<br /><label>{t('copy_host_link_prefix')}</label></th> : null}
                                            {tab === 'upcoming' ? <th className='copy-audience'>{t('copy_audience_link')}</th> : null}
                                            {tab === 'completed' ? <th className={classNames('download', { 'disabled': !this.checkRecording() })}>{t('recording')}</th> : null}
                                            {tab === 'upcoming' ? <th className='delete-event'>{t('delete_event')}</th> : null}
                                            <th className='more'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.filter(item => {
                                            return item.title.toLowerCase().includes(this.state.filterStr.toLowerCase())
                                        }).map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td className='date-time'>
                                                        {moment(new Date(item.startDate ? item.startDate : moment().unix())).format('L')},
                                                        <br />
                                                        {moment(new Date(item.startDate ? item.startDate : moment().unix())).format('hh:mm a')}, {moment.tz(momentZone.tz.guess()).zoneAbbr()}
                                                    </td>
                                                    <td className='event-name'>
                                                        <Link to={`/event/${item.id}`}>
                                                            {item.title}
                                                        </Link>
                                                    </td>
                                                    {tab === 'upcoming' ? <td className='expected'>{`< ${getTextParticipant(item.participants)}`}</td> : null}
                                                    <td className='hosts'>
                                                        {tab === 'completed' ?
                                                            item.stats && item.stats.users && Object.values(item.stats.users).length ?
                                                                Object.values(item.stats.users).map((item, index) => {
                                                                    if (item && item.role && item.role === 'presenter') {
                                                                        return (
                                                                            <p key={index}>{item.name || item.email}</p>
                                                                        )
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })
                                                                : null
                                                            : tab === 'upcoming' ?
                                                                item.hosts && item.hosts.length ?
                                                                    item.hosts.map((item, index) => {
                                                                        return (
                                                                            <p key={index}>{item.username || item.mail || item.email}</p>
                                                                        )
                                                                    })
                                                                    : null
                                                                : null}
                                                    </td>
                                                    <td className='expected completed'>
                                                        {this.checkAnalytics() ?
                                                            <img
                                                                src={statsIcon}
                                                                loading='lazy'
                                                                alt='download'
                                                                className='card-icon'
                                                                onClick={(e) => this.handleShowAnalytics(e, item)}
                                                            />
                                                            : null}
                                                    </td>
                                                    <td className='edit'>
                                                        <Link to={`/scheduler/${item.id}?o=${window.btoa(item.id)}`}>
                                                            <img
                                                                src={copyIcon}
                                                                loading='lazy'
                                                                alt='clone'
                                                                className='edit-icon'
                                                            />
                                                        </Link>
                                                    </td>
                                                    {tab === 'upcoming' ?
                                                        <td className='edit'>
                                                            <Link to={`/scheduler/${item.id}`}>
                                                                <img
                                                                    src={editIcon}
                                                                    loading='lazy'
                                                                    alt='edit'
                                                                    className='edit-icon'
                                                                />
                                                            </Link>
                                                        </td> : null
                                                    }
                                                    {tab === 'upcoming' ?
                                                        <td className='copy-presenter'>
                                                            <img
                                                                src={linkIcon}
                                                                loading='lazy'
                                                                alt='link'
                                                                className='link-icon'
                                                                data-for="copy-presenter-link"
                                                                data-tip={t('link_copied')}
                                                                onClick={() => this.handleCopy(item, 'admin', this.refContent[`${index}-presenter`])}
                                                                ref={ref => this.refContent[`${index}-presenter`] = ref}
                                                            />
                                                            <ReactTooltip
                                                                id="copy-presenter-link"
                                                                isCapture={true}
                                                                className=""
                                                                place="top"
                                                                type="info"
                                                                effect="solid"
                                                                event="none"
                                                                eventOff="click"
                                                                delayHide={2000}
                                                            />
                                                        </td> : null
                                                    }
                                                    {tab === 'upcoming' ?
                                                        <td className='copy-audience'>
                                                            <img
                                                                src={linkIcon}
                                                                loading='lazy'
                                                                alt='link'
                                                                className='link-icon'
                                                                data-for="copy-audience-link"
                                                                data-tip={t('link_copied')}
                                                                onClick={() => this.handleCopy(item, 'audience', this.refContent[index])}
                                                                ref={ref => this.refContent[index] = ref}
                                                            />
                                                            <ReactTooltip
                                                                id="copy-audience-link"
                                                                isCapture={true}
                                                                className=""
                                                                place="top"
                                                                type="info"
                                                                effect="solid"
                                                                event="none"
                                                                eventOff="click"
                                                                delayHide={2000}
                                                            />
                                                        </td> : null
                                                    }
                                                    {tab === 'completed' ?
                                                        <td className='download'>
                                                            {this.checkRecording() ?
                                                                checkRecordingAvailable(item) ?
                                                                    <img
                                                                        src={downloadIcon}
                                                                        loading='lazy'
                                                                        alt='download'
                                                                        className='download-icon'
                                                                        onClick={(e) => this.openLinks(e, item, this.refRecording[index])}
                                                                        ref={ref => this.refRecording[index] = ref}
                                                                    />
                                                                    : checkRecordingProcessing(item) ?
                                                                        <Spinner animation="border" className='spinner loading-icon disabled' variant="light" size="sm">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </Spinner>
                                                                        : null
                                                                : null
                                                            }
                                                        </td> : null
                                                    }
                                                    {tab === 'upcoming' ?
                                                        <td className='delete-event'>
                                                            <img
                                                                src={deleteIcon}
                                                                loading='lazy'
                                                                alt='delete'
                                                                className='link-icon'
                                                                onClick={(e) => this.showModal(e, item)}
                                                            />
                                                        </td> : null
                                                    }
                                                    <td className='more'>
                                                        <img
                                                            src={moreIcon}
                                                            onClick={() => this.setState({
                                                                moreId: item.id
                                                            })}
                                                            loading='lazy'
                                                            alt='more'
                                                            className={classNames('more-icon', { 'no-event': moreId && moreId === item.id })}
                                                        />
                                                        {moreId && moreId === item.id ?
                                                            tab === 'upcoming' ?
                                                                <ClickOutside
                                                                    className='more-menu'
                                                                    clickOutside={() => this.setState({ moreId: null })}
                                                                >
                                                                    <p className='more-item'>
                                                                        <Link to={`/scheduler/${item.id}?o=${window.btoa(item.id)}`}>
                                                                            <img src={copyIcon} loading='lazy' alt='clone' className='edit-icon' />
                                                                            {t('clone')}
                                                                        </Link>
                                                                    </p>
                                                                    <p className='more-item'>
                                                                        <Link to={`/scheduler/${item.id}`}>
                                                                            <img src={editIcon} loading='lazy' alt='edit' className='edit-icon' />
                                                                            {t('edit_event')}
                                                                        </Link>
                                                                    </p>
                                                                    <p
                                                                        className='more-item'
                                                                        data-for="copy-more-link"
                                                                        data-tip={t('link_copied')}
                                                                        onClick={() => this.handleCopy(item, 'admin', this.secondTooltip)}
                                                                        ref={ref => this.secondTooltip = ref}
                                                                    >
                                                                        <img src={linkIcon} loading='lazy' alt='link' className='link-icon' />
                                                                        <span>{t('copy_host_link')}<br /><label>{t('copy_host_link_prefix')}</label></span>
                                                                    </p>
                                                                    <ReactTooltip
                                                                        id="copy-more-link"
                                                                        isCapture={true}
                                                                        className=""
                                                                        place="top"
                                                                        type="info"
                                                                        effect="solid"
                                                                        event="none"
                                                                        eventOff="click"
                                                                        delayHide={2000}
                                                                    />
                                                                    <p
                                                                        className='more-item'
                                                                        data-for="copy-more2-link"
                                                                        data-tip={t('link_copied')}
                                                                        onClick={() => this.handleCopy(item, 'audience', this.thirdTooltip)}
                                                                        ref={ref => this.thirdTooltip = ref}
                                                                    >
                                                                        <img src={linkIcon} loading='lazy' alt='link' className='link-icon' />
                                                                        {t('copy_audience_link')}
                                                                    </p>
                                                                    <ReactTooltip
                                                                        id="copy-more2-link"
                                                                        isCapture={true}
                                                                        className=""
                                                                        place="top"
                                                                        type="info"
                                                                        effect="solid"
                                                                        event="none"
                                                                        eventOff="click"
                                                                        delayHide={2000}
                                                                    />
                                                                    <p
                                                                        className='more-item'
                                                                        onClick={(e) => this.showModal(e, item)}
                                                                    >
                                                                        <img src={deleteIcon} loading='lazy' alt='delete' className='delete-icon' />
                                                                        {t('delete_event')}
                                                                    </p>
                                                                </ClickOutside>
                                                                : tab === 'completed' ?
                                                                    <ClickOutside
                                                                        className='more-menu'
                                                                        clickOutside={() => this.setState({ moreId: null })}
                                                                    >
                                                                        <p className='more-item'>
                                                                            <Link to={`/scheduler/${item.id}?o=${window.btoa(item.id)}`}>
                                                                                <img src={copyIcon} loading='lazy' alt='clone' className='edit-icon' />
                                                                                {t('clone')}
                                                                            </Link>
                                                                        </p>
                                                                        {this.checkRecording() ?
                                                                            checkRecordingAvailable(item) ?
                                                                                <p
                                                                                    className='more-item'
                                                                                    onClick={(e) => this.openLinks(e, item, this.recMore)}
                                                                                    ref={ref => this.recMore = ref}
                                                                                >
                                                                                    <img src={downloadIcon} loading='lazy' alt='edit' className='edit-icon' />
                                                                                    {t('get_recording')}
                                                                                </p>
                                                                                : checkRecordingProcessing(item) ?
                                                                                    <p className='more-item disabled'>
                                                                                        <Spinner animation="border" className='spinner edit-icon' variant="light" size="sm">
                                                                                            <span className="visually-hidden">Loading...</span>
                                                                                        </Spinner>
                                                                                        {t('generating_recording')}
                                                                                    </p>
                                                                                    : null
                                                                            : null
                                                                        }
                                                                    </ClickOutside>
                                                                    : null
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :
                                <p className={classNames('table-empty', { "subscription": user.subscription && user.subscription.type && user.subscription.type === 'explorer' })}>{t('Your event list is empty')}</p>
                            }
                            {items && items.filter(item => item && item.title && item.title.toLowerCase && item.title.toLowerCase().includes(this.state.filterStr.toLowerCase())).length ?
                                <div className={classNames("events-mobile-wrapper", { "subscription": user.subscription && user.subscription.type && user.subscription.type === 'explorer' })}>
                                    {items.filter(item => {
                                        return item.title.toLowerCase().includes(this.state.filterStr.toLowerCase())
                                    }).map((item) => {
                                        return (
                                            <div key={item.id} className='event-item-wrapper'>
                                                <div className='event-item'>
                                                    <p className='event-info'>{t('date_and_time')}</p>
                                                    <p className='event-value'>
                                                        {moment(new Date(item.startDate ? item.startDate : moment().unix())).format('L')}, {moment(new Date(item.startDate ? item.startDate : moment().unix())).format('hh:mm a')}, {moment.tz(momentZone.tz.guess()).zoneAbbr()}
                                                    </p>
                                                </div>
                                                <div className='event-item'>
                                                    <p className='event-info'>{t('event_title')}</p>
                                                    <p className='event-value'>
                                                        <Link to={`/event/${item.id}`}>
                                                            {item.title}
                                                        </Link>
                                                    </p>
                                                </div>
                                                {tab === 'upcoming' ?
                                                    <div className='event-item'>
                                                        <p className='event-info'>{t('audience_size')}</p>
                                                        <p className='event-value'>{`< ${getTextParticipant(item.participants)}`}</p>
                                                    </div>
                                                    : null}
                                                {tab === 'completed' ?
                                                    item.stats && item.stats.users && Object.values(item.stats.users).length ?
                                                        <div className='event-item'>
                                                            <p className='event-info'>{t('host_s')}</p>
                                                            {Object.values(item.stats.users).map((item, index) => {
                                                                if (item && item.role && item.role === 'presenter') {
                                                                    return (
                                                                        <p className='event-value' key={index}>{item.name || item.email}</p>
                                                                    )
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </div>
                                                        : null
                                                    : tab === 'upcoming' ?
                                                        item.hosts && item.hosts.length ?
                                                            <div className='event-item'>
                                                                <p className='event-info'>{t('host_s')}</p>
                                                                {item.hosts.map((item, index) => {
                                                                    return (
                                                                        <p className='event-value' key={index}>{item.username || item.mail || item.email}</p>
                                                                    )
                                                                })}
                                                            </div>
                                                            : null
                                                        : null}
                                                <div className='event-item'>
                                                    <p className={classNames('event-info', { 'disabled': !this.checkAnalytics() })}>{t('attendee_analytics')}</p>
                                                    <p className='event-value'>
                                                        {this.checkAnalytics() ?
                                                            <img
                                                                onClick={(e) => this.handleShowAnalytics(e, item)}
                                                                src={statsIcon}
                                                                loading='lazy'
                                                                alt='edit'
                                                                className='card-icon'
                                                            />
                                                            : null}
                                                    </p>
                                                </div>
                                                <button
                                                    className={classNames('btn btn-more-mobile', { 'no-event': mobileMoreId && mobileMoreId === item.id })}
                                                    onClick={() => this.setState({
                                                        mobileMoreId: item.id
                                                    })}
                                                >
                                                    {mobileMoreId && mobileMoreId === item.id ? t('hide_details') : t('more_details')}
                                                </button>
                                                {mobileMoreId && mobileMoreId === item.id ?
                                                    tab === 'upcoming' ?
                                                        <ClickOutside
                                                            className='mobile-more'
                                                            clickOutside={() => this.setState({ mobileMoreId: null })}
                                                        >
                                                            <p>
                                                                <Link to={`/scheduler/${item.id}?o=${window.btoa(item.id)}`}>
                                                                    <img src={copyIcon} loading='lazy' alt='clone' className='edit-icon' />
                                                                    {t('clone')}
                                                                </Link>
                                                            </p>
                                                            <p>
                                                                <Link to={`/scheduler/${item.id}`}>
                                                                    <img src={editIcon} loading='lazy' alt='edit' className='edit-icon' />
                                                                    {t('edit_event')}
                                                                </Link>
                                                            </p>
                                                            <p
                                                                data-for="copy-more-mobile"
                                                                data-tip={t('link_copied')}
                                                                onClick={() => this.handleCopy(item, 'admin', this.fourthTooltip)}
                                                                ref={ref => this.fourthTooltip = ref}
                                                            >
                                                                <img src={linkIcon} loading='lazy' alt='link' className='link-icon' />
                                                                <span>{t('copy_host_link')}<br /><label>{t('copy_host_link_prefix')}</label></span>
                                                            </p>
                                                            <ReactTooltip
                                                                id="copy-more-mobile"
                                                                isCapture={true}
                                                                className=""
                                                                place="top"
                                                                type="info"
                                                                effect="solid"
                                                                event="none"
                                                                eventOff="click"
                                                                delayHide={2000}
                                                            />
                                                            <p
                                                                data-for="copy-more2-mobile"
                                                                data-tip={t('link_copied')}
                                                                onClick={() => this.handleCopy(item, 'audience', this.fifthTooltip)}
                                                                ref={ref => this.fifthTooltip = ref}
                                                            >
                                                                <img src={linkIcon} loading='lazy' alt='link' className='link-icon' />
                                                                {t('copy_audience_link')}
                                                            </p>
                                                            <ReactTooltip
                                                                id="copy-more2-mobile"
                                                                isCapture={true}
                                                                className=""
                                                                place="top"
                                                                type="info"
                                                                effect="solid"
                                                                event="none"
                                                                eventOff="click"
                                                                delayHide={2000}
                                                            />
                                                            <p
                                                                onClick={(e) => this.showModal(e, item)}
                                                            >
                                                                <img src={deleteIcon} loading='lazy' alt='delete' className='link-icon' />
                                                                {t('delete_event')}
                                                            </p>
                                                        </ClickOutside>
                                                        : tab === 'completed' ?
                                                            <ClickOutside
                                                                className='mobile-more'
                                                                clickOutside={() => this.setState({ mobileMoreId: null })}
                                                            >
                                                                <p>
                                                                    <Link to={`/scheduler/${item.id}?o=${window.btoa(item.id)}`}>
                                                                        <img src={copyIcon} loading='lazy' alt='clone' className='edit-icon' />
                                                                        {t('clone')}
                                                                    </Link>
                                                                </p>
                                                                {this.checkRecording() ?
                                                                    checkRecordingAvailable(item) ?
                                                                        <p
                                                                            onClick={(e) => this.openLinks(e, item, this.recMobile)}
                                                                            ref={ref => this.recMobile = ref}
                                                                        >
                                                                            <img src={downloadIcon} loading='lazy' alt='edit' className='edit-icon' />
                                                                            {t('get_recording')}
                                                                        </p>
                                                                        : checkRecordingProcessing(item) ?
                                                                            <p className="disabled">
                                                                                <Spinner animation="border" className='spinner edit-icon' variant="light" size="sm">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </Spinner>
                                                                                {t('generating_recording')}
                                                                            </p>
                                                                            : null
                                                                    : null
                                                                }
                                                            </ClickOutside>
                                                            : null
                                                    : null
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <p className={classNames('table-empty-mobile', { 'subscription': user.subscription && user.subscription.type && user.subscription.type === 'explorer' })}>{t('Your event list is empty')}</p>
                            }
                            {/* <button className='btn btn-view-more'>
                                {t('view_more')}
                            </button> */}
                        </div>
                    </div>
                    :
                    <Loader
                        text={t('Loading')}
                        dots
                    />
                }
                {analyticsItem ?
                    <Analytics
                        analyticsItem={analyticsItem}
                        close={() => this.setState({ analyticsItem: null })}
                    />
                    : null}

                <Modal show={openModal}>
                    <button className="close-btn"
                        onClick={this.hideModal}>
                        <img src={closeIcon} loading='lazy' alt='edit' className='close-icon' />
                    </button>
                    {t("modal_delete_event")}
                    <br />
                    {t("want_continue")}
                    <p className='error-text'>{errorText}</p>
                    <div className="action-container">
                        {errorText === '' ?
                            <React.Fragment>
                                <button
                                    disabled={modalButtonDisabled}
                                    className="modal-btn"
                                    onClick={(e) => this.handleDeleteEvent(e, deleteItemId)}
                                >
                                    {t("yes")}
                                </button>
                                <button
                                    disabled={modalButtonDisabled}
                                    className="modal-btn"
                                    onClick={this.hideModal}
                                >
                                    {t("no")}
                                </button>
                            </React.Fragment>
                            :
                            <button onClick={this.hideModal} className="modal-btn">{t("ok")}</button>}
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.firebase.events && state.firebase.events.length ? state.firebase.events : [],
        streams: state.firebase.videos && state.firebase.videos.length ? state.firebase.videos : [],
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded,
        loadedPublicData: state.firebase.loadedPublicData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignIn: (value) => {
            dispatch(appActions.showSignIn({ signIn: value, signUp: false }));
        }
    };
};

const MyEventsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyEvents);

export default withTranslation()(withRouter(MyEventsContainer));
