import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import { withRouter } from '../../utils/HelperFunctions';
import firebaseClient from '../../utils/FirebaseClient';

class VerifyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            processing: false,
            message: '',
            errorMessage: ''
        }

        this.verifyEmail = this.verifyEmail.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { user } = this.props;

        if (user && !user.emailVerified && prevProps.user !== user) {
            this.setState({
                show: true
            }, () => {
                firebaseClient.sendEmailVerification();
            })
        } else if (!user && prevProps.user !== user) {
            this.setState({
                show: false
            });
        }
    }

    verifyEmail() {
        const { t } = this.props;

        this.setState({
            processing: true,
            message: '',
            errorMessage: ''
        }, () => {
            firebaseClient.sendEmailVerification().then(() => {
                this.setState({
                    processing: false,
                    message: t('sended_again')
                })
            }).catch((error) => {
                this.setState({
                    processing: false,
                    errorMessage: t('some_error')
                });
            });
        });
    }

    logout() {
        firebaseClient.logOut();
    }

    render() {
        const { show, processing, message, errorMessage } = this.state;
        const { t } = this.props;

        return (
            <div className={classNames('verify-wrapper', { 'show': show })}>
                <div className='verify-div'>
                    <p className='verify-text'>{t('verify_message')}</p>
                    <button
                        className='btn btn-send-again join-btn'
                        disabled={processing}
                        onClick={this.verifyEmail}
                    >
                        {t('send_again')}
                    </button>
                    <button
                        className='btn btn-send-again join-btn'
                        disabled={processing}
                        onClick={this.logout}
                    >
                        {t('logout')}
                    </button>
                    {message ? <p className='again-text'>{message}</p> : null}
                    {errorMessage ? <p className='error-text'>{errorMessage}</p> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user
    };
};

const VerifyAccountContainer = connect(
    mapStateToProps
)(VerifyAccount);

export default withTranslation()(withRouter(VerifyAccountContainer));