import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import userPlaceholder from '../resources/images/user-placeholder.png';

export function withRouter(Child) {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return (
            <Child
                {...props}
                navigate={navigate}
                location={location}
                params={params}
            />
        );
    }
}

export const defaultColor = {
    eventPage: {
        bg: '#00000f'
    },
    eventTitle: {
        color: '#FFFFFF',
        bg: '#00000f'
    },
    eventDetails: {
        color: '#FFFFFF',
        bg: '#00000f'
    },
    eventRegistration: {
        color: '#FFFFFF',
        bg: '#00000f',
        btn: '#11b0ed'
    }
}

export function getUserAvatar(user) {
    if (user) {
        if (user.profile_picture1) {
            return user.profile_picture1;
        } else if (user.profile_picture2) {
            return user.profile_picture2;
        } else if (user.profile_picture) {
            return user.profile_picture;
        } else return userPlaceholder;
    } else return userPlaceholder;
}

export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

export function checkPathname(text, pathname) {
    let retVal = false;

    if (text && pathname && pathname.indexOf(text) !== -1) {
        retVal = true;
    }

    return retVal;
}

export const pricingArray = [
    { participants: 1000, monthlyPrice: 600, annualPrice: 5600 },
    { participants: 2000, monthlyPrice: 1000, annualPrice: 9600 },
    { participants: 3000, monthlyPrice: 1350, annualPrice: 12800 },
    { participants: 5000, monthlyPrice: 1700, annualPrice: 16000 },
    { participants: 10000, monthlyPrice: 3200, annualPrice: 30400 },
    { participants: 25000, monthlyPrice: 7400, annualPrice: 72000 },
    { participants: 50000, monthlyPrice: 14000, annualPrice: 136000 }
];

export const participantsArray = [
    { value: 500, text: '< 500' },
    { value: 1000, text: '< 1.000' },
    { value: 2000, text: '< 2.000' },
    { value: 3000, text: '< 3.000' },
    { value: 5000, text: '< 5.000' },
    { value: 10000, text: '< 10.000' },
    { value: 25000, text: '< 25.000' },
    { value: 50000, text: '< 50.000' }
];

export const companySizeArray = [
    '1-10',
    '10-100',
    '100-1000',
    '1000-5000',
    '5000-20000',
    '20000+'
];

export const durationArray = [
    { value: 1, text: '< 1' },
    { value: 2, text: '< 2' },
    { value: 3, text: '< 3' },
    { value: 4, text: '< 4' },
    { value: 5, text: '< 5' }
];

export function parseParticipant(value) {
    let text = 500;

    switch (value) {
        case '0.5k':
        case '500':
        case 500:
            text = 500;
            break;
        case '1k':
        case '1000':
        case 1000:
            text = 1000;
            break;
        case '2k':
        case '2000':
        case 2000:
            text = 2000;
            break;
        case '3k':
        case '3000':
        case 3000:
            text = 3000;
            break;
        case '5k':
        case '5000':
        case 5000:
            text = 5000;
            break;
        case '10000':
        case 10000:
            text = 10000;
            break;
        case '25000':
        case 25000:
            text = 25000;
            break;
        case '50000':
        case 50000:
            text = 50000;
            break;
        default:
            text = 50000;
            break;
    }

    return text;
}

export function parseDuration(value) {
    let text = 1;

    switch (value) {
        case '1h':
        case '1':
        case 1:
            text = 1;
            break;
        case '2h':
        case '2':
        case 2:
            text = 2;
            break;
        case '3h':
        case '3':
        case 3:
            text = 3;
            break;
        case '4h':
        case '4':
        case 4:
            text = 4;
            break;
        case '5h':
        case '5':
        case 5:
            text = 5;
            break;
        default:
            text = 5;
            break;
    }

    return text;
}

export function getTextParticipant(value) {
    let text = '500';

    switch (value) {
        case '0.5k':
        case '500':
        case 500:
            text = '500';
            break;
        case '1k':
        case '1000':
        case 1000:
            text = '1.000';
            break;
        case '2k':
        case '2000':
        case 2000:
            text = '2.000';
            break;
        case '3k':
        case '3000':
        case 3000:
            text = '3.000';
            break;
        case '5k':
        case '5000':
        case 5000:
            text = '5.000';
            break;
        case '10000':
        case 10000:
            text = '10.000';
            break;
        case '25000':
        case 25000:
            text = '25.000';
            break;
        case '50000':
        case 50000:
            text = '50.000';
            break;
        default:
            text = '50.000';
            break;
    }

    return text;
}

export function getTextDuration(value, t1, t2) {
    let text = `1 ${t1}`;

    switch (value) {
        case '1h':
        case '1':
        case 1:
            text = `1 ${t1}`;
            break;
        case '2h':
        case '2':
        case 2:
            text = `2 ${t2}`;
            break;
        case '3h':
        case '3':
        case 3:
            text = `3 ${t2}`;
            break;
        case '4h':
        case '4':
        case 4:
            text = `4 ${t2}`;
            break;
        case '5h':
        case '5':
        case 5:
            text = `5 ${t2}`;
            break;
        default:
            text = `5 ${t2}`;
            break;
    }

    return text;
}

export function getPrice(participants, duration) {
    let price = 700;
    if (participants && duration) {
        switch (duration) {
            case '1h':
            case '1':
            case 1:
                switch (participants) {
                    case '0.5k':
                    case '500':
                    case 500:
                        price = 0;
                        break;
                    case '1k':
                    case '1000':
                    case 1000:
                        price = 700;
                        break;
                    case '2k':
                    case '2000':
                    case 2000:
                        price = 1200;
                        break;
                    case '3k':
                    case '3000':
                    case 3000:
                        price = 1600;
                        break;
                    case '5k':
                    case '5000':
                    case 5000:
                        price = 2000;
                        break;
                    case '10000':
                    case 10000:
                        price = 3800;
                        break;
                    case '25000':
                    case 25000:
                        price = 9000;
                        break;
                    case '50000':
                    case 50000:
                        price = 17000;
                        break;
                    default:
                        price = 17000;
                        break;
                }
                break;
            case '2h':
            case '2':
            case 2:
                switch (participants) {
                    case '0.5k':
                    case '500':
                    case 500:
                        price = 0;
                        break;
                    case '1k':
                    case '1000':
                    case 1000:
                        price = 1400;
                        break;
                    case '2k':
                    case '2000':
                    case 2000:
                        price = 2400;
                        break;
                    case '3k':
                    case '3000':
                    case 3000:
                        price = 3200;
                        break;
                    case '5k':
                    case '5000':
                    case 5000:
                        price = 4000;
                        break;
                    case '10000':
                    case 10000:
                        price = 7600;
                        break;
                    case '25000':
                    case 25000:
                        price = 18000;
                        break;
                    case '50000':
                    case 50000:
                        price = 34000;
                        break;
                    default:
                        price = 34000;
                        break;
                }
                break;
            case '3h':
            case '3':
            case 3:
                switch (participants) {
                    case '0.5k':
                    case '500':
                    case 500:
                        price = 0;
                        break;
                    case '1k':
                    case '1000':
                    case 1000:
                        price = 2100;
                        break;
                    case '2k':
                    case '2000':
                    case 2000:
                        price = 3600;
                        break;
                    case '3k':
                    case '3000':
                    case 3000:
                        price = 4800;
                        break;
                    case '5k':
                    case '5000':
                    case 5000:
                        price = 6000;
                        break;
                    case '10000':
                    case 10000:
                        price = 11400;
                        break;
                    case '25000':
                    case 25000:
                        price = 27000;
                        break;
                    case '50000':
                    case 50000:
                        price = 51000;
                        break;
                    default:
                        price = 51000;
                        break;
                }
                break;
            case '4h':
            case '4':
            case 4:
                switch (participants) {
                    case '0.5k':
                    case '500':
                    case 500:
                        price = 0;
                        break;
                    case '1k':
                    case '1000':
                    case 1000:
                        price = 2800;
                        break;
                    case '2k':
                    case '2000':
                    case 2000:
                        price = 4800;
                        break;
                    case '3k':
                    case '3000':
                    case 3000:
                        price = 6400;
                        break;
                    case '5k':
                    case '5000':
                    case 5000:
                        price = 8000;
                        break;
                    case '10000':
                    case 10000:
                        price = 15200;
                        break;
                    case '25000':
                    case 25000:
                        price = 36000;
                        break;
                    case '50000':
                    case 50000:
                        price = 68000;
                        break;
                    default:
                        price = 68000;
                        break;
                }
                break;
            case '5h':
            case '5':
            case 5:
                switch (participants) {
                    case '0.5k':
                    case '500':
                    case 500:
                        price = 0;
                        break;
                    case '1k':
                    case '1000':
                    case 1000:
                        price = 3500;
                        break;
                    case '2k':
                    case '2000':
                    case 2000:
                        price = 6000;
                        break;
                    case '3k':
                    case '3000':
                    case 3000:
                        price = 8000;
                        break;
                    case '5k':
                    case '5000':
                    case 5000:
                        price = 10000;
                        break;
                    case '10000':
                    case 10000:
                        price = 19000;
                        break;
                    case '25000':
                    case 25000:
                        price = 45000;
                        break;
                    case '50000':
                    case 50000:
                        price = 85000;
                        break;
                    default:
                        price = 85000;
                        break;
                }
                break;
            default:
                switch (participants) {
                    case '0.5k':
                    case '500':
                    case 500:
                        price = 0;
                        break;
                    case '1k':
                    case '1000':
                    case 1000:
                        price = 3500;
                        break;
                    case '2k':
                    case '2000':
                    case 2000:
                        price = 6000;
                        break;
                    case '3k':
                    case '3000':
                    case 3000:
                        price = 8000;
                        break;
                    case '5k':
                    case '5000':
                    case 5000:
                        price = 10000;
                        break;
                    case '10000':
                    case 10000:
                        price = 19000;
                        break;
                    case '25000':
                    case 25000:
                        price = 45000;
                        break;
                    case '50000':
                    case 50000:
                        price = 85000;
                        break;
                    default:
                        price = 85000;
                        break;
                }
                break;
        }
    }

    return price;
}

export function parseRole(role) {
    let retVal = 'audience';

    switch (role) {
        case 'presenter':
            retVal = 'Host';
            break;
        case 'moderator':
            retVal = 'Moderator';
            break;
        case 'guest_speaker':
            retVal = 'Guest Speaker';
            break;
        case 'audience':
            retVal = 'Audience';
            break;
        default:
            retVal = 'Audience'
            break;
    }

    return retVal;
}

export function msToHhMm(milliseconds) {
    if (!milliseconds) return "00:00";
    try {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;

        const padTo2Digits = (num) => {
            return num.toString().padStart(2, "0");
        }

        return `${hours}${minutes ? `:${padTo2Digits(minutes)}` : ''}`;
    } catch (e) {
        return "(Unknown)";
    }
}

export function hoursToMS(val) {
    let parseVal = parseInt(val);
    if (val) {
        parseVal = parseVal * 60 * 60 * 1000;
        return parseVal
    } else return 0;
}

export function msToHh(ms) {
    // 1- Convert to seconds:
    let seconds = ms / 1000;
    // 2- Extract hours:
    const hours = parseInt(seconds / 3600);
    return hours;
}

export function isIOS() {
    return (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
}

export function isMobile() {
    return isIOS() ||
        /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
}

export function checkRecordingAvailable(item) {
    let retVal = false;

    if (item && ((item.recordingLink) || (item.recordingStatus && item.recordingStatus === 'ready'))) {
        retVal = true;
    }

    return retVal;
}

export function checkRecordingProcessing(item) {
    let retVal = false;

    if (item && item.recordingStatus && (item.recordingStatus === 'processing' || item.recordingStatus === 'error')) {
        retVal = true;
    }

    return retVal;
}

export const timezones = [
    {
        "name": "(GMT-11:00) Midway Island",
        "timezone": "Pacific/Midway"
    }, {
        "name": "(GMT-11:00) Samoa",
        "timezone": "US/Samoa"
    }, {
        "name": "(GMT-10:00) Hawaii",
        "timezone": "US/Hawaii"
    }, {
        "name": "(GMT-09:00) Alaska",
        "timezone": "US/Alaska"
    }, {
        "name": "(GMT-08:00) Pacific Time (US & Canada)",
        "timezone": "US/Pacific"
    }, {
        "name": "(GMT-08:00) Tijuana",
        "timezone": "America/Tijuana"
    }, {
        "name": "(GMT-07:00) Arizona",
        "timezone": "US/Arizona"
    }, {
        "name": "(GMT-07:00) Mountain Time (US & Canada)",
        "timezone": "US/Mountain"
    }, {
        "name": "(GMT-07:00) Chihuahua",
        "timezone": "America/Chihuahua"
    }, {
        "name": "(GMT-07:00) Mazatlan",
        "timezone": "America/Mazatlan"
    }, {
        "name": "(GMT-06:00) Mexico City",
        "timezone": "America/Mexico_City"
    }, {
        "name": "(GMT-06:00) Monterrey",
        "timezone": "America/Monterrey"
    }, {
        "name": "(GMT-06:00) Saskatchewan",
        "timezone": "Canada/Saskatchewan"
    }, {
        "name": "(GMT-06:00) Central Time (US & Canada)",
        "timezone": "US/Central"
    }, {
        "name": "(GMT-05:00) Eastern Time (US & Canada)",
        "timezone": "US/Eastern"
    }, {
        "name": "(GMT-05:00) Indiana (East)",
        "timezone": "US/East-Indiana"
    }, {
        "name": "(GMT-05:00) Bogota",
        "timezone": "America/Bogota"
    }, {
        "name": "(GMT-05:00) Lima",
        "timezone": "America/Lima"
    }, {
        "name": "(GMT-04:30) Caracas",
        "timezone": "America/Caracas"
    }, {
        "name": "(GMT-04:00) Atlantic Time (Canada)",
        "timezone": "Canada/Atlantic"
    }, {
        "name": "(GMT-04:00) La Paz",
        "timezone": "America/La_Paz"
    }, {
        "name": "(GMT-04:00) Santiago",
        "timezone": "America/Santiago"
    }, {
        "name": "(GMT-03:30) Newfoundland",
        "timezone": "Canada/Newfoundland"
    }, {
        "name": "(GMT-03:00) Buenos Aires",
        "timezone": "America/Buenos_Aires"
    }, {
        "name": "(GMT-03:00) Greenland",
        "timezone": "Greenland"
    }, {
        "name": "(GMT-02:00) Stanley",
        "timezone": "Atlantic/Stanley"
    }, {
        "name": "(GMT-01:00) Azores",
        "timezone": "Atlantic/Azores"
    }, {
        "name": "(GMT-01:00) Cape Verde Is.",
        "timezone": "Atlantic/Cape_Verde"
    }, {
        "name": "(GMT) Casablanca",
        "timezone": "Africa/Casablanca"
    }, {
        "name": "(GMT) Dublin",
        "timezone": "Europe/Dublin"
    }, {
        "name": "(GMT) Lisbon",
        "timezone": "Europe/Lisbon"
    }, {
        "name": "(GMT) London",
        "timezone": "Europe/London"
    }, {
        "name": "(GMT) Monrovia",
        "timezone": "Africa/Monrovia"
    }, {
        "name": "(GMT+01:00) Amsterdam",
        "timezone": "Europe/Amsterdam"
    }, {
        "name": "(GMT+01:00) Belgrade",
        "timezone": "Europe/Belgrade"
    }, {
        "name": "(GMT+01:00) Berlin",
        "timezone": "Europe/Berlin"
    }, {
        "name": "(GMT+01:00) Bratislava",
        "timezone": "Europe/Bratislava"
    }, {
        "name": "(GMT+01:00) Brussels",
        "timezone": "Europe/Brussels"
    }, {
        "name": "(GMT+01:00) Budapest",
        "timezone": "Europe/Budapest"
    }, {
        "name": "(GMT+01:00) Copenhagen",
        "timezone": "Europe/Copenhagen"
    }, {
        "name": "(GMT+01:00) Ljubljana",
        "timezone": "Europe/Ljubljana"
    }, {
        "name": "(GMT+01:00) Madrid",
        "timezone": "Europe/Madrid"
    }, {
        "name": "(GMT+01:00) Paris",
        "timezone": "Europe/Paris"
    }, {
        "name": "(GMT+01:00) Prague",
        "timezone": "Europe/Prague"
    }, {
        "name": "(GMT+01:00) Rome",
        "timezone": "Europe/Rome"
    }, {
        "name": "(GMT+01:00) Sarajevo",
        "timezone": "Europe/Sarajevo"
    }, {
        "name": "(GMT+01:00) Skopje",
        "timezone": "Europe/Skopje"
    }, {
        "name": "(GMT+01:00) Stockholm",
        "timezone": "Europe/Stockholm"
    }, {
        "name": "(GMT+01:00) Vienna",
        "timezone": "Europe/Vienna"
    }, {
        "name": "(GMT+01:00) Warsaw",
        "timezone": "Europe/Warsaw"
    }, {
        "name": "(GMT+01:00) Zagreb",
        "timezone": "Europe/Zagreb"
    }, {
        "name": "(GMT+02:00) Athens",
        "timezone": "Europe/Athens"
    }, {
        "name": "(GMT+02:00) Bucharest",
        "timezone": "Europe/Bucharest"
    }, {
        "name": "(GMT+02:00) Cairo",
        "timezone": "Africa/Cairo"
    }, {
        "name": "(GMT+02:00) Harare",
        "timezone": "Africa/Harare"
    }, {
        "name": "(GMT+02:00) Helsinki",
        "timezone": "Europe/Helsinki"
    }, {
        "name": "(GMT+02:00) Istanbul",
        "timezone": "Europe/Istanbul"
    }, {
        "name": "(GMT+02:00) Jerusalem",
        "timezone": "Asia/Jerusalem"
    }, {
        "name": "(GMT+02:00) Kyiv",
        "timezone": "Europe/Kiev"
    }, {
        "name": "(GMT+02:00) Minsk",
        "timezone": "Europe/Minsk"
    }, {
        "name": "(GMT+02:00) Riga",
        "timezone": "Europe/Riga"
    }, {
        "name": "(GMT+02:00) Sofia",
        "timezone": "Europe/Sofia"
    }, {
        "name": "(GMT+02:00) Tallinn",
        "timezone": "Europe/Tallinn"
    }, {
        "name": "(GMT+02:00) Vilnius",
        "timezone": "Europe/Vilnius"
    }, {
        "name": "(GMT+03:00) Baghdad",
        "timezone": "Asia/Baghdad"
    }, {
        "name": "(GMT+03:00) Kuwait",
        "timezone": "Asia/Kuwait"
    }, {
        "name": "(GMT+03:00) Nairobi",
        "timezone": "Africa/Nairobi"
    }, {
        "name": "(GMT+03:00) Riyadh",
        "timezone": "Asia/Riyadh"
    }, {
        "name": "(GMT+03:00) Moscow",
        "timezone": "Europe/Moscow"
    }, {
        "name": "(GMT+03:30) Tehran",
        "timezone": "Asia/Tehran"
    }, {
        "name": "(GMT+04:00) Baku",
        "timezone": "Asia/Baku"
    }, {
        "name": "(GMT+04:00) Volgograd",
        "timezone": "Europe/Volgograd"
    }, {
        "name": "(GMT+04:00) Muscat",
        "timezone": "Asia/Muscat"
    }, {
        "name": "(GMT+04:00) Tbilisi",
        "timezone": "Asia/Tbilisi"
    }, {
        "name": "(GMT+04:00) Yerevan",
        "timezone": "Asia/Yerevan"
    }, {
        "name": "(GMT+04:30) Kabul",
        "timezone": "Asia/Kabul"
    }, {
        "name": "(GMT+05:00) Karachi",
        "timezone": "Asia/Karachi"
    }, {
        "name": "(GMT+05:00) Tashkent",
        "timezone": "Asia/Tashkent"
    }, {
        "name": "(GMT+05:30) Kolkata",
        "timezone": "Asia/Kolkata"
    }, {
        "name": "(GMT+05:45) Kathmandu",
        "timezone": "Asia/Kathmandu"
    }, {
        "name": "(GMT+06:00) Ekaterinburg",
        "timezone": "Asia/Yekaterinburg"
    }, {
        "name": "(GMT+06:00) Almaty",
        "timezone": "Asia/Almaty"
    }, {
        "name": "(GMT+06:00) Dhaka",
        "timezone": "Asia/Dhaka"
    }, {
        "name": "(GMT+07:00) Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    }, {
        "name": "(GMT+07:00) Bangkok",
        "timezone": "Asia/Bangkok"
    }, {
        "name": "(GMT+07:00) Jakarta",
        "timezone": "Asia/Jakarta"
    }, {
        "name": "(GMT+08:00) Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    }, {
        "name": "(GMT+08:00) Chongqing",
        "timezone": "Asia/Chongqing"
    }, {
        "name": "(GMT+08:00) Hong Kong",
        "timezone": "Asia/Hong_Kong"
    }, {
        "name": "(GMT+08:00) Kuala Lumpur",
        "timezone": "Asia/Kuala_Lumpur"
    }, {
        "name": "(GMT+08:00) Perth",
        "timezone": "Australia/Perth"
    }, {
        "name": "(GMT+08:00) Singapore",
        "timezone": "Asia/Singapore"
    }, {
        "name": "(GMT+08:00) Taipei",
        "timezone": "Asia/Taipei"
    }, {
        "name": "(GMT+08:00) Ulaan Bataar",
        "timezone": "Asia/Ulaanbaatar"
    }, {
        "name": "(GMT+08:00) Urumqi",
        "timezone": "Asia/Urumqi"
    }, {
        "name": "(GMT+09:00) Irkutsk",
        "timezone": "Asia/Irkutsk"
    }, {
        "name": "(GMT+09:00) Seoul",
        "timezone": "Asia/Seoul"
    }, {
        "name": "(GMT+09:00) Tokyo",
        "timezone": "Asia/Tokyo"
    }, {
        "name": "(GMT+09:30) Adelaide",
        "timezone": "Australia/Adelaide"
    }, {
        "name": "(GMT+09:30) Darwin",
        "timezone": "Australia/Darwin"
    }, {
        "name": "(GMT+10:00) Yakutsk",
        "timezone": "Asia/Yakutsk"
    }, {
        "name": "(GMT+10:00) Brisbane",
        "timezone": "Australia/Brisbane"
    }, {
        "name": "(GMT+10:00) Canberra",
        "timezone": "Australia/Canberra"
    }, {
        "name": "(GMT+10:00) Guam",
        "timezone": "Pacific/Guam"
    }, {
        "name": "(GMT+10:00) Hobart",
        "timezone": "Australia/Hobart"
    }, {
        "name": "(GMT+10:00) Melbourne",
        "timezone": "Australia/Melbourne"
    }, {
        "name": "(GMT+10:00) Port Moresby",
        "timezone": "Pacific/Port_Moresby"
    }, {
        "name": "(GMT+10:00) Sydney",
        "timezone": "Australia/Sydney"
    }, {
        "name": "(GMT+11:00) Vladivostok",
        "timezone": "Asia/Vladivostok"
    }, {
        "name": "(GMT+12:00) Magadan",
        "timezone": "Asia/Magadan"
    }, {
        "name": "(GMT+12:00) Auckland",
        "timezone": "Pacific/Auckland"
    }, {
        "name": "(GMT+12:00) Fiji",
        "timezone": "Pacific/Fiji"
    }
];

export function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}

export function getRemainingSubscriptionTime(item) {
    return msToHhMm((item.available_amount + (parseInt(item.accumulated_ms) ? parseInt(item.accumulated_ms) : 0) + (parseInt(item.extra_event_hours) ? hoursToMS(parseInt(item.extra_event_hours)) : 0)) && item.used && (item.available_amount + (parseInt(item.accumulated_ms) ? parseInt(item.accumulated_ms) : 0) + (parseInt(item.extra_event_hours) ? hoursToMS(parseInt(item.extra_event_hours)) : 0)) < item.used ? 0 : (item.available_amount + (parseInt(item.accumulated_ms) ? parseInt(item.accumulated_ms) : 0) + (parseInt(item.extra_event_hours) ? hoursToMS(parseInt(item.extra_event_hours)) : 0)) ? (item.available_amount + (parseInt(item.accumulated_ms) ? parseInt(item.accumulated_ms) : 0) + (parseInt(item.extra_event_hours) ? hoursToMS(parseInt(item.extra_event_hours)) : 0)) - (item.used ? item.used : 0) : !item.available_amount && !item.accumulated_ms && !item.used && item.max_event_hours ? hoursToMS((item.max_event_hours + (parseInt(item.extra_event_hours) ? parseInt(item.extra_event_hours) : 0))) : 0);
}

export function getTotalSubscriptionTime(item) {
    return msToHhMm((item.available_amount + (parseInt(item.accumulated_ms) ? parseInt(item.accumulated_ms) : 0) + (parseInt(item.extra_event_hours) ? hoursToMS(parseInt(item.extra_event_hours)) : 0)) ? (item.available_amount + (parseInt(item.accumulated_ms) ? parseInt(item.accumulated_ms) : 0) + (parseInt(item.extra_event_hours) ? hoursToMS(parseInt(item.extra_event_hours)) : 0)) : item.max_event_hours ? hoursToMS((item.max_event_hours + (parseInt(item.extra_event_hours) ? parseInt(item.extra_event_hours) : 0))) : 0);
}

export function validatePassword(password) {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$!%^&*()_+\-=\[\]{};':"\\|,.<>])[A-Za-z\d@#$!%^&*()_+\-=\[\]{};':"\\|,.<>]{8,}$/;
    return re.test(String(password));
}

export function getTenantId(user) {
    let retVal = null, website = process.env.website;

    if (website && user && user.tenants && user.tenants.length) {
        let found = user.tenants.find((tenant) => tenant && tenant.key && tenant.key === website);
        retVal = found && found.id ? found.id : null;
    }

    return retVal;
}