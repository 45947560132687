import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import * as appActions from '../../reducers/app';
import logo from '../../resources/images/strmzio-logo.png';
import { withTranslation } from 'react-i18next';
import NavMenu from './NavMenu';
import SignIn from './SignIn';
import Profile from './Profile';
import config from '../../config';

class Header extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    const { t, showSignIn, hideSignForm, signIn, auth, user } = this.props;

    return (
      <div className='header'>
        <div className='header-logo'>
          <a
            href={`https://${config.homeSiteDomain}/`}
            onClick={() => {
              window.scrollTo(0, 0)
              hideSignForm()
            }}
          >
            <img src={logo} alt='logo' />
          </a>
        </div>
        <div className={classNames("header-links", { "signIn": signIn })}>
          <a
            href={`https://${config.homeSiteDomain}/`}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            {t('home')}
          </a>
          <a
            href={`https://${config.homeSiteDomain}/pricing`}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            {t('pricing')}
          </a>
          <a
            href={`https://${config.homeSiteDomain}/about`}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            {t('about')}
          </a>
          <a
            href={`https://${config.homeSiteDomain}/partners`}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            {t('partners')}
          </a>
          <a
            href={`https://${config.homeSiteDomain}/help`}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            {t('help')}
          </a>
          {user && user.eventAdmin ?
            <NavLink
              to="/my-events?t=upcoming"
              className="nav-link"
            >
              {t('my_events')}
            </NavLink>
            : null
          }
          {!auth && !user ?
            <NavLink
              to="/signup"
              className="nav-link"
            >
              {t('sign_up')}
            </NavLink>
            : null}
          {!auth && !user ?
            <div
              className={classNames("nav-link underline", { "active": signIn })}
              onClick={() => showSignIn(true)}
            >
              {t('sign_in')}
            </div>
            : null}
        </div>
        {auth && user ?
          <Profile />
          : null
        }
        <NavMenu />
        {signIn ?
          <SignIn />
          : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    signIn: state.app.signIn,
    auth: state.firebase.auth,
    user: state.firebase.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSignIn: (value) => {
      dispatch(appActions.showSignIn({ signIn: value, signUp: false }));
    },
    hideSignForm: () => {
      dispatch(appActions.showSignIn({ signIn: false, signUp: false }));
    }
  };
};

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default withTranslation()(HeaderContainer);