import React, { Component } from 'react';
import classNames from "classnames";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as appActions from '../../reducers/app';
import firebaseClient from '../../utils/FirebaseClient';
import Loader from '../Widget/Loader';
import { companySizeArray, withRouter, getQueryVariable } from '../../utils/HelperFunctions';
import config from '../../config';

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'creator',
            username: '',
            terms: false,
            firstName: '',
            lastName: '',
            title: '',
            companyName: '',
            companyAddress: '',
            companySize: '1-10',
            email: '',
            phoneNumber: '',
            processing: false,
            tab: 1,
            errorText: '',
            successText: '',
            changes: false
        }

        this.handleValidUserForm = this.handleValidUserForm.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleSetTerms = this.handleSetTerms.bind(this);

    }

    setUserInfo() {
        const { user } = this.props;

        this.setState({
            username: user && user.username ? user.username : '',
            firstName: user && user.firstName ? user.firstName : '',
            lastName: user && user.lastName ? user.lastName : '',
            title: user && user.title ? user.title : '',
            email: user && user.email ? user.email : '',
            companyName: user && user.companyName ? user.companyName : '',
            companyAddress: user && user.companyAddress ? user.companyAddress : '',
            companySize: user && user.companySize ? user.companySize : '1-10',
            phoneNumber: user && user.phoneNumber ? user.phoneNumber : '',
        });
    }

    componentDidMount() {
        const { user, userLoaded, showSignIn } = this.props;

        if (!user && userLoaded && showSignIn) {
            showSignIn(true)
        } else if (user) {
            const a = getQueryVariable('a');

            if (a && (a === 'creator' || a === 'participant')) {
                this.setSubscriptionType(a);
            } else {
                this.setUserInfo();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { user, userLoaded, showSignIn } = this.props;

        if (!user && userLoaded && prevProps.userLoaded !== userLoaded && showSignIn) {
            showSignIn(true)
        }

        if (user && user !== prevProps.user) {
            const a = getQueryVariable('a');

            if (a && (a === 'creator' || a === 'participant')) {
                this.setSubscriptionType(a);
            } else {
                this.setUserInfo();
            }
        }
    }

    componentWillUnmount() {
        if (this.succesTimeout) {
            clearTimeout(this.succesTimeout);
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePhoneNumber(number) {
        const re = /^\+[1]\d{10}$/;
        return re.test(String(number).toLowerCase());
    }

    handleValidUserForm() {
        const { type, username, firstName, lastName, title, email, terms, phoneNumber } = this.state;

        if (!email || (email && !this.validateEmail(email))
            || !username || (username && username.length < 2)
            || (type && type === 'creator' && (!phoneNumber || (phoneNumber && !this.validatePhoneNumber(phoneNumber))))
            || !firstName || (firstName && firstName.length < 2)
            || !lastName || (lastName && lastName.length < 2)
            || !title || (title && title.length < 2)
            || !terms) {
            this.checkUserForm();
        } else {
            this.setState({
                tab: 2,
                errorText: '',
                successText: ''
            });
        }
    }

    checkUserForm() {
        const { type, terms, username, firstName, lastName, title, email, phoneNumber } = this.state;
        const { t } = this.props;

        let message = '';

        if (!email) {
            message = t('valid_1');
        } else if (type && type === 'creator' && !phoneNumber) {
            message = t('valid_19');
        } else if (type && type === 'creator' && phoneNumber && !this.validatePhoneNumber(phoneNumber)) {
            message = t('valid_20');
        } else if (email && !this.validateEmail(email)) {
            message = t('valid_2');
        } else if (!username) {
            message = t('valid_8');
        } else if (username && username.length < 2) {
            message = t('valid_9');
        } else if (!firstName) {
            message = t('valid_10');
        } else if (firstName && firstName.length < 2) {
            message = t('valid_11');
        } else if (!lastName) {
            message = t('valid_12');
        } else if (lastName && lastName.length < 2) {
            message = t('valid_13');
        } else if (!title) {
            message = t('valid_14');
        } else if (title && title.length < 2) {
            message = t('valid_15');
        } else if (!terms) {
            message = type && type === 'creator' ? t('valid_27') : t('valid_26');
        }

        this.setState({
            errorText: message
        });
    }

    checkCompanyForm() {
        const { type, companyName, companyAddress, companySize, phoneNumber } = this.state;
        const { t } = this.props;

        let message = '';

        if (type && type === 'creator') {
            if (!companyName) {
                message = t('valid_16');
            } else if (!companyAddress) {
                message = t('valid_17');
            } else if (!companySize) {
                message = t('valid_18');
            } else if (!phoneNumber) {
                message = t('valid_19');
            } else if (phoneNumber && !this.validatePhoneNumber(phoneNumber)) {
                message = t('valid_20');
            }
        } else if (type && type === 'participant') {
            if (!companyName) {
                message = t('valid_16');
            }
        }

        this.setState({
            errorText: message
        });
    }

    handleSubmitForm() {
        const { type, terms, username, firstName, lastName, title, email, companyName, companyAddress, companySize, phoneNumber, changes } = this.state;
        const { t, user } = this.props;

        if ((type && type === 'creator' && (!phoneNumber || (phoneNumber && !this.validatePhoneNumber(phoneNumber))
            || !companyName || !companyAddress || !companySize)) || (type && type === 'participant' && !companyName)) {
            this.checkCompanyForm();
        } else if (!email || (email && !this.validateEmail(email))
            || !username || (username && username.length < 2)
            || !firstName || (firstName && firstName.length < 2)
            || !lastName || (lastName && lastName.length < 2)
            || !title || (title && title.length < 2)
            || !terms) {
            this.checkUserForm();
        } else if (user && user.uid) {
            this.setState({ processing: true });

            if (type && type === 'creator') {
                let subscriptionData = {
                    type: 'explorer',
                    username: username,
                    firstName: firstName,
                    lastName: lastName,
                    title: title,
                    companyName: companyName,
                    companyAddress: companyAddress,
                    companySize: companySize,
                    email: email,
                    phoneNumber: phoneNumber
                }

                if (changes) {

                    let updateData = {
                        username,
                        username_lc: username.toLowerCase(),
                        firstName,
                        lastName,
                        title,
                        companyName,
                        companyAddress,
                        companySize,
                        phoneNumber
                    }

                    firebaseClient.updateUser(user.uid, updateData).then(() => {
                        this.sendSubscriptionRequest(subscriptionData);
                    }).catch(error => {
                        this.setState({
                            processing: false,
                            successText: '',
                            errorText: t('update_user_error'),
                            tab: 1,
                            changes: false
                        }, () => {
                            this.setUserInfo();
                        });
                    });
                } else {
                    this.sendSubscriptionRequest(subscriptionData);
                }
            } else if (type && type === 'participant') {
                if (changes) {

                    let updateData = {
                        username,
                        username_lc: username.toLowerCase(),
                        firstName,
                        lastName,
                        title,
                        companyName
                    }

                    firebaseClient.updateUser(user.uid, updateData).then(() => {
                        this.setState({
                            processing: false,
                            errorText: '',
                            successText: t('success_participant_message')
                        }, () => {
                            this.succesTimeout = setTimeout(() => {
                                const { navigate } = this.props;
                                if (navigate) {
                                    navigate('/');
                                }
                            }, 5000);
                        });
                    }).catch(error => {
                        this.setState({
                            processing: false,
                            successText: '',
                            errorText: t('update_user_error'),
                            tab: 1,
                            changes: false
                        }, () => {
                            this.setUserInfo();
                        });
                    });
                } else {
                    this.setState({
                        processing: false,
                        errorText: '',
                        successText: t('success_participant_message')
                    }, () => {
                        this.succesTimeout = setTimeout(() => {
                            const { navigate } = this.props;
                            if (navigate) {
                                navigate('/');
                            }
                        }, 5000);
                    });
                }
            }
        }
    }

    sendSubscriptionRequest(data) {
        const { t, user } = this.props;

        if (data) {
            firebaseClient.sendSubscriptionRequest(data).then((res) => {
                if (res) {
                    this.setState({
                        processing: false,
                        errorText: '',
                        successText: res.type === 'explorer' ? t('explorer_success_text') : res.type === 'pro' ? t('pro_success_text') : res.type === 'enterprise' ? t('enterprise_success_text') : t('success_subscription_request')
                    }, () => {
                        if (user) {
                            firebaseClient.getUserData(user, true);
                        }
                        this.succesTimeout = setTimeout(() => {
                            const { navigate } = this.props;
                            if (navigate) {
                                navigate('/');
                            }
                        }, 5000);
                    });
                }
            }).catch(error => {
                this.setState({
                    processing: false,
                    successText: '',
                    errorText: t('subscription_request_error'),
                    tab: 1,
                    changes: false
                }, () => {
                    this.setUserInfo();
                });
            });
        }
    }

    setSubscriptionType(type) {
        this.setState({
            type: type
        }, () => {
            this.setUserInfo();
        });
    }

    handleSetTerms(value) {
        this.setState({ terms: value });
    }


    render() {
        const { type, tab, terms, processing, username, firstName, lastName, title, companyName,
            companyAddress, companySize, email, phoneNumber, errorText, successText } = this.state;
        const { t, user, userLoaded } = this.props;

        return (
            <div>
                {user && userLoaded ?
                    <div className='event-scheduler'>
                        {type && type === 'creator' ?
                            <div className='event-scheduler-items'>
                                <p className='event-scheduler-title'>{t('company_details')}</p>
                                <div className='scheduler-item'>
                                    <div className={classNames('item-header signup-header', { 'show': tab === 1 })}>
                                        <p>{t('cet_6')}</p>
                                    </div>
                                    <div className={classNames('item-body signup-body', { 'show': tab === 1 })}>
                                        <div className='section-flex'>
                                            <div className='section-left'>
                                                <div className='input-wrapper'>
                                                    <p>{t('username')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_username')}
                                                        disabled={processing}
                                                        onChange={(e) => this.setState({ username: e.target.value, errorText: '', changes: true })}
                                                        value={username}
                                                    />
                                                </div>
                                                <div className='input-wrapper'>
                                                    <p>{t('first_name')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_fn')}
                                                        disabled={processing}
                                                        onChange={(e) => this.setState({ firstName: e.target.value, errorText: '', changes: true })}
                                                        value={firstName}
                                                    />
                                                </div>
                                                <div className='input-wrapper'>
                                                    <p>{t('last_name')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_ln')}
                                                        disabled={processing}
                                                        onChange={(e) => this.setState({ lastName: e.target.value, errorText: '', changes: true })}
                                                        value={lastName}
                                                    />
                                                </div>
                                            </div>
                                            <div className='section-right'>
                                                <div className='input-wrapper'>
                                                    <p>{t('title')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_title')}
                                                        disabled={processing}
                                                        onChange={(e) => this.setState({ title: e.target.value, errorText: '', changes: true })}
                                                        value={title}
                                                    />
                                                </div>
                                                <div className='input-wrapper'>
                                                    <p>{t('phoneNumber_text')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_pn')}
                                                        disabled={processing}
                                                        onChange={(e) => this.setState({ phoneNumber: e.target.value, errorText: '', changes: true })}
                                                        value={phoneNumber}
                                                    />
                                                </div>
                                                <div className='input-wrapper'>
                                                    <p>{t('email')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_email')}
                                                        readOnly
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='input-wrapper align-left'>
                                            <input
                                                type='checkbox'
                                                checked={terms}
                                                onChange={(e) => this.handleSetTerms(e.target.checked)}
                                            />
                                            <p>
                                                {t('sign_up_participant')}
                                                <a
                                                    href={`https://${config.homeSiteDomain}/subscription-terms-of-service`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('nfss_terms_of_service')}
                                                </a>.
                                            </p>
                                        </div>
                                        {errorText ? <p className='error-text'>{errorText}</p> : null}
                                        {successText ? <p className='success-text'>{successText}</p> : null}
                                        <div className='buttons'>
                                            {/* <button
                                            className='btn btn-back'
                                            onClick={() => this.setState({ type: '' })}
                                            disabled={processing}
                                        >
                                            {t('back')}
                                        </button> */}
                                            <button
                                                className='btn btn-save'
                                                onClick={this.handleValidUserForm}
                                                disabled={processing}
                                            >
                                                {t('next')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='scheduler-item'>
                                    <div className={classNames('item-header signup-header', { 'show': tab === 2 })}>
                                        <p>{t('cet_7')}</p>
                                    </div>
                                    <div className={classNames('item-body signup-body', { 'show': tab === 2 })}>
                                        <div className='section-flex'>
                                            <div className='section-left'>
                                                <div className='input-wrapper'>
                                                    <p>{t('companyName')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_cn')}
                                                        disabled={processing}
                                                        onChange={(e) => this.setState({ companyName: e.target.value, errorText: '', changes: true })}
                                                        value={companyName}
                                                    />
                                                </div>
                                                <div className='input-wrapper'>
                                                    <p>{t('companyAddress')}<span className='asterisk'>*</span></p>
                                                    <input
                                                        type="text"
                                                        placeholder={t('enter_ca')}
                                                        disabled={processing}
                                                        onChange={(e) => this.setState({ companyAddress: e.target.value, errorText: '', changes: true })}
                                                        value={companyAddress}
                                                    />
                                                </div>
                                            </div>
                                            <div className='section-right'>
                                                <div className='input-wrapper'>
                                                    <p>{t('companySize')}<span className='asterisk'>*</span></p>
                                                    <select
                                                        onChange={(e) => this.setState({ companySize: e.target.value, errorText: '', changes: true })}
                                                        value={companySize}
                                                        disabled={processing}
                                                    >
                                                        {companySizeArray.map((item, index) => {
                                                            return (
                                                                <option key={index} style={{ background: '#27272e' }} value={item}>{item}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {errorText ? <p className='error-text'>{errorText}</p> : null}
                                        {successText ? <p className='success-text'>{successText}</p> : null}
                                        <div className='buttons'>
                                            <button
                                                className='btn btn-back'
                                                onClick={() => this.setState({ tab: 1, successText: '', errorText: '' })}
                                                disabled={processing}
                                            >
                                                {t('back')}
                                            </button>
                                            <button
                                                className='btn btn-save'
                                                onClick={this.handleSubmitForm}
                                                disabled={processing}
                                            >
                                                {t('sign_up')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : type && type === 'participant' ?
                                <div className='event-scheduler-items'>
                                    <p className='event-scheduler-title'>{t('participant_live_event')}</p>
                                    <div className='scheduler-item'>
                                        <div className={classNames('item-header signup-header', { 'show': tab === 1 })}>
                                            <p>{t('cet_8')}</p>
                                        </div>
                                        <div className={classNames('item-body signup-body', { 'show': tab === 1 })}>
                                            <div className='section-flex'>
                                                <div className='section-left'>
                                                    <div className='input-wrapper'>
                                                        <p>{t('username')}<span className='asterisk'>*</span></p>
                                                        <input
                                                            type="text"
                                                            placeholder={t('enter_username')}
                                                            disabled={processing}
                                                            onChange={(e) => this.setState({ username: e.target.value, errorText: '', changes: true })}
                                                            value={username}
                                                        />
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <p>{t('first_name')}<span className='asterisk'>*</span></p>
                                                        <input
                                                            type="text"
                                                            placeholder={t('enter_fn')}
                                                            disabled={processing}
                                                            onChange={(e) => this.setState({ firstName: e.target.value, errorText: '', changes: true })}
                                                            value={firstName}
                                                        />
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <p>{t('last_name')}<span className='asterisk'>*</span></p>
                                                        <input
                                                            type="text"
                                                            placeholder={t('enter_ln')}
                                                            disabled={processing}
                                                            onChange={(e) => this.setState({ lastName: e.target.value, errorText: '', changes: true })}
                                                            value={lastName}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='section-right'>
                                                    <div className='input-wrapper'>
                                                        <p>{t('title')}<span className='asterisk'>*</span></p>
                                                        <input
                                                            type="text"
                                                            placeholder={t('enter_title')}
                                                            disabled={processing}
                                                            onChange={(e) => this.setState({ title: e.target.value, errorText: '', changes: true })}
                                                            value={title}
                                                        />
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <p>{t('companyName')}<span className='asterisk'>*</span></p>
                                                        <input
                                                            type="text"
                                                            placeholder={t('enter_cn')}
                                                            disabled={processing}
                                                            onChange={(e) => this.setState({ companyName: e.target.value, errorText: '', changes: true })}
                                                            value={companyName}
                                                        />
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <p>{t('email')}<span className='asterisk'>*</span></p>
                                                        <input
                                                            type="text"
                                                            placeholder={t('enter_email')}
                                                            readOnly
                                                            value={email}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='input-wrapper align-left'>
                                                <input
                                                    type='checkbox'
                                                    checked={terms}
                                                    onChange={(e) => this.handleSetTerms(e.target.checked)}
                                                />
                                                <p>
                                                    {t('sign_up_participant')}
                                                    <a
                                                        href={`https://${config.homeSiteDomain}/participant-terms-of-use`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {t('e_p_terms_of_use')}
                                                    </a>.
                                                </p>
                                            </div>
                                            {errorText ? <p className='error-text'>{errorText}</p> : null}
                                            {successText ? <p className='success-text'>{successText}</p> : null}
                                            <div className='buttons'>
                                                {/* <button
                                                className='btn btn-back'
                                                onClick={() => this.setState({ type: '' })}
                                                disabled={processing}
                                            >
                                                {t('back')}
                                            </button> */}
                                                <button
                                                    className='btn btn-save'
                                                    onClick={this.handleSubmitForm}
                                                    disabled={processing}
                                                >
                                                    {t('sign_up')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='event-scheduler-items'>
                                    <p className='event-scheduler-title'>{t('sign_up_as')}</p>
                                    <div className='scheduler-buttons'>
                                        <button
                                            className='btn btn-scheduler'
                                            onClick={() => this.setSubscriptionType('participant')}
                                        >
                                            {t('participant_live_event')}
                                        </button>
                                        <button
                                            className='btn btn-scheduler'
                                            onClick={() => this.setSubscriptionType('creator')}
                                        >
                                            {t('creator_live_events')}
                                        </button>
                                    </div>
                                </div>
                        }
                    </div>
                    :
                    <Loader
                        text={t('Loading')}
                        dots
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        events: state.firebase.events,
        userLoaded: state.firebase.userLoaded
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignIn: (value) => {
            dispatch(appActions.showSignIn({ signIn: value, signUp: true }));
        }
    };
};

const SignupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Signup);

export default withTranslation()(withRouter(SignupContainer));