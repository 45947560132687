import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withRouter, pricingArray } from '../../utils/HelperFunctions';
import pricing1 from '../../resources/images/pricing-1.png';
import about5 from '../../resources/images/about-5.png';
import editIcon from '../../resources/images/edit-icon.svg';
import cardIcon from '../../resources/images/credit-card.svg';
import monitorIcon from '../../resources/images/monitor.svg';
import config from '../../config';

class Pricing extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.state = {
            priceItem: pricingArray[0],

        }
    }

    render() {
        const { priceItem } = this.state;
        const { t } = this.props;
        // const { user } = this.props;

        return (
            <div className='about-wrapper'>
                <p className='about-title pricing'>{t('subscription_pricing')}</p>
                <div className='pricing-table'>
                    <table>
                        <thead>
                            <tr>
                                <th />
                                <th>{t('explorer')}</th>
                                <th>{t('pro')}</th>
                                <th>{t('enterprise')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>{t('subscription_term_1')}</p>
                                    <span>{t('subscription_term_2')}</span>
                                </td>
                                <td>{t('annual')}</td>
                                <td>{t('annual')}</td>
                                <td>{t('annual')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('price_per_month')}</p>
                                </td>
                                <td>{t('price_1')}</td>
                                <td>
                                    {pricingArray && pricingArray.length ?
                                        <select
                                            onChange={(e) => this.setState({ priceItem: JSON.parse(e.target.value) })}
                                        >
                                            {pricingArray.map((item, index) => {
                                                return (
                                                    <option key={index} style={{ background: '#1b1b23' }} value={JSON.stringify(item)}>{item.participants} {t('participants_l')}</option>
                                                )
                                            })}
                                        </select>
                                        : null}
                                    <p><span>{priceItem && priceItem.monthlyPrice ? `$ ${priceItem.monthlyPrice}` : ''}</span> {t('per_month')} <span>{priceItem && priceItem.annualPrice ? `$ ${priceItem.annualPrice}` : ''}</span> {t('paid_annually')}</p>
                                </td>
                                <td>{t('available_request')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('event_hour_allowance_1')}</p>
                                    <span>{t('event_hour_allowance_2')}</span>
                                </td>
                                <td>{t('per_quarter')}</td>
                                <td>{t('price_2')}</td>
                                <td>{t('unlimited')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('hosts_included_1')}</p>
                                    <span>{t('hosts_included_2')}</span>
                                </td>
                                <td>2</td>
                                <td>3</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('concurrent_events_1')}</p>
                                    <span>{t('concurrent_events_2')}</span>
                                </td>
                                <td>2</td>
                                <td>3</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('audience_size_st')}</p>
                                </td>
                                <td>{t('max_audience_size')}</td>
                                <td>{t('max_audience_size')}</td>
                                <td>{t('max_audience_size')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('single_sign_on')}</p>
                                </td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('live_chat')}</p>
                                </td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('screenshare_play_video')}</p>
                                </td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('admit_audience_member_1')}</p>
                                    <span>{t('admit_audience_member_2')}</span>
                                </td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('event_branding_1')}</p>
                                    <span>{t('event_branding_2')}</span>
                                </td>
                                <td>{t('corporate_logo')}</td>
                                <td>{t('branding_event_card')}</td>
                                <td>{t('branding_event_card')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('event_recording_1')}</p>
                                    <span>{t('event_recording_2')}</span>
                                </td>
                                <td>{t('no')}</td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('event_analytics_1')}</p>
                                    <span>{t('event_analytics_2')}</span>
                                </td>
                                <td>{t('no')}</td>
                                <td>{t('yes')}</td>
                                <td>{t('yes')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('pre_event_rehearsals_1')}</p>
                                    <span>{t('pre_event_rehearsals_2')}</span>
                                </td>
                                <td>{t('price_3')}</td>
                                <td>{t('unlimited')}</td>
                                <td>{t('unlimited')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('attendee_overage_1')}</p>
                                    <span>{t('attendee_overage_2')}</span>
                                </td>
                                <td>{`$1 ${t('per_hour')}`}</td>
                                <td>{`$0.50 ${t('per_hour')}`}</td>
                                <td>{`$0.30 ${t('per_hour')}`}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('price_4_1')}</p>
                                    <span>{t('price_4_2')}</span>
                                </td>
                                <td>{t('price_5')}</td>
                                <td>{t('price_6')}</td>
                                <td>{t('unlimited')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{t('payments')}</p>
                                </td>
                                <td>{t('paid_1')}</td>
                                <td>{t('paid_2')}</td>
                                <td>{t('paid_2')}</td>
                            </tr>
                            <tr>
                                <td />
                                <td>
                                    <Link to="/signup">
                                        <button className='btn btn-creator'>{t('subscribe_for_explorer')}</button>
                                    </Link>
                                </td>
                                <td>
                                    {t('about_pro')}
                                    <a
                                        href={`https://${config.homeSiteDomain}/help#ContactUsform`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t('contact_us')}
                                    </a>
                                </td>
                                <td>
                                    {t('about_enterprise')}
                                    <a
                                        href={`https://${config.homeSiteDomain}/help#ContactUsform`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t('contact_us')}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='how-does-wrapper' style={{ backgroundImage: `url(${about5})` }}>
                    <p className='how-title'>{t('how_does')}</p>
                    <div className='how-does-items'>
                        <div className='item'>
                            <img src={editIcon} alt="edit" loading='lazy' />
                            <p className='p-title'>{t('how_title_1')}</p>
                            <p>{t('how_text_1')}</p>
                        </div>
                        <div className='item'>
                            <img src={cardIcon} alt="card" loading='lazy' />
                            <p className='p-title'>{t('how_title_2')}</p>
                            <p>{t('how_text_2')}</p>
                        </div>
                        <div className='item'>
                            <img src={monitorIcon} alt="monitor" loading='lazy' />
                            <p className='p-title'>{t('how_title_3')}</p>
                            <p>{t('how_text_3')}</p>
                        </div>
                    </div>
                </div>
                <div className='pricing-info'>
                    <p>
                        {t('contact_info')}
                        <a
                            href={`https://${config.homeSiteDomain}/help#ContactUsform`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('request_quote')}
                        </a>
                        .
                    </p>
                </div>
                <div className='about-details-wrapper'>
                    <div className='details-text-wrapper'>
                        <p className='details-title'>{t('over_title')}</p>
                        <p className='details-text'>{t('over_text')}</p>
                    </div>
                    <img src={pricing1} loading='lazy' alt="about4" />
                </div>
                {/* <div className='over-wrapper'>
                    <div className='over-details-wrapper'>
                        <div className='over-details-text'>
                            <p className='over-title'>{t('over_title')}</p>
                            <p className='over-text'>{t('over_text')}</p>
                        </div>
                        <img src={pricing1} alt='pricing' loading='lazy' />
                    </div>
                    <div className='send-message-form'>
                        <p className='sm-title'>{t('contact_us_form_btn')}</p>
                        <p className='msg-label'>{t('contact_us_form_text')}</p>
                        <textarea rows={4} />
                        <button className='btn btn-send-msg'>{t('contact_us_form_btn')}</button>
                        <p className='our-contact-us'>{t('or_contact_us')}</p>
                        <Mailto email={"support@strmz.net"} subject={t('contact_us')}>
                            <div className='message-mail'>
                                <img src={mailIcon} alt='email' loading='lazy' />
                                <p>support@strmz.net</p>
                            </div>
                        </Mailto>
                        <div className='mesage-icons'>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <img src={facebookIcon} alt='facebook' loading='lazy' />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <img src={instagramIcon} alt='instagram' loading='lazy' />
                            </a>
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <img src={twitterIcon} alt='twitter' loading='lazy' />
                            </a>
                        </div>
                    </div>
                </div> */}
                {/* {user && user.eventAdmin ?
                    <div className='about-schedule-wrapper pricing'>
                        <div className='schedule-div'>
                            <p className='schedule-title'>{t('about_s_t')}</p>
                            <p className='schedule-text'>{t('about_s_d')}</p>
                            <Link to='/scheduler'>
                                <button className='btn schedule-btn'>
                                    {t('create_event')}
                                </button>
                            </Link>
                        </div>
                    </div>
                    : null} */}
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user
    };
};

const PricingContainer = connect(
    mapStateToProps
)(Pricing);

export default withTranslation()(withRouter(PricingContainer));
