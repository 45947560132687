export const TRANSLATIONS_EN = {
    not_found: "Not Found",
    about: "About",
    partners: "Partners",
    pricing: "Pricing",
    subscription_pricing: "Subscription Pricing",
    sign_up: "Sign up",
    sign_up_as: "Sign up as:",
    create_event: "Create event",
    update_event: "Update event",
    view_pricing: "View pricing",
    home_monitor: "Create large scale LIVE digital meetings in minutes",
    home_dolar: "Host events for 50,000+ attendees",
    home_play: "Stream high quality video with zero delay",
    home_empty: "Interact with your live audience via Chat and Raise Hand",
    home_bar: "View attendee analytics and download event recordings with one click",
    strmz_io_stream: "Strmz.io",
    strmz_io_text: "Strmz.io is a US streaming platform powered by innovative real-time video streaming technology. Digital video events with thousands of people experience sub-second delay, interactive host-to-viewer engagement, and consistently high quality even as viewership scales!",
    about_t_1: "Host events with\n 50,000+ viewers",
    about_d_1: "With our patented fanout engine, digital events can stream out to thousands of participants, with high video and audio quality at scale. It also drastically lowers the cost of hosting large-scale events, meaning massive savings for you and your organization. There’s even a free subscription!",
    about_t_2: "Sub-Second Delay",
    about_d_2: "Most streaming platforms experience up to 12 seconds of delay out to their viewers, making true engagement and seamless interaction with your audience almost impossible. Our fanout engine solves this problem with sub-second latency, meaning there is virtually no delay between what you say, and what your viewers hear.",
    about_t_3: "Interactivity",
    about_d_3: "Strengthen your engagement with your audience, using screen-sharing, live chat, and the ability to invite viewers to join in the video stream themselves.",
    about_t_4: "Have a question regarding pricing, tech issues or otherwise? ",
    faq: "FAQ",
    faq_t_1: "What is Strmz.io?",
    faq_d_1: "Strmz.io is an interactive virtual venue where you can host events using Real Time Streaming to connect with your staff, your customers, your partners or your target market. Because we feature sub-second latency (meaning virtually no delay) and a host of interactive features, we’re the ideal solution for large scale team meetings, group trainings, digital events, webinars, and more. Best of all? We can bring you this for a fraction of the cost of our competitors!",
    faq_t_2: "What makes Strmz.io different from other digital venue platforms?",
    faq_d_2: " Most “Livestreams” are not truly live – there can be anything from 8-30 seconds of delay between the streamer and viewers. Hosting events on Strmz.io brings the power of Real Time Video Streaming to your live event, with sub-second delay between you and your viewers. You can bring guests into your events using integrated video calls with unbeatable sound and video quality. The biggest difference, however, is the scalability of these features, providing exceptional video and audio quality even as your events scale to tens of thousands of viewers. And the great news for your budget is that our smart, optimized fanout architecture allows us to deliver our service to you very cost effectively, so you get higher quality in real-time at a market-busting price!",
    faq_t_3: "How can I host an event on Strmz.io?",
    faq_d_3: " Once you’ve subscribed to one of the Strmz.io subscriptions, hosting an event is as simple as setting a date, time, number of participants, and event duration on the Create Event page. After that, you’ll be provided with links for your guest speakers and audience to attend.",
    faq_t_4: "Can I add more time or participants to an event after I’ve already scheduled It?",
    faq_d_4: "Yes! In your account dashboard, you can view all of your upcoming events and edit any event to add speakers, extend your audience or adjust the duration your event. It’s just that simple!  This also applies to events that are already underway - you can add more participants and/or more time even while you’re hosting your event!",
    faq_t_5: "What browsers are compatible with Strmz.io?",
    faq_d_5: "Strmz.io is compatible with Google Chrome, Safari and Microsoft Edge on both desktop and mobile browsers. We recommend Google Chrome for best performance.",
    faq_t_6: "What devices can I host and stream from?",
    faq_d_6: "You can stream from a computer or from Apple or Android smartphones and tablets. We recommend streaming from a modern computer to ensure the best quality stream performance for your viewers.",
    faq_t_7: "How fast should my internet speed be to host an event?",
    faq_d_7: "To stream at 1080p resolution we recommend having a minimum upload speed of 5 mb/s. To ensure the most steady connection while live, we recommend setting up a wired internet connection.",
    faq_t_8: "Can I use my built-in laptop/smartphone camera and microphone?",
    faq_d_8: "While you can use the built-in camera and microphones for both your computers and smartphones, a better quality experience can be had by investing in a high quality external webcam and USB microphone. These aren’t essential to a high-quality video and audio experience, but can make the difference between good and great for your viewers.",
    about_s_t: "Sign up for the free Explorer subscription now!",
    about_s_d: "It only takes a few minutes",
    contact_us: "Contact us",
    contact_us_t: "Have a question regarding pricing, tech issues, or otherwise? Send us an email at support@strmz.net.",
    contact_us_form_text: "Your message",
    contact_us_form_btn: "Send message",
    hour: "hour",
    hours: "hours",
    participants: "Participants",
    estimate: "Estimate your event cost",
    pricing_text: "Please be informed: this amount is given for your reference and may change, depending on the actual number of participants that attend your event and the actual duration of your event.",
    est_participants: "Expected participants",
    est_duration: "Expected duration",
    total: "Total",
    how_does: "How does it work?",
    how_title_1: " Choose your preferred subscription",
    how_text_1: " If you’re just getting started with digital events, try our Explorer subscription – it’s FREE!",
    how_title_2: " Create an event ",
    how_text_2: " Choose the number of your participants, your event duration, set a date, and we’ll do the rest",
    how_title_3: " Host your event",
    how_text_3: " Once you’ve created the event, you’ll have everything you need to stream your event to all your participants",
    over_title: "over 50,000 participants on your event?",
    over_text: "Regardless of how many people you have attending your events, you can enjoy high quality video and audio streaming with sub-second delay.",
    or_contact_us: "or contact us",
    contact_info: "To request a quote or discuss your requirements for a special event, please ",
    request_quote: "Request a Quote",
    privacy: "Privacy",
    terms: "Terms",
    sign_in: "Sign in",
    agree_1: "I agree to the Strmz.io ",
    terms_of_service: "Terms of Service",
    and: "and",
    privacy_policy: " privacy policy",
    google_sign: "Sign in with Google",
    apple_sign: "Sign in with Apple",
    microsoft_sign: "Sign in with Microsoft",
    mail_sign: "Sign in with email",
    google_sign_up: "Sign up with Google",
    apple_sign_up: "Sign up with Apple",
    microsoft_sign_up: "Sign up with Microsoft",
    mail_sign_up: "Sign up with email",
    logout: "Logout",
    create_event_info: "Please be informed all meetings on this platform are recorded automatically. You will be able to access your meeting recording on your Completed Events page.",
    cet_1: "Event description",
    cet_2: "Time and date",
    cet_3: "Payment",
    cet_4: "Share event registration",
    cet_5: "Schedule an event",
    cet_6: "User information",
    cet_7: "Company information",
    cet_8: "Participant information",
    event_title: "Event Title",
    event_subtitle: "Event Subtitle",
    presenters_emails: "Guest Speakers’ emails",
    add_pres_email: "Add Guest Speaker email",
    moderators_email: "Moderator email",
    add_moderators: "Add Moderators",
    duration: "Duration",
    logo_upload: "In-event logo",
    corporate_logo_upload: "Corporate logo",
    image_upload: "Profile image",
    background_audience_lobby_upload: "Background for the audience lobby",
    wide_header_banner_upload: "Wide header banner",
    narrow_header_banner_upload: "Narrow header banner",
    wide_footer_banner_upload: "Wide footer banner",
    narrow_footer_banner_upload: "Narrow footer banner",
    wide_supplementary_picture_upload: "Wide supplementary picture",
    narrow_supplementary_picture_upload: "Narrow supplementary picture",
    switch_1: "Hosts can remove Guest Speakers from the live stream",
    switch_2: "Moderators can remove Guest Speakers from the live stream",
    switch_3: "Only I can share the screen",
    switch_4: "Only I can send messages to the chat",
    switch_5: `Availability "Raise hand"`,
    next: "Next",
    back: "Back",
    time_zone: "Time zone",
    pay$: "Pay $",
    add_new_card: "Add new card",
    payment_method: "Payment method",
    stripe_info: "Your payment will be processed via Stripe, and they securely store your card information for future purchases. Once your payment is verified by Stripe, you will receive a confirmation email, and your event will be created",
    time: "Time",
    date: "Date",
    recurring: "Recurring",
    event_link: "Event link",
    copy_host_guest_moderator_link: "Copy Host, Moderator and Guest Speaker link",
    copy_host_link: "Copy Host link",
    copy_host_link_prefix: "(for Hosts, Guest Speakers and Moderators)",
    copy_audience_link: "Copy Audience link",
    view_event_card: "View event card",
    view_event_page: "View event page",
    my_events: "My Events",
    my_profile: "My Profile",
    my_subscription: "My Subscription",
    subscription: "Subscription",
    home: "Home",
    help: "Help",
    welcome_e: "Welcome!",
    upcoming_e: "Upcoming",
    completed_e: "Completed",
    date_and_time: "Date and time",
    reserved_tickets: "Reserved tickets",
    payment_details: "Payment details",
    edit_event: "Edit event",
    copy_link: "Copy link",
    get_recording: "Get recording",
    generating_recording: "Generating recording",
    recording: "Recording",
    view_more: "View more",
    link_copied: "Link copied",
    more_details: "More details",
    hide_details: "Hide details",
    save_changes: "Save changes",
    change_password: "Change password",
    cancel: "Cancel",
    edit: "Edit",
    update: "Update",
    delete: "Delete",
    save: "Save",
    no_events_message: "Your event list is empty",
    event_description: "Event Description",
    cs_event_page: "Event Page",
    cs_event_title: "Event Title",
    cs_event_details: "Event Details",
    cs_event_registration: "Event Registration",
    cs_event_registration_page: "Event Registration Page",
    text_color: "Text",
    bg_color: "Background",
    btn_color: "Button",
    default: "Default",
    preview_erp: "Preview",
    preview_al: "Preview",
    in_event_logo_text: "This logo will be displayed to all participants during the event",
    replace_logo: "Click to replace in-event logo",
    replace_corporate_logo: "Click to replace the corporate logo",
    replace_image: "Click to replace the image",
    replace_background_audience_lobby: "Click to replace the background for the audience lobby",
    replace_wide_header_banner: "Click to replace the wide header banner",
    replace_narrow_header_banner: "Click to replace the narrow header banner",
    replace_wide_footer_banner: "Click to replace the wide footer banner",
    replace_narrow_footer_banner: "Click to replace the narrow footer banner",
    replace_wide_supplementary_picture: "Click to replace the wide supplementary picture",
    replace_narrow_supplementary_picture: "Click to replace the narrow supplementary picture",
    start_date: "Start Date",
    start_time: "Start Time",
    add_host: "Add Host",
    add_guest_speaker: "Add Guest Speaker",
    add_moderator: "Add Moderator",
    companyName: "Company Name",
    enter_cn: "Enter company name",
    companyAddress: "Company Address",
    enter_ca: "Enter company address",
    companySize: "Company Size",
    enter_cs: "Enter company size",
    phoneNumber: "Phone Number",
    phoneNumber_text: "Phone Number (+1, followed by 10 digits)",
    phoneNumber_text2: "(+1, followed by 10 digits)",
    enter_pn: "Enter phone number",
    username: "Username",
    enter_username: "Enter username",
    first_name: "First Name",
    enter_fn: "Enter first name",
    last_name: "Last Name",
    enter_ln: "Enter last name",
    enter_title: "Enter title",
    enter_subtitle: "Enter subtitle",
    enter_description: "Enter description",
    enter_email: "Enter email",
    email_p: "Email",
    password: "Password",
    confirm_password: "Confirm Password",
    enter_confirm_password: "Confirm new password",
    audience_size: "Audience Size",
    audience_size_included: "Audience Size Included",
    duration_hours: "Duration (hours)",
    some_error: "Some error occurred",
    update_user_error: "Update user failed",
    update_user_subscription_error: "Update user subscription failed",
    subscription_request_error: "Error submitting subscription request",
    please_wait: "Please wait",
    loading: "Loading",
    error: "Error",
    pairing: "Pairing",
    ok: "Ok",
    pair: "Pair",
    different_provider: "You have already signed up with a different auth provider for that email.",
    like_pair: "Would you like to pair accounts?",
    closed_popup: "You closed the pop-up window before the operation was completed.",
    login_cancelled: "Login cancelled",
    require_audience_authenticate: "Require the audience to login to the event using their email and password",
    hd_video_streaming: "HD video streaming",
    automatically_record: "Automatically record the event?",
    get_audience: "Get audience",
    audience_attendance: "Audience attendance for event",
    name: "Name",
    company: "Company",
    title: "Title",
    role: "Role",
    email: "Email",
    your_email: "Your email",
    your_password: "Your password",
    your_confirm_password: "Your confirm password",
    banned: "Banned",
    hand: "Raise Hand",
    yes: "Yes",
    no: "No",
    attendee_analytics: "Attendee Analytics",
    participants_analytics: "Participants Analytics",
    audience_analytics: "Audience Analytics",
    analytics: "Analytics",
    time_in_event: "Time in Event",
    security: "Security",
    clone: "Clone event",
    started: "Started",
    stopped: "Stopped",
    registration_time: "Registration Time",
    total_audience_registrations: "Total Audience Registrations",
    first_join: "First join",
    last_leave: "Last leave",
    no_analytics: "We have no analytics for ",
    max_count: "Maximum number of participants",
    total_count: "Total number of participants",
    max_audience: "Maximum number of audience",
    total_audience: "Total number of audience",
    password_invalid: "The password is invalid",
    no_user_email: "There is no user with this email",
    create_account: "Create account",
    reset_password: "Reset password",
    reset: "Reset",
    resetting_password: "Resetting password",
    new_password: "New Password",
    enter_new_password: "Enter new password",
    reset_message: "A mail for reset password has been sent to",
    success_password: "Password changed successfully, use new password the next time you sign in.",
    success_subscription_request: "Sign Up successful! You will hear from us in the next 24 hours, at the phone number you provided, to discuss your event creation needs.",
    success_participant_message: "You have successfully edited your user data.",
    success_added_host: "You have successfully added a host.",
    error_adding_host: "Error adding host",
    success_edited_host: "You have successfully edited the host.",
    error_editing_host: "Error editing host",
    success_deleted_host: "You have successfully deleted the host.",
    error_deleting_host: "Error deleting host",
    no_user_1: "Don’t have an account on Strmz.io?",
    no_user_2: "Click ",
    no_user_3: " to create one!",
    here: "here",
    submit: "Submit",
    exist_user: "Already have an account on Strmz.io? ",
    pass_text_2: "Enter your new password for Strmz.io. Password rules:",
    pass_text: "Minimum 8 characters, at least one letter, at least one number and at least one special character.",
    error_another_account: "The email address is already in use by another account.",
    valid_1: "You must enter your email",
    valid_2: "You must enter a valid email",
    valid_3: "You must enter your password",
    valid_4: "You must enter a valid password",
    valid_5: "You must enter your confirmation password",
    valid_6: "You must enter a valid confirmation password",
    valid_7: "Passwords do not match",
    valid_8: "You must enter your username",
    valid_9: "You must enter a valid username, at least two characters",
    valid_10: "You must enter your first name",
    valid_11: "You must enter a valid first name, at least two characters",
    valid_12: "You must enter your last name",
    valid_13: "You must enter a valid last name, at least two characters",
    valid_14: "You must enter your title",
    valid_15: "You must enter a valid title, at least two characters",
    valid_16: "You must enter company name",
    valid_17: "You must enter company address",
    valid_18: "You must enter company size",
    valid_19: "You must enter phone number",
    valid_20: "You must enter a valid phone number (+1, followed by 10 digits)",
    valid_21: "You must add a profile picture",
    valid_22: "You must enter title",
    valid_23: "You must enter a company name, at least two characters",
    valid_24: "You must enter your name",
    valid_25: "You must enter a valid name, at least two characters",
    valid_26: "You must confirm Event Participant Terms of Use",
    valid_27: "You must confirm Strmz.io Subscription Terms of Service",
    verify_message: "We have sent a verification email to your specified email address. Please check your inbox for the email and click on the verification link to complete sign-up.",
    send_again: "Send again",
    sended_again: "Verification email sent again",
    participant_live_event: "Participant in a Live Event",
    creator_live_events: "Creator of Live Events",
    company_details: "Company Details",
    explorer: "Explorer",
    pro: "Pro",
    enterprise: "Enterprise",
    subscription_term_1: "Subscription Term",
    subscription_term_2: "Default term of the subscription agreement. You can cancel at any time.",
    annual: "Annual",
    price_per_month: "Price Per Month",
    price_1: "Free for up to 1,000 users!",
    participants_l: "participants",
    per_month: "per month or",
    paid_annually: "if paid annually in advance.",
    available_request: "Available on request",
    event_hour_allowance_1: "Event Hour Allowance",
    event_hour_allowance_2: "The number of hours you are allocated for events per subscription type",
    per_quarter: "3 per quarter, with rollover of unused events to the next quarter",
    price_2: "24 per year, with rollover of unused events to the next year’s subscription",
    unlimited: "Unlimited",
    hosts_included_1: "Number of Hosts included",
    hosts_included_2: "The maximum number of Hosts you can nominate to host events",
    concurrent_events_1: "Concurrent Events",
    concurrent_events_2: "The maximum number of events that can take place at the same time",
    single_sign_on: "Single Sign-on with Google and Microsoft AD",
    // single_sign_on: "Single Sign-on with Google, Microsoft AD and Okta",
    live_chat: "Live Chat",
    screenshare_play_video: "Screenshare & Play Video",
    admit_audience_member_1: "Raise Hand feature",
    admit_audience_member_2: "Allow audience members to Raise Hand and be brought in to join the panel discussion in the live stream",
    branding: "Branding",
    event_branding_1: "Event Branding",
    event_branding_2: "Add your brand to the event",
    corporate_logo: "Your corporate logo displayed during the event",
    // corporate_logo: "Your corporate logo displayed during the event. \n Option to add further branding for $200",
    branding_event_card: "Add your corporate logo, plus add your brand to the Event Card and Audience Lobby",
    event_recording_1: "Event Recording",
    event_recording_2: "Automatically record your event and download the recording as an mp4 file",
    a_la_carte_recording: "Add for $200 per event",
    event_analytics_1: "Event Analytics",
    event_analytics_2: "Pre-event registration reports and post-event attendance report, including all audience member’s names, titles, company names and email addresses",
    a_la_carte_analytics: "Add for $200 per event",
    pre_event_rehearsals_1: "Pre-Event Rehearsals",
    pre_event_rehearsals_2: "Meet in the green room of the event to prepare/rehearse in advance of the event",
    price_3: "30 minutes prior to event",
    // price_3: "30 minutes prior to event. \n Option to extend to unlimited for $100",
    attendee_overage_1: "Attendee Overage",
    attendee_overage_2: "The charge per extra attendee, if your event attendance exceeds your audience size allowance",
    price_4_1: "Duration Overage Fee",
    price_4_2: "Overage fee charged if you exceed the Event Hour allowance associated with your subscription",
    price_5: " $100 per quarter hour",
    price_6: "Waived when you renew your subscription or upgrade to Enterprise",
    payments: "Payments",
    paid_1: "Overages paid by credit card",
    paid_2: "Monthly or annual invoice",
    mp_1: "If you would like to request a change to your company name, company address, company size, or email address, please contact ",
    customer_support: "Customer Support",
    audience_size_st: "Audience Size Scales To",
    max_audience_size: "50,000+ attendees",
    loading_subscriptions: "Loading your subscription",
    loading_hosts: "Loading your hosts",
    loading_analytics: "Loading analytics",
    no_subscriptions: "No subscriptions",
    do_not_access: "You do not have access to this page",
    enabled: "enabled",
    disabled: "disabled",
    subscription_start_date: "Subscription Start Date",
    subscription_renewal_date: "Subscription Renewal Date",
    payment_schedule: "Payment Schedule",
    status: "Status",
    event_hour_balance: "Event Hour Balance",
    nominated_1: "You have nominated",
    nominated_2: "host of the",
    nominated_3: "hosts of the",
    nominated_4: "host in your subscription quota",
    nominated_5: "hosts in your subscription quota",
    my_hosts: "My Hosts",
    hosts: "Hosts",
    host_s: "Host(s)",
    guest_speaker_s: "Guest Speaker(s)",
    moderator_s: "Moderator(s)",
    upgrade_to_pro: "Request an upgrade to Pro",
    upgrade_to_enterprise: "Request an upgrade to Enterprise",
    hb_1: "You have",
    hb_2: "event hours remaining from your subscription quota of",
    hb_3: "event hours",
    current_hour_balance: "Your current event hour balance",
    the_audience_size_included: "Your audience size included",
    manage_my_hosts: "Manage My Hosts",
    modal_delete_host: "You are about to delete a host.",
    want_continue: "Do you want to continue?",
    modal_delete_event: "You are about to delete the event.",
    only_domains: "Only permit attendees from domains you specify",
    add_domain: "Add domain",
    enter_domain: "Enter domain",
    specify_text: "Specify all permitted email domains, use the wildcard “*” if required",
    host_1: "This host is currently scheduled to participate in",
    host_2: "upcoming events. Please remove them from those events before deleting them as hosts.",
    available_hosts: "Available hosts",
    no_hosts_selected: "No hosts selected",
    no_hosts_available: "No hosts available",
    no_hosts_configured: "No hosts configured",
    events_p1: "Upgrade to",
    events_p2: "to enable event analytics, branding and recording.",
    accept_charge: "Accept Charge",
    ap_1: "The estimated charge for this event, based on the specified duration and audience size, is ",
    ap_2: " and will be invoiced to you, based on actual duration and attendance, when the event ends.",
    ap_3: "The estimated charge for this event, based on the specified duration, is ",
    ap_4: " and will be invoiced to you, based on actual duration, when the event ends.",
    manage_my_subscription: "Manage my Subscription",
    sign_in_text: "Once you have ticked the box to accept our terms of service, please login using the email address and password you’d like to associate with your new account. Note that the email address you choose to sign in with will become your new Account ID.",
    sign_up_participant: "Please tick the box to agree to the ",
    e_p_terms_of_use: "Event Participant Terms of Use",
    nfss_terms_of_service: "Strmz.io Subscription Terms of Service",
    summary: "Summary",
    audience_overage_quote: "Audience Overage Quote",
    duration_overage_quote: "Duration Overage Quote",
    free_explorer_subscription: "Get a free explorer subscription",
    about_pro: "Want to find out more about Pro? ",
    about_enterprise: "Want to find out more about Enterprise? ",
    subscribe_for_explorer: "Subscribe to Explorer!",
    per_hour: "per hour",
    sign_in_no_user_1: "Don’t have an account on Strmz.io?\nClick ",
    sign_in_no_user_2: " to Sign Up!",
    sign_up_lready_user_1: "Already have an account on Strmz.io?\nClick ",
    sign_up_lready_user_2: " to Sign In!",
    explorer_success_text: "Your Explorer subscription is now activated. Congratulations and Welcome to Strmz.io!",
    pro_success_text: "Your Pro subscription is now activated. Congratulations and Welcome to Strmz.io!",
    enterprise_success_text: "Your Enterprise subscription is now activated. Congratulations and Welcome to Strmz.io!",
    delete_event_error_1: "Could not delete event",
    delete_event: "Delete event",
    search: "Search",
    event_card_lobby: "Branding in Event Card and Audience Lobby",
    close: "Close",
    crop: "Crop",
    max_characters: "The maximum number of characters is {{characters}}",
    desktop: "Desktop",
    mobile: "Mobile",
    inbound_video: "Inbound Video",
    leave: "Leave",
    knock: "Raise Hand",
    chat: "Chat",
    event_registration: "Event registration",
    registration_panel: "Registration Panel",
    event_text: "To register for this event, please provide the following details",
    your_name: "Your Name",
    join: "Join",
    header_banner: "Header banner (optional)",
    footer_banner: "Footer banner (optional)",
    logo: "Logo",
    cor_logo: "Corporate logo (optional)",
    background_audience_lobby: "Background for the audience lobby (optional)",
    supplementary_picture: "Supplementary picture (optional)",
    erpd: "Event Registration Page Design",
    alpd: "In-event Audience Lobby Design",
    size_1: "(1280px x 320px)",
    size_2: "(320px x 200px)",
    size_3: "(200px x 200px)",
    size_4: "(1280px x 720px)",
    security_settings: "Security Settings for Participants",
    security_settings_text: "By default, all audience members will be required to validate their email addresses. If this event requires a higher level of security or confidentiality, you can enable one or both of the options below.",
    current_time: "Current time"
};
