import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Faq from "react-faq-component";
// import { Link } from 'react-router-dom';
import { withRouter } from '../../utils/HelperFunctions';
// import about1 from '../../resources/images/about-1.png';
import about2 from '../../resources/images/about-2.png';
import about3 from '../../resources/images/about-3.png';
import about4 from '../../resources/images/about-4.png';
import about5 from '../../resources/images/about-5.png';
import about6 from '../../resources/images/about-6.png';
import configData from '../../config';

class About extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        // const { user } = this.props;

        const styles = {
            bgColor: 'transparent',
            titleTextColor: "white",
            rowTitleColor: "white",
            rowContentColor: 'rgba(251, 251, 251, 0.7)',
            arrowColor: "white"
        };

        const config = {
            animate: true,
            tabFocus: false
        };

        const faq = {
            rows: [
                {
                    title: t('faq_t_1'),
                    content: t('faq_d_1'),
                },
                {
                    title: t('faq_t_2'),
                    content: t('faq_d_2'),
                },
                {
                    title: t('faq_t_3'),
                    content: t('faq_d_3'),
                },
                {
                    title: t('faq_t_4'),
                    content: t('faq_d_4'),
                },
                {
                    title: t('faq_t_5'),
                    content: t('faq_d_5'),
                },
                {
                    title: t('faq_t_6'),
                    content: t('faq_d_6'),
                },
                {
                    title: t('faq_t_7'),
                    content: t('faq_d_7'),
                },
                {
                    title: t('faq_t_8'),
                    content: t('faq_d_8'),
                }
            ]
        }


        return (
            <div className='about-wrapper'>
                <p className='about-title'>{t('strmz_io_stream')}</p>
                <p className='about-text'>{t('strmz_io_text')}</p>
                {/* <img className='about-image' src={about1} loading='lazy' alt="about1" /> */}
                <div className='about-details-wrapper' style={{ backgroundImage: `url(${about5})` }}>
                    <div className='details-text-wrapper'>
                        <p className='details-title'>{t('about_t_1')}</p>
                        <p className='details-text'>{t('about_d_1')}</p>
                    </div>
                    <img src={about2} loading='lazy' alt="about2" />
                </div>
                <div className='about-details-wrapper text-right'>
                    <img src={about3} loading='lazy' alt="about3" />
                    <div className='details-text-wrapper'>
                        <p className='details-title'>{t('about_t_2')}</p>
                        <p className='details-text'>{t('about_d_2')}</p>
                    </div>
                </div>
                <div className='about-details-wrapper'>
                    <div className='details-text-wrapper'>
                        <p className='details-title'>{t('about_t_3')}</p>
                        <p className='details-text'>{t('about_d_3')}</p>
                    </div>
                    <img src={about4} loading='lazy' alt="about4" />
                </div>
                <div className='faq-wrapper' style={{ backgroundImage: `url(${about6})` }}>
                    <p className='faq-title'>{t('faq')}</p>
                    <Faq data={faq} styles={styles} config={config} />
                </div>
                {/* {user && user.eventAdmin ?
                    <div className='about-schedule-wrapper'>
                        <div className='schedule-div'>
                            <p className='schedule-title'>{t('about_s_t')}</p>
                            <p className='schedule-text'>{t('about_s_d')}</p>
                            <Link to='/scheduler'>
                                <button className='btn schedule-btn'>
                                    {t('create_event')}
                                </button>
                            </Link>
                        </div>
                    </div>
                    : null
                } */}
                <div className='pricing-info'>
                    <p>
                        {t('about_t_4')}
                        <a
                            href={`https://${configData.homeSiteDomain}/help#ContactUsform`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('contact_us')}
                        </a>
                        .
                    </p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user
    };
};

const AboutContainer = connect(
    mapStateToProps
)(About);

export default withTranslation()(withRouter(AboutContainer));
