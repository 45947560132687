import React, { Component } from "react";
import { connect } from 'react-redux';
import logo from '../../resources/images/strmzio-logo.png';
import { withTranslation } from 'react-i18next';
import config from '../../config';

class Footer extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <div className='footer'>
                <div className='footer-logo'>
                    <a
                        href={`https://${config.homeSiteDomain}/`}
                        onClick={() => window.scrollTo(0, 0)}
                    >
                        <img src={logo} alt='logo' />
                    </a>
                </div>
                <div className='footer-links'>
                    <div className='footer-links-1' />
                    <div className='footer-links-2'>
                        <a
                            href={`https://${config.homeSiteDomain}/privacy-policy`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-link"
                        >
                            {t('privacy')}
                        </a>
                        <a
                            href={`https://${config.homeSiteDomain}/terms-and-policies`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-link"
                        >
                            {t('terms')}
                        </a>
                        <a
                            href={`https://${config.homeSiteDomain}/security-overview`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-link"
                        >
                            {t('security')}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        active: state.app.active
    };
};

const FooterContainer = connect(
    mapStateToProps
)(Footer);

export default withTranslation()(FooterContainer);