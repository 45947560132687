// Dev
const dev = {
    build: "DEV",
    homeSiteDomain: 'www.strmz.io',
    googleClientId: '104795671843-4dgpder6d9lrpj72sum0aroat257gr1f.apps.googleusercontent.com'
};
// Prod
const prod = {
    build: "PROD",
    homeSiteDomain: 'www.strmz.io',
    googleClientId: '104795671843-4dgpder6d9lrpj72sum0aroat257gr1f.apps.googleusercontent.com'
};

const config = process.env.NODE_ENV === 'development'
    ? dev
    : prod;


let configData = {
    ...config
}

export default configData;