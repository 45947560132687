import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import momentZone from "moment-timezone";
import ReactTooltip from 'react-tooltip';
import { Editor } from "react-draft-wysiwyg";
import { 
    withRouter, 
    getTextParticipant, 
    getTextDuration,
    getTenantId
} from '../../utils/HelperFunctions';
import firebaseClient from '../../utils/FirebaseClient';
import Loader from '../Widget/Loader';
import strmzDefault from '../../resources/images/strmz-default.png';
import copyIcon from '../../resources/icons/copy.svg';

class EventPage extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.state = {
            event: null
        }
    }

    componentDidMount() {
        const { params } = this.props;

        if (params && params.eventId) {
            firebaseClient.getEvent(params.eventId).then((event) => {
                if (event) {
                    this.setState({
                        event: event
                    });
                }
            })
        }
    }

    handleCopy(event, type, ref) {
        const { user } = this.props;

        let tenantId = getTenantId(user);

        if (event && type) {
            if (ref) {
                ref.disabled = true;
            }
            firebaseClient.getCopyLink(event, type, tenantId).then((link) => {
                if (link) {
                    ReactTooltip.show(ref);
                    navigator.clipboard.writeText(link);
                    if (ref) {
                        ref.disabled = false;
                    }
                    setTimeout(() => {
                        ReactTooltip.hide(ref);
                    }, 2000);
                }
            });
        }
    }

    render() {
        const { event } = this.state;
        const { t, user } = this.props;

        return (
            <div>
                {event ?
                    <div className='event-page-wrapper'>
                        <div className='event-header'>
                            <div className='event-info'>
                                <img src={event.logo ? event.logo : strmzDefault} alt='company' loading='lazy' />
                                <p>{event.title}</p>
                            </div>
                        </div>
                        <div className='event-body'>
                            {event.description && ((typeof event.description !== 'string' && event.descriptionText) || typeof event.description === 'string') ?
                                <div className='event-line'>
                                    <p>{t('event_description')}:</p>
                                    <div className='value'>
                                        {typeof event.description !== 'string' ?
                                            (
                                                <Editor
                                                    toolbarHidden
                                                    readOnly
                                                    initialContentState={event.description}
                                                />
                                            )
                                            : event.description}
                                    </div>
                                </div>
                                : null
                            }
                            <div className='event-line'>
                                <p>{t('audience_size')}:</p>
                                <p className='value'>{`< ${getTextParticipant(event.participants)}`}</p>
                            </div>
                            <div className='event-line'>
                                <p>{t('host_s')}:</p>
                                <p className='value'>
                                    {event.hosts && event.hosts.length ?
                                        event.hosts.map((host, index) => {
                                            if (!index) {
                                                return (
                                                    <span key={index}>{`${host.username || host.mail || host.email}`}</span>
                                                )
                                            } else {
                                                return (
                                                    <span key={index}>{`, ${host.username || host.mail || host.email}`}</span>
                                                )
                                            }
                                        })
                                        : ''}
                                </p>
                            </div>
                            <div className='event-line'>
                                <p>{t('duration')}:</p>
                                <p className='value'>{`< ${getTextDuration(event.duration, t('hour'), t('hours'))}`}</p>
                            </div>
                            <div className='event-line'>
                                <p>{t('date')}:</p>
                                <p className='value'>{moment(new Date(event.startDate ? event.startDate : moment().unix())).format('L')}</p>
                            </div>
                            <div className='event-line'>
                                <p>{t('time')}:</p>
                                <p className='value'>{moment(new Date(event.startDate ? event.startDate : moment().unix())).format('HH:mm a')}, {moment.tz(momentZone.tz.guess()).zoneAbbr()}</p>
                            </div>
                            {event.id && event.status && event.status !== 'ended' && event.uid && user && user.uid && event.uid === user.uid ?
                                <div className='btn-copy-wrapper'>
                                    <button
                                        className='btn btn-copy'
                                        onClick={() => this.handleCopy(event, 'admin', this.firstTooltip)}
                                        data-for="event-admin-link"
                                        data-tip={t('link_copied')}
                                        ref={ref => this.firstTooltip = ref}
                                    >
                                        <span>{t('copy_host_link')}<br /><label>{t('copy_host_link_prefix')}</label></span>
                                        <img src={copyIcon} alt="copy" />
                                    </button>
                                    <ReactTooltip
                                        id="event-admin-link"
                                        isCapture={true}
                                        className=""
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                        event="none"
                                        eventOff="click"
                                        delayHide={2000}
                                    />
                                </div>
                                : null
                            }
                            {event.id && event.status && event.status !== 'ended' ?
                                <div className='btn-copy-wrapper audience'>
                                    <button
                                        className='btn btn-copy'
                                        onClick={() => this.handleCopy(event, 'audience', this.secondTooltip)}
                                        data-for="event-audience-link"
                                        data-tip={t('link_copied')}
                                        ref={ref => this.secondTooltip = ref}
                                    >
                                        <span>{t('copy_audience_link')}</span>
                                        <img src={copyIcon} alt="copy" />
                                    </button>
                                    <ReactTooltip
                                        id="event-audience-link"
                                        isCapture={true}
                                        className=""
                                        place="bottom"
                                        type="dark"
                                        effect="solid"
                                        event="none"
                                        eventOff="click"
                                        delayHide={2000}
                                    />
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    :
                    <Loader
                        text={t('Loading')}
                        dots
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user
    };
};

const EventPageContainer = connect(
    mapStateToProps
)(EventPage);

export default withTranslation()(withRouter(EventPageContainer));