import React from 'react';

function Mailto({ email, subject = '', body = '', children }) {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return (
        <a
            href={`mailto:${email}${params}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    )
}

export default Mailto
