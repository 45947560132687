import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import ClickOutside from '../Widget/ClickOutside';
import firebaseClient from '../../utils/FirebaseClient';
import { getUserAvatar } from '../../utils/HelperFunctions';

function handleShowList(value, callback, menu) {
    if (value !== menu) {
        callback(value);
    }
}

function Profile() {
    const { t } = useTranslation();
    const user = useSelector(state => state.firebase.user);
    const [profileList, setProfileList] = useState(false);

    return (
        <ClickOutside
            className='header-profile'
            clickOutside={() => handleShowList(false, (value) => setProfileList(value), profileList)}
        >
            <img
                src={getUserAvatar(user)}
                onClick={() => setProfileList(!profileList)}
                alt='profile'
                loading='lazy'
            />
            {profileList ?
                <div className='profile-list'>
                    {user && user.eventAdmin ?
                        <button
                            className='btn btn-profile-list'
                            onClick={() => setProfileList(false)}
                        >
                            <NavLink
                                to="/my-profile"
                                className="nav-link"
                            >
                                {t('my_profile')}
                            </NavLink>
                        </button>
                        : null
                    }
                    {user && user.eventAdmin && user.subscription ?
                        <button
                            className='btn btn-profile-list'
                            onClick={() => setProfileList(false)}
                        >
                            <NavLink
                                to="/my-subscription"
                                className="nav-link"
                            >
                                {t('my_subscription')}
                            </NavLink>
                        </button>
                        : null
                    }
                    {user && user.eventAdmin && user.subscription ?
                        <button
                            className='btn btn-profile-list'
                            onClick={() => setProfileList(false)}
                        >
                            <NavLink
                                to="/my-hosts"
                                className="nav-link"
                            >
                                {t('my_hosts')}
                            </NavLink>
                        </button>
                        : null
                    }
                    <button
                        className='btn btn-profile-list'
                        onClick={() => firebaseClient.logOut()}
                    >
                        {t('logout')}
                    </button>
                </div>
                : null
            }
        </ClickOutside>
    )
}

export default Profile
