import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import googleIcon from '../../resources/icons/google.svg';
// import appleIcon from '../../resources/icons/apple.svg';
import mailIcon from '../../resources/icons/mail-white.svg';
import microsoftIcon from '../../resources/icons/microsoft.svg';
import firebaseClient from '../../utils/FirebaseClient';
import Loader from '../Widget/Loader';
import SignInMail from "./SignInMail";
import { withRouter } from '../../utils/HelperFunctions';
import * as appActions from '../../reducers/app';
import config from '../../config';
import GoogleButton from "./GoogleButton";
import AppleButton from "./AppleButton";

class SignIn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showLoading: false,
            terms: false,
            showTerms: false,
            pairing: false,
            error: null,
            showError: false,
            showPairing: false,
            loaderText: '',
            signInMail: false
        }
        this.handleSetTerms = this.handleSetTerms.bind(this);
        this.setProvider = this.setProvider.bind(this);
        this.setCutomProvider = this.setCutomProvider.bind(this);
        this.handleButtonError = this.handleButtonError.bind(this);
        this.reset = this.reset.bind(this);
        this.startPairing = this.startPairing.bind(this);
        this.handleShowMailForm = this.handleShowMailForm.bind(this);
    }

    componentDidMount() {
        const storageTerms = localStorage.getItem('nfbTerms') === 'true';
        this.setState({ terms: storageTerms });
        this.setState({ showTerms: !storageTerms });
    };

    setProvider(provider) {
        const { t } = this.props;

        if (provider) {
            this.setState({
                showLoading: true,
                showError: false,
                showPairing: false,
                loaderText: ''
            }, () => {
                firebaseClient.logIn(provider).catch(error => {
                    if (error.code === 'auth/account-exists-with-different-credential') {
                        this.setState({
                            showPairing: true,
                            showError: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    } else if (error.code === 'auth/popup-closed-by-user') {
                        this.setState({
                            showPairing: false,
                            showError: false,
                            showLoading: true,
                            error,
                            loaderText: t('login_cancelled')
                        }, () => {
                            setTimeout(() => {
                                this.reset();
                            }, 2000);
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showPairing: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    }
                });
            });
        }
    }

    setCutomProvider(data) {
        const { t } = this.props;

        if (data) {
            this.setState({
                showLoading: true,
                showError: false,
                showPairing: false,
                loaderText: ''
            }, () => {
                firebaseClient.verifyToken(data).catch(error => {
                    if (error.code === 'auth/account-exists-with-different-credential') {
                        this.setState({
                            showPairing: true,
                            showError: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    } else if (error.code === 'auth/popup-closed-by-user') {
                        this.setState({
                            showPairing: false,
                            showError: false,
                            showLoading: true,
                            error,
                            loaderText: t('login_cancelled')
                        }, () => {
                            setTimeout(() => {
                                this.reset();
                            }, 2000);
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showPairing: false,
                            showLoading: false,
                            error,
                            loaderText: ''
                        });
                    }
                });
            });
        }
    }

    startPairing() {
        if (this.state.error) {
            this.setState({
                showLoading: true,
                showError: false,
                showPairing: false,
                loaderText: ''
            }, () => {
                firebaseClient.pairAccounts(this.state.error).catch(error => {
                    this.setState({
                        showError: true,
                        showPairing: false,
                        showLoading: false,
                        error,
                        loaderText: ''
                    });
                });
            });
        } else {
            console.error('Could not find pending credentials');
        }
    }

    handleSetTerms(value) {
        this.setState({ terms: value });
        localStorage.setItem('nfbTerms', value);
    }

    reset() {
        this.setState({
            showError: false,
            signInMail: false,
            showPairing: false,
            showLoading: false,
            error: null,
            loaderText: ''
        });
    }

    handleShowMailForm() {
        this.setState({
            signInMail: true
        });
    }

    showSignUp(val) {
        const { showSignUp, navigate } = this.props;

        if (showSignUp) {
            showSignUp(val);

            if (navigate) {
                if (val) {
                    navigate("/signup");
                } else {
                    navigate("/");
                }
            }
        }
    }

    handleButtonError(err) {
        const { t } = this.props;
        if (err.error === 'account-exists-with-different-credential' || err.code === 'auth/account-exists-with-different-credential') {
            this.setState({
                showPairing: true,
                showError: false,
                showLoading: false,
                error: err,
                loaderText: ''
            });
        } else if (err.error === 'popup_closed_by_user' || err.code === 'auth/popup-closed-by-user') {
            this.setState({
                showPairing: false,
                showError: false,
                showLoading: true,
                error: err,
                loaderText: t('login_cancelled')
            }, () => {
                setTimeout(() => {
                    this.reset();
                }, 2000);
            });
        } else {
            this.setState({
                showError: true,
                showPairing: false,
                showLoading: false,
                error: err,
                loaderText: ''
            });
        }
    }

    render() {
        const { showLoading, terms, showTerms, showError, showPairing, loaderText, signInMail } = this.state;
        const { t, signUp } = this.props;

        return (
            <div className='sign-in-wrapper'>
                {signInMail ?
                    <SignInMail
                        close={this.reset}
                        signUp={signUp}
                        showSignUp={(val) => this.showSignUp(val)}
                    />
                    : showError ?
                        <div className='sign-in-div'>
                            <p className='title'>{t('error')}</p>
                            <p className='text error'>{t('some_error')}</p>
                            <button
                                className='btn btn-sign-in'
                                onClick={this.reset}
                            >
                                <p>{t('cancel')}</p>
                            </button>
                        </div> :
                        showLoading ?
                            <div className='sign-in-div'>
                                <Loader
                                    text={loaderText ? loaderText : t('loading')}
                                    dots={!loaderText}
                                />
                            </div> :
                            showPairing ?
                                <div className='sign-in-div'>
                                    <p className='title'>{t('pairing')}</p>
                                    <p className='text'>
                                        {t('different_provider')}
                                        <br />
                                        {t('like_pair')}
                                    </p>
                                    <button
                                        className='btn btn-sign-in btn-blue'
                                        onClick={this.startPairing}
                                    >
                                        <p>{t('pair')}</p>
                                    </button>
                                    <button
                                        className='btn btn-sign-in'
                                        onClick={this.reset}
                                    >
                                        <p>{t('cancel')}</p>
                                    </button>
                                </div> :
                                <div className='sign-in-div'>
                                    <p className='title'>{signUp ? t('sign_up') : t('sign_in')}</p>
                                    {showTerms ?
                                        <div className='accept-terms'>
                                            <input
                                                type='checkbox'
                                                checked={terms}
                                                onChange={(e) => this.handleSetTerms(e.target.checked)}
                                            />
                                            <p>
                                                {t('agree_1')}
                                                <a
                                                    href={`https://${config.homeSiteDomain}/subscription-terms-of-service`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('terms_of_service')}
                                                </a>
                                                .
                                            </p>
                                        </div>
                                        : null
                                    }
                                    {showTerms && signUp ?
                                        <p className='sign-text'>{t('sign_in_text')}</p>
                                        : null
                                    }
                                    {!signUp ?
                                        <p className='sign-text text-center'>
                                            {t('sign_in_no_user_1')}
                                            <span
                                                className='span-link'
                                                onClick={() => this.showSignUp(true)}
                                            >{t('here')}</span>
                                            {t('sign_in_no_user_2')}
                                        </p>
                                        :
                                        <p className='sign-text text-center'>
                                            {t('sign_up_lready_user_1')}
                                            <span
                                                className='span-link'
                                                onClick={() => this.showSignUp(false)}
                                            >{t('here')}</span>
                                            {t('sign_up_lready_user_2')}
                                        </p>
                                    }
                                    <GoogleButton
                                        text={signUp ? t('google_sign_up') : t('google_sign')}
                                        disabled={showLoading || !terms}
                                        verifyToken={this.setCutomProvider}
                                        handleError={this.handleButtonError}
                                    />
                                    {/* <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={() => this.setProvider('google')}>
                                        <img src={googleIcon} alt='google' loading='lazy' />
                                        <p>{signUp ? t('google_sign_up') : t('google_sign')}</p>
                                    </button> */}
                                    <AppleButton
                                        text={signUp ? t('apple_sign_up') : t('apple_sign')}
                                        disabled={showLoading || !terms}
                                        verifyToken={this.setCutomProvider}
                                        handleError={this.handleButtonError}
                                    />
                                    {/* <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={() => this.setProvider('apple')}>
                                        <img src={appleIcon} alt='apple' loading='lazy' />
                                        <p>{signUp ? t('apple_sign_up') : t('apple_sign')}</p>
                                    </button> */}
                                    <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={() => this.setProvider('microsoft')}>
                                        <img src={microsoftIcon} alt='microsoft' loading='lazy' />
                                        <p>{signUp ? t('microsoft_sign_up') : t('microsoft_sign')}</p>
                                    </button>
                                    <button className='btn btn-sign-in' disabled={showLoading || !terms}
                                        onClick={this.handleShowMailForm}>
                                        <img src={mailIcon} alt='mail' loading='lazy' />
                                        <p>{signUp ? t('mail_sign_up') : t('mail_sign')}</p>
                                    </button>
                                </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        active: state.app.active,
        signUp: state.app.signUp
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignUp: (val) => {
            dispatch(appActions.showSignIn({ signIn: true, signUp: val }));
        }
    };
};

const SignInContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignIn);

export default withTranslation()(withRouter(SignInContainer));