import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import Mailto from '../Widget/Mailto';
import ClickOutside from '../Widget/ClickOutside';
import * as appActions from '../../reducers/app';
import firebaseClient from '../../utils/FirebaseClient';
import { getUserAvatar } from '../../utils/HelperFunctions';
import menuIcon from '../../resources/icons/menu.svg';
import closeIcon from '../../resources/icons/x.svg';
import mailIcon from '../../resources/icons/mail.svg';
import facebookIcon from '../../resources/icons/facebook.svg';
import instagramIcon from '../../resources/icons/instagram.svg';
import twitterIcon from '../../resources/icons/twitter.svg';
import config from '../../config';

function handleShowMenu(value, callback, menu) {
    if (value !== menu) {
        callback(appActions.showMenu({ menu: value }));
    }
}

export default function NavMenu() {
    const { t } = useTranslation();
    const menu = useSelector(state => state.app.menu);
    const signIn = useSelector(state => state.app.signIn);
    const auth = useSelector(state => state.firebase.auth);
    const user = useSelector(state => state.firebase.user);
    const dispatch = useDispatch();

    return (
        <div className={classNames('menu-wrapper', { "signIn": signIn })}>
            <img src={menuIcon} alt='menu' onClick={() => handleShowMenu(true, dispatch, menu)} />
            <ClickOutside
                className={classNames('menu', { 'open-menu': menu })}
                clickOutside={() => handleShowMenu(false, dispatch, menu)}
            >
                <div className='close-icon-wrapper'>
                    <img src={closeIcon} alt='close-icon' loading='lazy' onClick={() => handleShowMenu(false, dispatch, menu)} />
                </div>
                {auth && user ?
                    <div className="menu-link">
                        <img src={getUserAvatar(user)} alt='profile' loading='lazy' />
                        <p>{user.username}</p>
                    </div>
                    : null
                }
                <a
                    href={`https://${config.homeSiteDomain}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="menu-link"
                    onClick={() => handleShowMenu(false, dispatch, menu)}
                >
                    {t('home')}
                </a>
                {!user ?
                    <React.Fragment>
                        <a
                            href={`https://${config.homeSiteDomain}/pricing`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="menu-link"
                            onClick={() => handleShowMenu(false, dispatch, menu)}
                        >
                            {t('pricing')}
                        </a>
                        <a
                            href={`https://${config.homeSiteDomain}/about`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="menu-link"
                            onClick={() => handleShowMenu(false, dispatch, menu)}
                        >
                            {t('about')}
                        </a>
                        <a
                            href={`https://${config.homeSiteDomain}/partners`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="menu-link"
                            onClick={() => handleShowMenu(false, dispatch, menu)}
                        >
                            {t('partners')}
                        </a>
                    </React.Fragment>
                    : null
                }
                {user && user.eventAdmin ?
                    <NavLink
                        to="/my-events?t=upcoming"
                        className="menu-link"
                        onClick={() => handleShowMenu(false, dispatch, menu)}
                    >
                        {t('my_events')}
                    </NavLink>
                    : null
                }
                {user && user.eventAdmin ?
                    <NavLink
                        to="/my-profile"
                        className="menu-link"
                        onClick={() => handleShowMenu(false, dispatch, menu)}
                    >
                        {t('my_profile')}
                    </NavLink>
                    : null
                }
                {user && user.eventAdmin && user.subscription ?
                    <NavLink
                        to="/my-subscription"
                        className="menu-link"
                        onClick={() => handleShowMenu(false, dispatch, menu)}
                    >
                        {t('my_subscription')}
                    </NavLink>
                    : null
                }
                {user && user.eventAdmin && user.subscription ?
                    <NavLink
                        to="/my-hosts"
                        className="menu-link"
                        onClick={() => handleShowMenu(false, dispatch, menu)}
                    >
                        {t('my_hosts')}
                    </NavLink>
                    : null
                }
                {!auth && !user ?
                    <NavLink
                        to="/signup"
                        className="menu-link"
                        onClick={() => handleShowMenu(false, dispatch, menu)}
                    >
                        {t('sign_up')}
                    </NavLink>
                    : null}
                {!auth && !user ?
                    <div
                        className={classNames("menu-link underline", { "active": signIn })}
                        onClick={() => dispatch(appActions.showSignIn({ signIn: true, signUp: false }))}
                    >
                        {t('sign_in')}
                    </div>
                    : null}
                <a
                    href={`https://${config.homeSiteDomain}/help`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="menu-link"
                >
                    {t('help')}
                </a>
                {auth && user ?
                    <div
                        className="menu-link"
                        onClick={() => {
                            firebaseClient.logOut();
                            handleShowMenu(false, dispatch, menu);
                        }}
                    >
                        {t('logout')}
                    </div>
                    : null
                }
            </ClickOutside>
        </div>
    )
}