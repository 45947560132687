import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from 'lodash';
import * as appActions from "../../reducers/app";
import { withRouter } from "../../utils/HelperFunctions";
import Loader from "../Widget/Loader";
import Modal from "./Modal";
import firebaseClient from '../../utils/FirebaseClient';
import closeIcon from '../../resources/icons/x.svg';
import config from '../../config';

class MyHosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            successText: "",
            errorText: "",
            editItemId: "",
            deleteItemId: "",
            editData: {
                title: "",
                username: "",
                companyName: "",
                mail: "",
                phoneNumber: "",
            },
            formData: {
                title: "",
                username: "",
                companyName: "",
                mail: "",
                phoneNumber: "",
            },
            openModal: false,
            processing: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEditInputChange = this.handleEditInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleAddHost = this.handleAddHost.bind(this);
        this.handleDeleteHost = this.handleDeleteHost.bind(this);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    setUserHosts() {
        const { user } = this.props;

        if (user && user.subscription && user.subscription.hosts) {
            let listHosts = [];

            user.subscription.hosts.map((item, index) => {
                listHosts.push({ ...item, id: index + 1 });

                return index;
            });

            this.setState({
                data: listHosts
            });
        }
    }

    parseDataArray(data) {
        if (data && data.length) {
            let list = [];

            data.map((item, index) => {
                list.push({ ...item, id: index + 1 });

                return index;
            });


            return list;
        } else {
            return [];
        }
    }

    componentDidMount() {
        const { user, userLoaded, showSignIn, navigate } = this.props;

        if (!user && userLoaded && showSignIn) {
            showSignIn(true);
        } else if (user) {
            if (user && user.eventAdmin) {
                this.setUserHosts();
            } else if (user && !user.eventAdmin) {
                navigate("/");
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { user, userLoaded, showSignIn, navigate } = this.props;

        if (!user && userLoaded && prevProps.userLoaded !== userLoaded && showSignIn) {
            showSignIn(true);
        }

        if (user && user !== prevProps.user) {
            if (user.eventAdmin) {
                this.setUserHosts();
            } else if (!user.eventAdmin) {
                navigate("/");
            }
        }
    }

    validatePhoneNumber(number) {
        const re = /^\+[1]\d{10}$/;
        return re.test(String(number).toLowerCase());
    }

    validateMail(mail) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(mail).toLowerCase());
    }

    handleInputChange(e) {
        e.preventDefault();
        const { formData } = this.state;

        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;

        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        this.setState({
            formData: { ...newFormData },
            successText: "",
            errorText: ""
        });
    }

    handleEditInputChange(e) {
        e.preventDefault();
        const { editData } = this.state;

        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;

        const newEditData = { ...editData };
        newEditData[fieldName] = fieldValue;
        this.setState({
            editData: { ...newEditData },
            successText: "",
            errorText: ""
        });
    }

    handleEditChange(e, host, index) {
        e.preventDefault();

        this.setState({
            editItemId: index,
            editData: {
                title: host.title || "",
                username: host.username || "",
                companyName: host.companyName || "",
                mail: host.mail || "",
                phoneNumber: host.phoneNumber || "",
            },
            successText: "",
            errorText: ""
        });
    }

    handleDeleteHost(e) {
        e.preventDefault();
        const { data, deleteItemId } = this.state;
        const { user, t } = this.props;

        const hostsList = [...data];
        const index = data.findIndex(
            (item) => item.id === deleteItemId
        );

        let hostEmail = hostsList[index] ? hostsList[index].mail : null;

        hostsList.splice(index, 1);

        if (user && user.subscription && user.subscription.id && hostEmail) {
            this.setState({
                processing: true,
                successText: "",
                errorText: ""
            }, () => {
                firebaseClient.deleteHost(user.subscription.id, hostEmail).then((res) => {
                    this.setState({
                        data: this.parseDataArray(hostsList),
                        deleteItemId: "",
                        openModal: false,
                        processing: false,
                        successText: t("success_deleted_host")
                    }, () => {
                        if (this.state.successText) {
                            this.handleScrollToId("host-success");
                        }
                        firebaseClient.getUserData(user, true);
                    });
                }).catch((e) => {
                    let message = t("error_deleting_host");
                    if (e && e.code && e.code === 'functions/aborted' && e.message) {
                        message = `${t('host_1')} ${e.message} ${t('host_2')}`;
                    }
                    this.setState({
                        errorText: message,
                        deleteItemId: "",
                        openModal: false,
                        processing: false,
                    }, () => {
                        if (this.state.errorText) {
                            this.handleScrollToId("host-error");
                        }
                    });
                })
            });
        }
    }

    handleSave(e) {
        e.preventDefault();
        const { data, editItemId } = this.state;
        const { username, phoneNumber, title, companyName, mail } = this.state.editData;

        let message = "";

        const { t, user } = this.props;

        if (!username) {
            message = t("valid_24");
        } else if (username && username.length < 2) {
            message = t("valid_25");
        } else if (!title) {
            message = t("valid_14");
        } else if (title && title.length < 2) {
            message = t("valid_15");
        } else if (!companyName) {
            message = t("valid_16");
        } else if (companyName && companyName.length < 2) {
            message = t("valid_23");
        } else if (!mail) {
            message = t("valid_1");
        } else if (mail && !this.validateMail(mail)) {
            message = t("valid_2");
        } else if (phoneNumber && !this.validatePhoneNumber(phoneNumber)) {
            message = t("valid_20");
        } else {
            const editHost = {
                id: editItemId,
                username: username,
                title: title,
                companyName: companyName,
                mail: mail,
                phoneNumber: phoneNumber,
            };

            const hostsList = { ...data };
            const index = data.findIndex(
                (item) => item.id === editItemId
            );
            hostsList[index] = editHost;

            const result = Object.entries(hostsList).map(([key, value]) => {
                return value;
            });

            let parsedHosts = result.map((item) => {
                delete item.id;
                return item;
            });

            if (user && user.subscription && user.subscription.id && parsedHosts && parsedHosts.length) {
                this.setState({
                    processing: true,
                    successText: "",
                    errorText: ""
                }, () => {
                    firebaseClient.updateUserSubscription(user.subscription.id, { hosts: parsedHosts }).then((res) => {
                        this.setState({
                            data: this.parseDataArray(result),
                            editItemId: "",
                            editData: {
                                title: "",
                                username: "",
                                companyName: "",
                                mail: "",
                                phoneNumber: "",
                            },
                            processing: false,
                            successText: t("success_edited_host")
                        }, () => {
                            if (this.state.successText) {
                                this.handleScrollToId("host-success");
                            }
                            firebaseClient.getUserData(user, true);
                        });
                    }).catch((e) => {
                        this.setState({
                            errorText: t("error_editing_host")
                        }, () => {
                            if (this.state.errorText) {
                                this.handleScrollToId("host-error");
                            }
                        });
                    })
                });
            }
        }

        this.setState({
            errorText: message,
        }, () => {
            if (this.state.errorText) {
                this.handleScrollToId("host-error");
            }
        });
    }

    handleCheckDisableSaveButton() {
        const { username, title, companyName, mail } = this.state.editData;

        let retVal = false;

        if (!username) {
            retVal = true;
        } else if (!title) {
            retVal = true;
        } else if (!companyName) {
            retVal = true;
        } else if (!mail) {
            retVal = true;
        }

        return retVal;
    }

    handleAddHost(e) {
        e.preventDefault();
        const { data } = this.state;
        const { username, phoneNumber, title, companyName, mail } = this.state.formData;

        let message = "";

        const { t, user } = this.props;

        if (!username) {
            message = t("valid_24");
        } else if (username && username.length < 2) {
            message = t("valid_25");
        } else if (!title) {
            message = t("valid_14");
        } else if (title && title.length < 2) {
            message = t("valid_15");
        } else if (!companyName) {
            message = t("valid_16");
        } else if (companyName && companyName.length < 2) {
            message = t("valid_23");
        } else if (!mail) {
            message = t("valid_1");
        } else if (mail && !this.validateMail(mail)) {
            message = t("valid_2");
        } else if (phoneNumber && !this.validatePhoneNumber(phoneNumber)) {
            message = t("valid_20");
        } else {
            const newHost = {
                id: data.length + 1,
                username: username,
                title: title,
                companyName: companyName,
                mail: mail,
                phoneNumber: phoneNumber,
            };

            let hosts = [...data, newHost];

            let parsedHosts = hosts.map((item) => {
                delete item.id;
                return item;
            });

            if (user && user.subscription && user.subscription.id) {
                this.setState({
                    processing: true,
                    successText: "",
                    errorText: ""
                }, () => {
                    firebaseClient.updateUserSubscription(user.subscription.id, { hosts: parsedHosts }).then((res) => {
                        this.setState({
                            data: this.parseDataArray([...data, newHost]),
                            formData: {
                                title: "",
                                username: "",
                                companyName: "",
                                mail: "",
                                phoneNumber: "",
                            },
                            processing: false,
                            successText: t("success_added_host")
                        }, () => {
                            if (this.state.successText) {
                                this.handleScrollToId("host-success");
                            }
                            firebaseClient.getUserData(user, true);
                        });
                    }).catch((e) => {
                        this.setState({
                            errorText: t("error_adding_host")
                        }, () => {
                            if (this.state.errorText) {
                                this.handleScrollToId("host-error");
                            }
                        });
                    })
                });
            }

            Array.from(document.querySelectorAll("input")).forEach(
                (input) => (input.value = "")
            );
        }

        this.setState({
            errorText: message,
        }, () => {
            if (this.state.errorText) {
                this.handleScrollToId("host-error");
            }
        });
    }

    handleCheckDisableHostButton() {
        const { username, title, companyName, mail } = this.state.formData;

        let retVal = false;

        if (!username) {
            retVal = true;
        } else if (!title) {
            retVal = true;
        } else if (!companyName) {
            retVal = true;
        } else if (!mail) {
            retVal = true;
        }

        return retVal;
    }

    handleCancel(e) {
        e.preventDefault();

        this.setState({
            editItemId: "",
            editData: {
                title: "",
                username: "",
                companyName: "",
                mail: "",
                phoneNumber: "",
            },
            successText: "",
            errorText: ""
        });
    }
    showModal(e, host) {
        e.preventDefault();
        this.setState({
            openModal: true,
            deleteItemId: host.id,
            successText: "",
            errorText: ""
        });
    };

    hideModal(e) {
        e.preventDefault();
        this.setState({
            openModal: false,
            deleteItemId: "",
            successText: "",
            errorText: ""
        });
    };

    handleScrollToId(id) {
        if (id) {
            let el = document.getElementById(id);

            if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            }
        }
    }

    compareTwoJson(newJson, oldJSON) {
        let retVal = false;

        if (newJson && newJson.id && oldJSON) {
            oldJSON.id = newJson.id;
        }

        if (newJson && oldJSON && _.isEqual(newJson, oldJSON)) {
            retVal = true;
        }

        return retVal;
    }

    render() {
        const { editItemId, data, errorText, successText, openModal, processing, formData, editData } = this.state;
        const { t, userLoaded, user, overlay, closeOverlay } = this.props;

        return (
            <div>
                {userLoaded ?
                    <div className="about-wrapper">
                        {overlay ?
                            <img
                                src={closeIcon}
                                alt='x'
                                loading='lazy'
                                className='close'
                                onClick={() => {
                                    if (closeOverlay) {
                                        closeOverlay();
                                    }
                                }}
                            />
                            : null}
                        <p className="about-title">
                            {t("my_hosts")}
                            {user && user.subscription && user.subscription.type ?
                                <a
                                    href={
                                        user.subscription.type === 'pro' ?
                                            `https://${config.homeSiteDomain}/pro-your-hosts`
                                            :
                                            user.subscription.type === 'enterprise' ?
                                                `https://${config.homeSiteDomain}/enterprise-your-hosts`
                                                :
                                                `https://${config.homeSiteDomain}/explorer-your-hosts`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='help-link'
                                >
                                    ?
                                    </a>
                                : null}
                        </p>
                        {user && user.subscription ? <p className="host-notify-text">{`${t('nominated_1')} ${data ? data.length : '0'} ${data ? data.length && data.length > 1 ? t('nominated_3') : t('nominated_2') : t('nominated_2')} ${user.subscription.number_of_hosts} ${user.subscription.number_of_hosts ? user.subscription.number_of_hosts > 1 ? t('nominated_5') : t('nominated_4') : t('nominated_4')}.`}</p> : null}
                        <div className="pricing-table editable-table">
                            <form>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                {t("name")}
                                                <span className='asterisk'>*</span>
                                            </th>
                                            <th>
                                                {t("title")}
                                                <span className='asterisk'>*</span>
                                            </th>
                                            <th>
                                                {t("companyName")}
                                                <span className='asterisk'>*</span>
                                            </th>
                                            <th>
                                                {t("email_p")}
                                                <span className='asterisk'>*</span>
                                            </th>
                                            <th>
                                                {t("phoneNumber")}
                                                <br />
                                                {t("phoneNumber_text2")}
                                            </th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.length ?
                                            data.map((host, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className={editItemId && editItemId === index + 1 ? "edit-row" : ""}
                                                    >
                                                        <td data-content={`${t("name")}:`}>
                                                            {editItemId && editItemId === index + 1 ?
                                                                <div className='table-line'>
                                                                    <p className='table-p-wrapper'>
                                                                        {t("name")}
                                                                        <span className='asterisk'>*</span>
                                                                    </p>
                                                                    <input
                                                                        className="table-input-wrapper"
                                                                        type="text"
                                                                        name="username"
                                                                        placeholder={t("name")}
                                                                        value={editData.username}
                                                                        onChange={this.handleEditInputChange}
                                                                        disabled={processing}
                                                                    />
                                                                </div>
                                                                :
                                                                <p className="table-p">{host.username}</p>
                                                            }
                                                        </td>
                                                        <td data-content={`${t("title")}:`}>
                                                            {editItemId && editItemId === index + 1 ?
                                                                <div className='table-line'>
                                                                    <p className='table-p-wrapper'>
                                                                        {t("title")}
                                                                        <span className='asterisk'>*</span>
                                                                    </p>
                                                                    <input
                                                                        className="table-input-wrapper"
                                                                        type="text"
                                                                        name="title"
                                                                        placeholder={t("title")}
                                                                        value={editData.title}
                                                                        onChange={this.handleEditInputChange}
                                                                        disabled={processing}
                                                                    />
                                                                </div>
                                                                :
                                                                <p className="table-p">{host.title}</p>
                                                            }
                                                        </td>
                                                        <td data-content={`${t("companyName")}:`}>
                                                            {editItemId && editItemId === index + 1 ?
                                                                <div className='table-line'>
                                                                    <p className='table-p-wrapper'>
                                                                        {t("companyName")}
                                                                        <span className='asterisk'>*</span>
                                                                    </p>
                                                                    <input
                                                                        className="table-input-wrapper"
                                                                        type="text"
                                                                        name="companyName"
                                                                        placeholder={t("companyName")}
                                                                        value={editData.companyName}
                                                                        onChange={this.handleEditInputChange}
                                                                        disabled={processing}
                                                                    />
                                                                </div>
                                                                :
                                                                <p className="table-p">{host.companyName}</p>
                                                            }
                                                        </td>
                                                        <td data-content={`${t("email_p")}:`}>
                                                            {editItemId && editItemId === index + 1 ?
                                                                <div className='table-line'>
                                                                    <p className='table-p-wrapper'>
                                                                        {t("email_p")}
                                                                        <span className='asterisk'>*</span>
                                                                    </p>
                                                                    <input
                                                                        className="table-input-wrapper"
                                                                        type="text"
                                                                        name="mail"
                                                                        placeholder={t("email_p")}
                                                                        value={editData.mail}
                                                                        disabled
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                :
                                                                <p className="table-p">{host.mail}</p>
                                                            }
                                                        </td>
                                                        <td data-content={`${t("phoneNumber")}:`}>
                                                            {editItemId && editItemId === index + 1 ?
                                                                <div className='table-line'>
                                                                    <p className='table-p-wrapper'>{t("phoneNumber_text")}</p>
                                                                    <input
                                                                        className="table-input-wrapper"
                                                                        type="text"
                                                                        name="phoneNumber"
                                                                        placeholder={t("phoneNumber")}
                                                                        value={editData.phoneNumber}
                                                                        onChange={this.handleEditInputChange}
                                                                        disabled={processing}
                                                                    />
                                                                </div>
                                                                :
                                                                <p className="table-p">{host.phoneNumber}</p>
                                                            }
                                                        </td>
                                                        {editItemId !== index + 1 ?
                                                            <td className="action-data">
                                                                <button
                                                                    className="table-btn"
                                                                    onClick={(e) => this.handleEditChange(e, host, index + 1)}
                                                                    disabled={processing}
                                                                >
                                                                    {t("edit")}
                                                                </button>
                                                                <button
                                                                    className="table-btn"
                                                                    onClick={(e) => this.showModal(e, host)}
                                                                    disabled={processing}
                                                                >
                                                                    {t("delete")}
                                                                </button>
                                                            </td>
                                                            :
                                                            <td className="action-data">
                                                                {this.compareTwoJson(host, editData) ?
                                                                    <button
                                                                        className="table-btn"
                                                                        onClick={(e) => this.handleEditChange(e, host, index + 1)}
                                                                        disabled={processing}
                                                                    >
                                                                        {t("edit")}
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        className="table-btn"
                                                                        onClick={(e) => this.handleSave(e)}
                                                                        disabled={processing || this.handleCheckDisableSaveButton()}
                                                                    >
                                                                        {t("save")}
                                                                    </button>}
                                                                <button
                                                                    className="table-btn"
                                                                    onClick={this.handleCancel}
                                                                    disabled={processing}
                                                                >
                                                                    {t("cancel")}
                                                                </button>
                                                            </td>
                                                        }
                                                    </tr>
                                                );
                                            })
                                            : null}
                                        <tr>
                                            <td>
                                                <div className='table-line'>
                                                    <p className='table-p-wrapper'>
                                                        {t("name")}
                                                        <span className='asterisk'>*</span>
                                                    </p>
                                                    <input
                                                        className="table-input-wrapper"
                                                        type="text"
                                                        name="username"
                                                        placeholder={`${t("name")} *`}
                                                        value={formData.username}
                                                        onChange={this.handleInputChange}
                                                        disabled={processing || ((!user) || (user && !user.subscription) || (user && user.subscription && !user.subscription.number_of_hosts) || (user && user.subscription && user.subscription.number_of_hosts && user.subscription.number_of_hosts <= data.length))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-line'>
                                                    <p className='table-p-wrapper'>
                                                        {t("title")}
                                                        <span className='asterisk'>*</span>
                                                    </p>
                                                    <input
                                                        className="table-input-wrapper"
                                                        type="text"
                                                        name="title"
                                                        placeholder={`${t("title")} *`}
                                                        value={formData.title}
                                                        onChange={this.handleInputChange}
                                                        disabled={processing || ((!user) || (user && !user.subscription) || (user && user.subscription && !user.subscription.number_of_hosts) || (user && user.subscription && user.subscription.number_of_hosts && user.subscription.number_of_hosts <= data.length))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-line'>
                                                    <p className='table-p-wrapper'>
                                                        {t("companyName")}
                                                        <span className='asterisk'>*</span>
                                                    </p>
                                                    <input
                                                        className="table-input-wrapper"
                                                        type="text"
                                                        name="companyName"
                                                        placeholder={`${t("companyName")} *`}
                                                        value={formData.companyName}
                                                        onChange={this.handleInputChange}
                                                        disabled={processing || ((!user) || (user && !user.subscription) || (user && user.subscription && !user.subscription.number_of_hosts) || (user && user.subscription && user.subscription.number_of_hosts && user.subscription.number_of_hosts <= data.length))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-line'>
                                                    <p className='table-p-wrapper'>
                                                        {t("email_p")}
                                                        <span className='asterisk'>*</span>
                                                    </p>
                                                    <input
                                                        className="table-input-wrapper"
                                                        type="text"
                                                        name="mail"
                                                        placeholder={`${t("email_p")} *`}
                                                        value={formData.mail}
                                                        onChange={this.handleInputChange}
                                                        disabled={processing || ((!user) || (user && !user.subscription) || (user && user.subscription && !user.subscription.number_of_hosts) || (user && user.subscription && user.subscription.number_of_hosts && user.subscription.number_of_hosts <= data.length))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='table-line'>
                                                    <p className='table-p-wrapper'>{t("phoneNumber_text")}</p>
                                                    <input
                                                        className="table-input-wrapper"
                                                        type="text"
                                                        name="phoneNumber"
                                                        placeholder={t("phoneNumber")}
                                                        value={formData.phoneNumber}
                                                        onChange={this.handleInputChange}
                                                        disabled={processing || ((!user) || (user && !user.subscription) || (user && user.subscription && !user.subscription.number_of_hosts) || (user && user.subscription && user.subscription.number_of_hosts && user.subscription.number_of_hosts <= data.length))}
                                                    />
                                                </div>
                                            </td>
                                            <td className="action-data">
                                                <button
                                                    className="table-btn add-host-btn"
                                                    onClick={this.handleAddHost}
                                                    disabled={processing || this.handleCheckDisableHostButton() || ((!user) || (user && !user.subscription) || (user && user.subscription && !user.subscription.number_of_hosts) || (user && user.subscription && user.subscription.number_of_hosts && user.subscription.number_of_hosts <= data.length))}
                                                >
                                                    {t("add_host")}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        {errorText ? <p id="host-error" className="host-error-message">{errorText}</p> : null}
                        {successText ? <p id="host-success" className="host-success-message">{successText}</p> : null}
                    </div>
                    :
                    <Loader
                        text={t("loading_hosts")}
                        dots
                    />
                }
                <Modal show={openModal}>
                    {t("modal_delete_host")}<br />
                    {t("want_continue")}
                    <div className="action-container">
                        <button
                            className="modal-btn"
                            onClick={this.handleDeleteHost}
                            disabled={processing}
                        >
                            {t("yes")}
                        </button>
                        <button
                            className="modal-btn"
                            onClick={this.hideModal}
                            disabled={processing}
                        >
                            {t("no")}
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignIn: (value) => {
            dispatch(appActions.showSignIn({ signIn: value, signUp: false }));
        },
    };
};

const MyHostsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyHosts);

export default withTranslation()(withRouter(MyHostsContainer));