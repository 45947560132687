import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from 'connected-react-router';
import appReducer from './app';
import firebaseReducer from './firebase';

const reducers = (history) => combineReducers({
    router: connectRouter(history),
    app: appReducer,
    firebase: firebaseReducer
});

export default reducers;