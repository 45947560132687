import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import appleIcon from '../../resources/icons/apple.svg';

function AppleButton(props) {

    return (
        <AppleSignin
            authOptions={{
                clientId: '4H4N99YM66.com.nofilterstream',
                scope: 'email name',
                redirectURI: `${window.location.origin}/authenticate`,
                state: 'initial',
                nonce: 'nonce',
                usePopup: true,
            }}
            onSuccess={(response) => {
                // console.log('SUCCESS-loginApple', response);
                if (response && response.authorization && response.authorization.id_token && props.verifyToken) {
                    props.verifyToken({ token: response.authorization.id_token, type: 'apple' });
                }
            }}
            onError={error => props?.handleError(error)}
            render={(res) => (
                <button
                    className='btn btn-sign-in'
                    disabled={props.disabled}
                    onClick={res.onClick}
                >
                    <img src={appleIcon} alt='apple' loading='lazy' />
                    <p>{props.text}</p>
                </button>
            )}
        />
    )
}

export default AppleButton;