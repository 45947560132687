import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import moment from 'moment';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { withRouter, getRemainingSubscriptionTime, getTotalSubscriptionTime } from '../../utils/HelperFunctions';
import Loader from '../Widget/Loader';
import firebaseClient from '../../utils/FirebaseClient';
import * as appActions from '../../reducers/app';
import downIcon from '../../resources/icons/down.svg';
import config from '../../config';

class MySubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptions: null,
            subscriptionsLoaded: false,
            tab: null,
            showArrow: false
        }
    }

    getUserSubscriptions() {
        firebaseClient.getUserSubscriptions().then((res) => {
            if (res && res.data) {
                let subscriptions = res.data

                if (subscriptions && subscriptions.length) {
                    if (subscriptions.length > 1) {
                        let activeSubscription = null;
                        subscriptions.sort((a, b) => b.valid_to._seconds - a.valid_to._seconds);
                        subscriptions.forEach(sub => {
                            if (sub && sub.status && sub.status === 'active') {
                                activeSubscription = sub;
                            }
                        });

                        if (activeSubscription) {
                            subscriptions = [activeSubscription];
                        } else {
                            subscriptions = [subscriptions[0]];
                        }
                    }
                }

                this.setState({
                    subscriptionsLoaded: true,
                    subscriptions: subscriptions,
                    tab: subscriptions.length ? subscriptions[0].id : null,
                    showArrow: subscriptions.length && subscriptions.length > 1
                });
            }
        })
    }

    componentDidMount() {
        const { user, userLoaded, showSignIn, navigate } = this.props;

        if (!user && userLoaded && showSignIn) {
            showSignIn(true)
        } else if (user) {
            if (user && user.eventAdmin) {
                this.getUserSubscriptions();
            } else if (user && !user.eventAdmin) {
                navigate("/");
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { user, userLoaded, showSignIn, navigate } = this.props;

        if (!user && userLoaded && prevProps.userLoaded !== userLoaded && showSignIn) {
            showSignIn(true)
        }

        if (user && user !== prevProps.user) {
            if (user.eventAdmin) {
                this.getUserSubscriptions();
            } else {
                navigate("/");
            }
        }
    }

    setTab(id) {
        const { tab } = this.state;

        this.setState({
            tab: id && tab && id === tab ? null : id
        });
    }

    render() {
        const { subscriptions, subscriptionsLoaded, tab, showArrow } = this.state;
        const { t, user } = this.props;

        return (
            <div>
                {subscriptionsLoaded ?
                    <div className='event-scheduler'>
                        <div className='event-scheduler-items'>
                            <p className='event-scheduler-title'>
                                {t('my_subscription')}
                                {user && user.subscription && user.subscription.type ?
                                    <a
                                        href={
                                            user.subscription.type === 'pro' ?
                                                `https://${config.homeSiteDomain}/pro-subscription-and-balances`
                                                :
                                                user.subscription.type === 'enterprise' ?
                                                    `https://${config.homeSiteDomain}/enterprise-subscription-and-balances`
                                                    :
                                                    `https://${config.homeSiteDomain}/explorer-subscription-and-balances`
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className='help-link'
                                    >
                                        ?
                                    </a>
                                    : null}
                            </p>
                            {!user || (user && !user.eventAdmin) ?
                                <div className='scheduler-item'>
                                    <div className='item-header'>
                                        <p className='text-notify'>{t('do_not_access')}</p>
                                    </div>
                                </div>
                                : subscriptions && subscriptions.length ?
                                    <div>
                                        {subscriptions.map((item) => {
                                            return (
                                                <div className='scheduler-item' key={item.id}>
                                                    <div className={classNames('item-header', { 'show': showArrow })}>
                                                        <p>{item.subscription_name} - {item.status}</p>
                                                        <img
                                                            src={downIcon}
                                                            loading='lazy'
                                                            alt='arrow'
                                                            className={classNames({ 'rotate-icon': tab === item.id })}
                                                            onClick={() => this.setTab(item.id)}
                                                        />
                                                    </div>
                                                    <div className={classNames('item-body', { 'show': tab === item.id })}>
                                                        <div className='section-flex'>
                                                            <div className='section-left'>
                                                                <p className='sub-item'>
                                                                    {t('subscription')}:
                                                                    <span className='normal-case'>{` ${item.subscription_name}`}</span>
                                                                    {item.type ?
                                                                        item.type === 'explorer' ?
                                                                            <a
                                                                                href={`https://${config.homeSiteDomain}/help#ContactUsform`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {t('upgrade_to_pro')}
                                                                            </a>
                                                                            : item.type === 'pro' ?
                                                                                <a
                                                                                    href={`https://${config.homeSiteDomain}/help#ContactUsform`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    {t('upgrade_to_enterprise')}
                                                                                </a>
                                                                                : null
                                                                        : null}
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('status')}:
                                                                    <span>{` ${item.status}`}</span>
                                                                    {(item.status && (item.status === 'overdue' || item.status === 'suspended')) ?
                                                                        <a
                                                                            href={`https://${config.homeSiteDomain}/help#ContactUsform`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            {t('contact_us')}
                                                                        </a>
                                                                        : null}
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('subscription_start_date')}:
                                                                    <span>{` ${item.valid_from && item.valid_from._seconds ? moment(new Date(item.valid_from._seconds * 1000).getTime()).format("L") : ''} `}</span>
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('subscription_renewal_date')}:
                                                                    <span>{` ${item.valid_to && item.valid_to._seconds ? moment(new Date(item.valid_to._seconds * 1000).getTime()).format("L") : ''} `}</span>
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('payment_schedule')}:
                                                                    <span>{` ${item.term} - ${item.next_payment_due_date && item.next_payment_due_date._seconds ? moment(new Date(item.next_payment_due_date._seconds * 1000).getTime()).format("L") : ''}`}</span>
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('audience_size_included')}:
                                                                    <span>{` ${item.audience_size}`}</span>
                                                                </p>
                                                            </div>
                                                            <div className='section-right'>
                                                                <p className='sub-item'>
                                                                    {t('event_analytics_1')}:
                                                                    <span>{` ${item.event_analytics ? t('enabled') : t('disabled')}`}</span>
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('recording')}:
                                                                    <span>{` ${item.event_recording ? t('enabled') : t('disabled')}`}</span>
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('branding')}:
                                                                    <span>{` ${item.event_brending ? t(item.event_brending) : t('disabled')}`}</span>
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('event_hour_balance')}:
                                                                    <span className='normal-case'>{item.type && item.type === 'enterprise' ? ' Unlimited' : ` ${t('hb_1')} ${getRemainingSubscriptionTime(item)} ${t('hb_2')} ${getTotalSubscriptionTime(item)} ${t('hb_3')}`}</span>
                                                                </p>
                                                                <p className='sub-item'>
                                                                    {t('hosts')}:
                                                                    <span className='normal-case'>
                                                                        {` ${t('nominated_1')} ${item.hosts ? item.hosts.length : '0'} ${item.hosts ? item.hosts.length && item.hosts.length > 1 ? t('nominated_3') : t('nominated_2') : t('nominated_2')} ${item.number_of_hosts} ${item.number_of_hosts ? item.number_of_hosts > 1 ? t('nominated_5') : t('nominated_4') : t('nominated_4')}`}
                                                                        <Link to='/my-hosts'>
                                                                            {t('my_hosts')}
                                                                        </Link>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='buttons'>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div className='scheduler-item'>
                                        <div className='item-header'>
                                            <p className='text-notify'>{t('no_subscriptions')}</p>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    <Loader
                        text={t('loading_subscriptions')}
                        dots
                    />
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignIn: (value) => {
            dispatch(appActions.showSignIn({ signIn: value, signUp: false }));
        }
    };
};

const MySubscriptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MySubscription);

export default withTranslation()(withRouter(MySubscriptionContainer));