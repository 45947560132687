import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from '../../utils/HelperFunctions';

function NotFound(props) {
    const { t } = useTranslation();

    return (
        <p className='app-title'>
            {t('not_found')}
        </p>
    )
}

export default withRouter(NotFound);