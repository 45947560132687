import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import Loader from '../Widget/Loader';
import firebaseClient from '../../utils/FirebaseClient';
import eyeIcon from '../../resources/icons/eye.svg';
import eyeDisableIcon from '../../resources/icons/disable_eye.svg';
import { validatePassword } from '../../utils/HelperFunctions';

class SignInMail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form: 'signIn',
            showLoading: false,
            error: null,
            showError: false,
            showMessage: false,
            loaderText: '',
            errorText: '',
            notificationText: '',
            email: '',
            password: '',
            confirmPassword: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        }

        this.reset = this.reset.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.signInWithEmailAndPassword = this.signInWithEmailAndPassword.bind(this);
        this.createUserWithEmailAndPassword = this.createUserWithEmailAndPassword.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleSignKeyDown = this.handleSignKeyDown.bind(this);
        this.handleCreateKeyDown = this.handleCreateKeyDown.bind(this);
        this.changeInputType = this.changeInputType.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount() {
        const { signUp } = this.props;

        if (signUp) {
            this.switchToForm('create');
        }

        const userEmail = localStorage.getItem('userEmail');

        this.setState({
            email: userEmail ? userEmail : ''
        });
    };

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    signInWithEmailAndPassword() {
        const { email, password } = this.state;
        const { t } = this.props;

        if (!email || (email && !this.validateEmail(email)) || !password || (password && !validatePassword(password))) {
            this.checkForm('signIn');
        } else {
            this.setState({
                showLoading: true,
                showError: false,
                showMessage: false,
                loaderText: '',
                errorText: ''
            }, () => {
                firebaseClient.signInWithEmailAndPassword(email, password).catch(error => {
                    if (error.code && error.code === 'auth/user-not-found') {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            showLoading: false,
                            error,
                            loaderText: '',
                            errorText: t('no_user_email')
                        });
                    } else if (error.code && error.code === 'auth/wrong-password') {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            showLoading: false,
                            error,
                            loaderText: '',
                            errorText: t('password_invalid')
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            showLoading: false,
                            error,
                            loaderText: '',
                            errorText: ''
                        });
                    }
                });
            });
        }
    }

    createUserWithEmailAndPassword() {
        const { email, password, confirmPassword } = this.state;
        const { t } = this.props;

        if (!email || (email && !this.validateEmail(email)) || !password || (password && !validatePassword(password)) || !confirmPassword || (confirmPassword && !validatePassword(confirmPassword)) || password !== confirmPassword) {
            this.checkForm();
        } else {
            this.setState({
                showLoading: true,
                showError: false,
                showMessage: false,
                loaderText: '',
                errorText: ''
            }, () => {
                firebaseClient.createUserWithEmailAndPassword(email, password).catch(error => {
                    if (error.code && error.code === 'auth/email-already-in-use') {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            showLoading: false,
                            error,
                            loaderText: '',
                            errorText: t('error_another_account')
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            showLoading: false,
                            error,
                            loaderText: '',
                            errorText: ''
                        });
                    }
                });
            });
        }
    }

    reset() {
        this.setState({
            form: 'signIn',
            showLoading: false,
            error: null,
            showError: false,
            showMessage: false,
            loaderText: '',
            errorText: '',
            notificationText: '',
            password: '',
            confirmPassword: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        });
    }

    handleChangeEmail(e) {
        const value = e.target.value;
        this.setState({
            email: value,
            notificationText: ''
        }, () => {
            localStorage.setItem('userEmail', value.trim());
        });
    }

    handleSignKeyDown(event) {
        if (event.key === 'Enter') {
            this.signInWithEmailAndPassword();
        }
    }

    handleCreateKeyDown(event) {
        if (event.key === 'Enter') {
            this.createUserWithEmailAndPassword();
        }
    }

    changeInputType(value) {
        const { inputTypeFirst, inputTypeSecond } = this.state;

        switch (value) {
            case 1:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
            case 2:
                if (inputTypeSecond && inputTypeSecond === 'password') {
                    this.setState({
                        inputTypeSecond: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeSecond: 'password'
                    });
                }
                break;
            default:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
        }
    }

    resetPassword() {
        const { email } = this.state;

        if (email) {
            this.setState({
                showLoading: true,
                showError: false,
                showMessage: false,
                loaderText: '',
                errorText: ''
            }, () => {
                firebaseClient.sendPasswordResetMail(email).then(() => {
                    this.setState({
                        showError: false,
                        showMessage: true,
                        showLoading: false,
                        loaderText: '',
                        errorText: ''
                    });
                }).catch(error => {
                    this.setState({
                        showError: true,
                        showMessage: false,
                        showLoading: false,
                        error,
                        loaderText: '',
                        errorText: ''
                    });
                });
            });
        }
    }

    closeForm() {
        const { close } = this.props;

        if (close) {
            this.reset();
            close();
        }
    }

    switchToForm(form) {
        this.setState({
            form: form,
            showLoading: false,
            error: null,
            showError: false,
            showMessage: false,
            loaderText: '',
            errorText: '',
            notificationText: '',
            password: '',
            confirmPassword: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        })
    }

    checkForm(type) {
        const { email, password, confirmPassword } = this.state;
        const { t } = this.props;

        if (type && type === 'signIn') {
            let message = '';

            if (!email) {
                message = t('valid_1');
            } else if (email && !this.validateEmail(email)) {
                message = t('valid_2');
            } else if (!password) {
                message = t('valid_3');
            } else if (password && !validatePassword(password)) {
                message = t('valid_4');
            }

            this.setState({
                notificationText: message
            });
        } else {
            let message = '';

            if (!email) {
                message = t('valid_1');
            } else if (email && !this.validateEmail(email)) {
                message = t('valid_2');
            } else if (!password) {
                message = t('valid_3');
            } else if (password && !validatePassword(password)) {
                message = t('valid_4');
            } else if (!confirmPassword) {
                message = t('valid_5');
            } else if (confirmPassword && !validatePassword(confirmPassword)) {
                message = t('valid_6');
            } else if (password !== confirmPassword) {
                message = t('valid_7');
            }

            this.setState({
                notificationText: message
            });
        }
    }

    render() {
        const { showLoading, showError, showMessage, loaderText, errorText, email, password, confirmPassword,
            error, inputTypeFirst, inputTypeSecond, form, notificationText } = this.state;
        const { t, showSignUp } = this.props;

        return (
            <div>
                {showError ?
                    <div className='sign-in-div'>
                        <p className='title'>{t('error')}</p>
                        <p className='text error'>{errorText ? errorText : t('some_error')}</p>
                        {error && error.code && error.code === 'auth/user-not-found' ?
                            <button
                                className='btn btn-sign-in btn-blue'
                                onClick={() => this.switchToForm('create')}
                            >
                                <p>{t('create_account')}</p>
                            </button>
                            : null}
                        {error && email && this.validateEmail(email) && error.code && error.code === 'auth/wrong-password' ?
                            <button
                                className='btn btn-sign-in btn-blue'
                                onClick={this.resetPassword}
                            >
                                <p>{t('reset_password')}</p>
                            </button>
                            : null}
                        <button
                            className='btn btn-sign-in'
                            onClick={this.reset}
                        >
                            <p>{t('cancel')}</p>
                        </button>
                    </div> :
                    showMessage ?
                        <div className='sign-in-div'>
                            <p className='title user-auth'>{`${t('reset_message')} ${email}.`}</p>
                            <button
                                className='btn btn-sign-in'
                                disabled={showLoading}
                                onClick={this.reset}
                            >
                                <p>{t('back')}</p>
                            </button>
                        </div> :
                        showLoading ?
                            <div className='sign-in-div'>
                                <Loader
                                    text={loaderText ? loaderText : t('loading')}
                                    dots={!loaderText}
                                />
                            </div> :
                            form === 'signIn' ?
                                <div className='sign-in-div'>
                                    <p className='title user-auth'>{t('mail_sign')}</p>
                                    {notificationText ?
                                        <p className='notification-text'>{notificationText}</p>
                                        : null
                                    }
                                    <div className='sign-in-input-wrapper'>
                                        <p>{t('email_p')}</p>
                                        <input
                                            className={classNames({ 'valid': email && this.validateEmail(email) })}
                                            type='email'
                                            onChange={this.handleChangeEmail}
                                            disabled={showLoading}
                                            value={email}
                                            placeholder={t('your_email')}
                                            onKeyDown={this.handleSignKeyDown}
                                        />
                                    </div>
                                    <div className='sign-in-input-wrapper'>
                                        <p>{t('password')}</p>
                                        <input
                                            className={classNames('password', { 'valid': password && validatePassword(password) })}
                                            type={inputTypeFirst}
                                            onChange={(e) => this.setState({ password: e.target.value, notificationText: '' })}
                                            disabled={showLoading}
                                            value={password}
                                            placeholder={t('your_password')}
                                            onKeyDown={this.handleSignKeyDown}
                                        />
                                        <img
                                            className='eye'
                                            onClick={() => this.changeInputType(1)}
                                            src={inputTypeFirst && inputTypeFirst === 'password' ? eyeIcon : eyeDisableIcon}
                                            alt='eye'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='no-user'>
                                        {t('sign_in_no_user_1')}
                                        <span
                                            onClick={() => {
                                                this.switchToForm('create');
                                                if (showSignUp) {
                                                    showSignUp(true);
                                                }
                                            }}
                                        >
                                            {t('here')}
                                        </span>
                                        {t('sign_in_no_user_2')}
                                    </p>
                                    <button
                                        className='btn btn-sign-in btn-blue'
                                        disabled={showLoading}
                                        onClick={this.signInWithEmailAndPassword}
                                    >
                                        <p>{t('sign_in')}</p>
                                    </button>
                                    <button
                                        className='btn btn-sign-in'
                                        disabled={showLoading}
                                        onClick={this.closeForm}
                                    >
                                        <p>{t('cancel')}</p>
                                    </button>
                                </div>
                                :
                                <div className='sign-in-div'>
                                    <p className='title user-auth'>{t('mail_sign_up')}</p>
                                    {notificationText ?
                                        <p className='notification-text'>{notificationText}</p>
                                        : null
                                    }
                                    <div className='sign-in-input-wrapper'>
                                        <p>{t('email_p')}</p>
                                        <input
                                            className={classNames({ 'valid': email && this.validateEmail(email) })}
                                            type='email'
                                            onChange={this.handleChangeEmail}
                                            disabled={showLoading}
                                            value={email}
                                            placeholder={t('your_email')}
                                            onKeyDown={this.handleCreateKeyDown}
                                        />
                                    </div>
                                    <p className='pass-text'>
                                        {`${t('pass_text_2')} ${t('pass_text')}`}
                                    </p>
                                    <div className='sign-in-input-wrapper'>
                                        <p>{t('password')}</p>
                                        <input
                                            className={classNames('password', { 'valid': password && validatePassword(password) })}
                                            type={inputTypeFirst}
                                            onChange={(e) => this.setState({ password: e.target.value, notificationText: '' })}
                                            disabled={showLoading}
                                            value={password}
                                            placeholder={t('your_password')}
                                            onKeyDown={this.handleCreateKeyDown}
                                        />
                                        <img
                                            className='eye'
                                            onClick={() => this.changeInputType(1)}
                                            src={inputTypeFirst && inputTypeFirst === 'password' ? eyeIcon : eyeDisableIcon}
                                            alt='eye'
                                            loading='lazy'
                                        />
                                    </div>
                                    <div className='sign-in-input-wrapper'>
                                        <p>{t('confirm_password')}</p>
                                        <input
                                            className={classNames('password', { 'valid': confirmPassword && validatePassword(confirmPassword) })}
                                            type={inputTypeSecond}
                                            onChange={(e) => this.setState({ confirmPassword: e.target.value, notificationText: '' })}
                                            disabled={showLoading}
                                            value={confirmPassword}
                                            placeholder={t('your_confirm_password')}
                                            onKeyDown={this.handleCreateKeyDown}
                                        />
                                        <img
                                            className='eye'
                                            onClick={() => this.changeInputType(2)}
                                            src={inputTypeSecond && inputTypeSecond === 'password' ? eyeIcon : eyeDisableIcon}
                                            alt='eye'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='no-user'>
                                        {t('sign_up_lready_user_1')}
                                        <span
                                            onClick={() => {
                                                this.switchToForm('signIn');
                                                if (showSignUp) {
                                                    showSignUp(false);
                                                }
                                            }}
                                        >
                                            {t('here')}
                                        </span>
                                        {t('sign_up_lready_user_2')}
                                    </p>
                                    <button
                                        className='btn btn-sign-in btn-blue'
                                        disabled={showLoading}
                                        onClick={this.createUserWithEmailAndPassword}
                                    >
                                        <p>{t('create_account')}</p>
                                    </button>
                                    <button
                                        className='btn btn-sign-in'
                                        disabled={showLoading}
                                        onClick={this.closeForm}
                                    >
                                        <p>{t('cancel')}</p>
                                    </button>
                                </div>
                }
            </div>
        );
    }
}

export default withTranslation()(SignInMail);