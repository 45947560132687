import imageCompression from 'browser-image-compression';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/compat/messaging";
import * as appActions from '../reducers/app';
import * as firebaseActions from '../reducers/firebase';
import config from '../config';
import { getTenantId } from './HelperFunctions';

class FirebaseClient {
    constructor(store) {
        this._store = store;
    }

    setStore(store) {
        this._store = store;
    }

    initFirebase() {
        let fireBase = new Promise((resolve, reject) => {
            try {
                const config = {
                    apiKey: process.env.apiKey,
                    authDomain: process.env.authDomain,
                    databaseURL: process.env.databaseURL,
                    projectId: process.env.projectId,
                    storageBucket: process.env.storageBucket,
                    messagingSenderId: process.env.messagingSenderId,
                    appId: process.env.appId,
                    measurementId: process.env.measurementId
                };

                // const config = {
                //     apiKey: "AIzaSyDMc9jNjX_wsjMmAYPgOoosXPhWwdB9VlQ",
                //     authDomain: "strmz.io",
                //     projectId: "streemz-net",
                //     storageBucket: "streemz-net.appspot.com",
                //     messagingSenderId: "104795671843",
                //     appId: "1:104795671843:web:12dfc7be424ba6351f9096",
                //     measurementId: "G-75DKENRK81"
                // };

                firebase.initializeApp(config);
                firebase.analytics();
                // requestMessagingPermission();
                // workerInstance = new Worker(new URL('./messaging-service-worker.js', import.meta.url));
                if (firebase.app().name) {
                    console.log('Firebase initialized');
                    this._store.dispatch(firebaseActions.initFirebase({ init: true }));
                    resolve(true);
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return fireBase;

    }

    logIn(value) {
        if (!firebase.auth().currentUser) {
            let provider = value;
            if (provider === 'google') {
                provider = new firebase.auth.GoogleAuthProvider();
            } else if (provider === 'microsoft') {
                provider = new firebase.auth.OAuthProvider('microsoft.com');
            } else if (provider === 'apple') {
                provider = new firebase.auth.OAuthProvider('apple.com')
            } else {
                provider = new firebase.auth.FacebookAuthProvider();
            }

            provider.setCustomParameters({ prompt: 'select_account' });

            return firebase.auth().signInWithPopup(provider).then(function (result) {
                // var token = result.credential.accessToken;
                // var user = result.user;
                console.log('login');
            });
        } else {
            firebase.auth().signOut();
            return Promise.resolve();
        }
    }

    signInWithEmailAndPassword(email, password) {
        if (email && password) {
            return firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {
                console.log('login signInWithEmailAndPassword');
            })
        } else {
            firebase.auth().signOut();
            return Promise.resolve();
        }
    }

    signInWithCustomToken(token) {
        if (token) {
            firebase.auth().signInWithCustomToken(token).then((userCredential) => {
                console.log('signInWithCustomToken', userCredential)
                // Signed in
                var user = userCredential.user;
                // ...
            })
                .catch((error) => {
                    console.error('signInWithCustomToken', error);
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ...
                });
        } else {

        }
    }

    verifyToken(data) {
        let ref = firebase.functions().httpsCallable('verifyToken');
        return ref(data).then((result) => {
            if (result && result.data) {
                this.signInWithCustomToken(result.data);
            }
        });
    }

    createUserWithEmailAndPassword(email, password) {
        if (email && password) {
            return firebase.auth().createUserWithEmailAndPassword(email, password).then((result) => {
                console.log('create createUserWithEmailAndPassword');
            });
        } else {
            return Promise.resolve();
        }
    }

    sendEmailVerification() {
        let ref = firebase.functions().httpsCallable('sendEmailVerification');
        return ref().then((res) => {
            return res;
        });
    }

    /**
     * Start account pairing
     * @param err Error returned by signInWithPopup
     * @description
     * At this point, you should let the user know that they already have an \
     * account with a different provider, and validate they want to sign in
     * with the new provider.
     * Note: Browsers usually block popups triggered asynchronously, so in
     * real app, you should ask the user to click on a "Continue" button
     * that will trigger signInWithPopup().
     */
    pairAccounts(err) {
        if (err) {
            // User's email already exists.
            console.log('About to start pairing', err)
            // The pending credential.
            let pendingCred = err.credential;
            // The provider account's email address.
            let email = err.email;
            console.log('-++++++++++++++++', pendingCred, email)
            // Get the sign-in methods for this email.
            return firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
                console.log('++++++++++++++++', methods[0], methods)
                // If the user has several sign-in methods, the first method
                // in the list will be the "recommended" method to use.
                let provider;
                switch (methods[0]) {
                    case 'google.com':
                        provider = new firebase.auth.GoogleAuthProvider();
                        break;
                    case 'microsoft.com':
                        provider = new firebase.auth.OAuthProvider('microsoft.com');
                        break;
                    case 'apple.com':
                        provider = new firebase.auth.OAuthProvider('apple.com')
                        break;
                    case 'facebook.com':
                        provider = new firebase.auth.FacebookAuthProvider();
                        break;

                    case 'password': {
                        // TODO: Ask the user for their password.
                        // In real scenario, you should handle this asynchronously.
                        // var password = promptUserForPassword();
                        // firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
                        //     return result.user.linkWithCredential(pendingCred);
                        // }).then(() => {
                        //     // Facebook account successfully linked to the existing user.
                        //     goToApp();
                        // });
                        // return;
                        break;
                    }
                    default:
                        throw (new Error('Unknown auth provider'));
                }
                return firebase.auth().signInWithPopup(provider).then(result => {
                    // Note: Identity Platform doesn't control the provider's sign-in
                    // flow, so it's possible for the user to sign in with an account
                    // with a different email from the first one.

                    // Link the Facebook credential. We have access to the pending
                    // credential, so we can directly call the link method.
                    result.user.linkWithCredential(pendingCred).then(usercred => {
                        // Success.
                        // goToApp();
                    });
                });
            });
        } else {
            console.error('Could not pair accounts. Missing pending account');
            firebase.auth().signOut();
            throw (new Error('Could not pair accounts. Missing pending account'));
        }
    }

    logOut() {
        let logOut = new Promise((resolve, reject) => {
            try {
                firebase.auth().signOut().then(() => {
                    window.location.replace(`http://${config.homeSiteDomain}`);
                });
            } catch (e) {
                console.log('FIREBASE ERROR', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Log out error: ' + reason);
            return Promise.resolve(null);
        });

        return logOut;
    }

    init() {
        let appInit = new Promise((resolve, reject) => {
            try {
                if (firebase.app().name) {
                    firebase.auth().onAuthStateChanged((user) => {
                        if (user && user.uid) {
                            let { uid, displayName, email, photoURL, emailVerified } = user;
                            // console.log('User:', { uid, displayName, email, photoURL });
                            this.getUserData({ uid, displayName, email, photoURL, emailVerified }, true).then(() => {
                                resolve(true);
                            });
                            this.getUserPublicData();
                        } else {
                            this._store.dispatch(firebaseActions.setUser({ auth: false, user: null, userLoaded: true }));
                            this._store.dispatch(firebaseActions.setPublicData({ events: [], videos: [] }));
                            resolve(true);
                        }
                    });
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Aplication error: ' + reason);
            return Promise.resolve(null);
        });

        return appInit;

    }

    getUserPublicData() {
        this._store.dispatch(firebaseActions.setPublicData({ loadedPublicData: false }));
        let getUserPublicDataRef = firebase.functions().httpsCallable('getUserPublicData');
        return getUserPublicDataRef({ website: process.env.website }).then((result) => {
            this._store.dispatch(firebaseActions.setPublicData({ ...result.data, loadedPublicData: true }));
            return result.data;
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getUserData(user, auth) {
        if (!user)
            return Promise.resolve(null);
        const userDetails = {
            uid: user.uid ? user.uid : null,
            displayName: user.displayName ? user.displayName : null,
            photoURL: user.photoURL ? user.photoURL : null,
            email: user.email ? user.email : null,
            emailVerified: user.emailVerified ? user.emailVerified : false
        }
        if (!user.uid) {
            // Should not be here
            console.warn('User without uid found!', user)
            this._store.dispatch(firebaseActions.setUser({ user: userDetails, auth: auth, userLoaded: true }));
            return Promise.resolve(userDetails);
        }
        let rq = firebase.functions().httpsCallable('getUserData');
        return rq(user).then((doc) => {
            // console.log('getUserData response:', doc)
            if (!doc)
                return null;

            let udata = doc.data;

            if (udata && udata.user) {
                let combined = Object.assign({}, userDetails, udata.user);
                this._store.dispatch(firebaseActions.setUser({ user: combined, auth: auth, userLoaded: true }));
                return combined;
            }
            return null;
        }).catch(e => {
            console.error('Could not load user data', e.message);
        });
    }

    isUserLogged() {
        let valid = new Promise((resolve, reject) => {
            const user = firebase.auth().currentUser;

            if (user && user.getIdToken) {
                user.getIdToken(true).then((token) => {
                    if (token) {
                        resolve(true)
                    } else {
                        resolve(false);
                        this.logOut();
                    }
                }).catch((error) => {
                    reject(error);
                    this.logOut();
                    console.log('getIdToken error', error);
                });
            } else {
                resolve(false);
                this.logOut();
            }
        });

        return valid;
    }

    saveCalendarEvent(data) {
        return this.isUserLogged().then((res) => {
            if (res) {
                let callableRef = firebase.functions().httpsCallable('saveCalendarEventFSV2');
                return callableRef(data).then((result) => {
                    return result.data;
                }).catch((error) => {
                    console.error('There was an error when calling the Cloud Function saveCalendarEventFSV2', error);
                });
            }
        }).catch((e) => {
            console.error('User not logged', e);
            throw e;
        });
    }

    getDynamicLink(eventId, type, tenantId = null) {
        let linkRef = firebase.functions().httpsCallable('getDynamicLink');
        let dev = config && config.build && config.build === 'DEV';
        return linkRef({ eventId, type, dev, tenantId }).then((result) => {
            return result.data;
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    async getCopyLink(item, type, tenantId) {
        if (item) {
            let link = null;

            switch (type) {
                case 'admin':
                    link = item.presenterLink;
                    break;
                case 'audience':
                    link = item.audienceLink;
                    break;
                default:
                    console.log('getCopyLink default case');
                    break;
            }

            if (!link) {
                link = await this.getDynamicLink(item.id, type, tenantId);
            }

            return link;
        } else {
            return null;
        }
    }

    handleImageUpload(imageFile) {
        let img = new Promise((resolve, reject) => {
            let size = imageFile.size / 1024 / 1024;
            // console.log('originalFile', imageFile); // true
            // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            // console.log(`originalFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`);

            if (size > 1) {
                var options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }
                imageCompression(imageFile, options)
                    .then(function (compressedFile) {
                        // let size = compressedFile.size / 1024 / 1024;
                        // console.log('compressedFile', compressedFile); // true
                        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                        // console.log(`compressedFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`); // smaller than maxSizeMB

                        resolve(compressedFile); // write your own logic
                    })
                    .catch(function (error) {
                        reject(error.message);
                    });
            } else {
                resolve(imageFile)
            }
        })
        return img;
    }

    addImageToStorage(file, type, id) {
        let storageAdd = new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();

            this.handleImageUpload(file).then((resizeImg) => {
                if (resizeImg) {
                    // Create the file metadata
                    var metadata = {
                        contentType: resizeImg && resizeImg.type ? resizeImg.type : 'image/jpeg'
                    };

                    let folder = 'profile_pictures/';

                    if (type && type === 'event') {
                        folder = 'events_pictures/'
                    }
                    // Upload file and metadata to the object 'images/mountains.jpg'
                    var uploadTask = storageRef.child(folder + id).put(resizeImg, metadata);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        function (snapshot) {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            // console.log('Upload is ' + progress + '% done');
                            // switch (snapshot.state) {
                            //     case firebase.storage.TaskState.PAUSED: // or 'paused'
                            //         // console.log('Upload is paused');
                            //         break;
                            //     case firebase.storage.TaskState.RUNNING: // or 'running'
                            //         // console.log('Upload is running');
                            //         break;
                            // }
                        }, (error) => {
                            reject(error)
                            // switch (error.code) {
                            //     case 'storage/unauthorized':
                            //         // User doesn't have permission to access the object
                            //         break;

                            //     case 'storage/canceled':
                            //         // User canceled the upload
                            //         break;

                            //     case 'storage/unknown':
                            //         // Unknown error occurred, inspect error.serverResponse
                            //         break;
                            // }
                        }, function () {
                            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                // console.log('File available at', downloadURL);
                                return resolve(downloadURL);
                            });
                        });
                }
            });
        })
        return storageAdd;
    }

    getEvent(eventId) {
        let event = new Promise((resolve, reject) => {

            const safeParse = (json) => {
                let ret = null;
                try {
                    ret = JSON.parse(json);
                } catch (e) {
                    console.error('Could not parse data', json)
                }
                return ret;
            }

            const loadEventData = (snapshot) => {
                if (snapshot.exists()) {
                    // console.log('----------------------- Cached data:', snapshot.val());
                    let cached = safeParse(snapshot.val()), event = null;

                    if (cached && cached.length) {
                        cached.filter((item) => {
                            if (item.id === eventId)
                                event = item;
                            return item;
                        });
                    }

                    return event;
                } else {
                    console.log("No cached public data available");
                    return null;
                }
            };

            firebase.database().ref('public/events').get()
                .then(loadEventData)
                .then(async (result) => {
                    if (result !== null) {
                        // console.log('Return RT', result);
                        resolve(this.filterEventItem(result));
                    } else {
                        let { user } = this._store.getState().firebase || {};
                        let tenantId = getTenantId(user);
                        
                        const event = await this.readObject(tenantId ? `tenants/${tenantId}/calendar` : 'calendar', eventId);
                        if (event && event.data) {
                            // console.log('Return FS', event.data);
                            resolve(this.filterEventItem(event.data));
                        }
                    }
                }).catch((e) => {
                    console.error('Could not get public/events', eventId, e);
                });
        });

        return event;
    }

    filterEventItem(event) {
        let eventItem = event;

        if (eventItem) {
            const validKeys = [
                'audienceLinkId',
                'presenterLinkId'
            ];

            Object.keys(eventItem).forEach((key) => !validKeys.includes(key) || delete eventItem[key]);
        }

        return eventItem;
    }

    readObject(collection, id) {
        let readObjectRef = firebase.functions().httpsCallable('readObject');
        return readObjectRef({ collection, id }).then((result) => {
            // console.log("RESULT:", result, id);
            const resultJson = Object.assign({}, result.data, { id: id });
            return { data: resultJson };
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    sendPasswordResetMail(email) {
        let sendPasswordResetRef = firebase.functions().httpsCallable('sendPasswordResetMail');
        return sendPasswordResetRef({ email }).then((result) => {
            console.log('result sendPasswordResetMail', result);
            return;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    changeUserPassword(path, password) {
        let changeUserPasswordRef = firebase.functions().httpsCallable('changeUserPassword');
        return changeUserPasswordRef({ path, password }).then((result) => {
            console.log('result changeUserPassword', result);
            return;
        });
    }

    getEventStats(alias, tenantId) {
        let getEventStatsRef = firebase.functions().httpsCallable('getEventStats');
        return getEventStatsRef({ alias, tenantId }).then((result) => {
            return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    sendSubscriptionRequest(data) {
        let callableRef = firebase.functions().httpsCallable('userSubscriptionRequest');
        return callableRef(data).then((result) => {
            return result.data;
        });
    }

    updateUser(uid, data) {
        let callableRef = firebase.functions().httpsCallable('updateUser');
        return callableRef({ userId: uid, userData: data }).then((result) => {
            let state = this._store.getState();
            if (state && state.firebase && state.firebase.user) {
                let mergedUser = Object.assign({}, state.firebase.user, data);
                this._store.dispatch(firebaseActions.setUser({ user: mergedUser }));
            }
            return result.data;
        });
    }

    getUserSubscriptions() {
        let getUserSubscriptionsRef = firebase.functions().httpsCallable('getUserSubscriptions');
        return getUserSubscriptionsRef().then((result) => {
            return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getVideoLinks(item, download = false, newRecording = false) {
        let ref = firebase.functions().httpsCallable('getVideoLinks');
        return ref({
            alias: item.id,
            authorId: item.uid,
            tenantId: item.tenantId || null,
            download,
            newRecording
        }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not get video links: ${e.message}`)
        });
    }

    updateUserSubscription(subscriptionId, updateData) {
        let ref = firebase.functions().httpsCallable('updateUserSubscription');
        return ref({ subscriptionId, updateData }).then((result) => {
            return result.data;
        });
    }

    deleteHost(subscriptionId, hostEmail) {
        let ref = firebase.functions().httpsCallable('deleteHost');
        return ref({ subscriptionId, hostEmail }).then((result) => {
            return result.data;
        });
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            // eslint-disable-next-line 
            let state = getState();
            let res = next(action);
            switch (action.type) {
                case 'firebase/setUser': {
                    let signIn = state.app.signIn, user = action?.payload?.user, loadedPublicData = state.firebase.loadedPublicData;
                    if (signIn)
                        dispatch(appActions.showSignIn({ signIn: false, signUp: false }));
                    if (user && user.eventAdmin && !loadedPublicData)
                        this.getUserPublicData();
                    break;
                }
                default: {
                    break;
                }
            }
            return res;
        };
    }

    deleteEvent(alias) {
        let setEventStatusRef = firebase.functions().httpsCallable('setEventStatus');
        return setEventStatusRef({ alias, status: 'cancelled' }).then((result) => {
            console.log('result setEventStatus', result);
            return true;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
            return false;
        });
    }

}

export default new FirebaseClient();