import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import moment from 'moment';
import momentZone from "moment-timezone";
import { Editor } from "react-draft-wysiwyg";
import {
    withRouter,
    isMobile,
    getTextDuration,
    defaultColor
} from '../../utils/HelperFunctions';
import ClickOutside from '../Widget/ClickOutside';
import appClient from '../../utils/AppClient';
import closeIcon from '../../resources/icons/x.svg';
import poster from '../../resources/images/strmz-background.png';
import strmzDefault from '../../resources/images/strmz-default.png';
import strmzWideDefault from '../../resources/images/strmzio-logo.png';
import cameraIcon from '../../resources/icons/room/camera-active.svg';
import exitIcon from '../../resources/icons/room/exit.svg';
import handIcon from '../../resources/icons/room/hand.svg';
import hostsIcon from '../../resources/icons/room/hosts.svg';
import chatIcon from '../../resources/icons/room/chat.svg';

class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'desktop'
        }
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({
                tab: 'mobile'
            });
        }

        appClient.disableScroll(true);
    }

    componentWillUnmount() {
        appClient.disableScroll(false);
    }

    render() {
        const { tab } = this.state;
        const { t, preview, closePreview, backgroundLobbyAudience, logo, corporateLogo, title, wideHeaderBanner, narrowHeaderBanner, colorSchemeEventPage, colorSchemeEventTitle, eventSubtitle,
            colorSchemeEventDetails, description, descriptionText, duration, startDate, colorSchemeEventRegistration, narrowSupplementaryPicture, wideSupplementaryPicture, narrowFooterBanner, wideFooterBanner } = this.props;

        return (
            <div className='preview-wrapper'>
                <ClickOutside
                    className='preview-div'
                    clickOutside={closePreview}
                >
                    <div className='preview-tabs'>
                        <button
                            className={classNames('btn btn-tab', { 'active': tab && tab === 'desktop' })}
                            onClick={() => this.setState({ tab: 'desktop' })}
                        >
                            {t('desktop')}
                        </button>
                        <button
                            className={classNames('btn btn-tab', { 'active': tab && tab === 'mobile' })}
                            onClick={() => this.setState({ tab: 'mobile' })}
                        >
                            {t('mobile')}
                        </button>
                    </div>
                    <img
                        src={closeIcon}
                        alt='x'
                        loading='lazy'
                        className='close'
                        onClick={closePreview}
                    />
                    {preview && preview === 'event-page' ?
                        <div
                            className={classNames('preview', { 'mobile': tab && tab === 'mobile' })}
                            style={colorSchemeEventPage && colorSchemeEventPage.bg ?
                                {
                                    background: colorSchemeEventPage.bg ? `rgba(${colorSchemeEventPage.bg.r}, ${colorSchemeEventPage.bg.g}, ${colorSchemeEventPage.bg.b}, ${colorSchemeEventPage.bg.a})` : defaultColor.eventPage.bg
                                }
                                :
                                {
                                    background: defaultColor.eventPage.bg
                                }
                            }
                        >
                            {tab && tab === 'mobile' ?
                                narrowHeaderBanner ?
                                    <img
                                        src={narrowHeaderBanner}
                                        className='branding-header'
                                        alt='branding-header'
                                        loading='lazy'
                                    />
                                    : null
                                : wideHeaderBanner ?
                                    <img
                                        src={wideHeaderBanner}
                                        className='branding-header'
                                        alt='branding-header'
                                        loading='lazy'
                                    />
                                    : null
                            }
                            <div
                                className='lobby-header'
                                style={colorSchemeEventTitle && (colorSchemeEventTitle.color || colorSchemeEventTitle.bg) ?
                                    {
                                        color: colorSchemeEventTitle.color ? `rgba(${colorSchemeEventTitle.color.r}, ${colorSchemeEventTitle.color.g}, ${colorSchemeEventTitle.color.b}, ${colorSchemeEventTitle.color.a})` : defaultColor.eventTitle.color,
                                        background: colorSchemeEventTitle.bg ? `rgba(${colorSchemeEventTitle.bg.r}, ${colorSchemeEventTitle.bg.g}, ${colorSchemeEventTitle.bg.b}, ${colorSchemeEventTitle.bg.a})` : defaultColor.eventTitle.bg
                                    }
                                    :
                                    {
                                        color: defaultColor.eventTitle.color,
                                        background: defaultColor.eventTitle.bg
                                    }
                                }
                            >
                                <div className='event-info'>
                                    <img src={corporateLogo ? corporateLogo : strmzWideDefault} alt='company' loading='lazy' />
                                    <div className='event-title'>
                                        {title ? <p className='title'>{title}</p> : null}
                                        {eventSubtitle ? <p className='sub-title'>{eventSubtitle}</p> : null}
                                    </div>
                                </div>
                            </div>
                            <div className='lobby-body'>
                                <div
                                    className='body-event-details'
                                    style={colorSchemeEventDetails && (colorSchemeEventDetails.color || colorSchemeEventDetails.bg) ?
                                        {
                                            color: colorSchemeEventDetails.color ? `rgba(${colorSchemeEventDetails.color.r}, ${colorSchemeEventDetails.color.g}, ${colorSchemeEventDetails.color.b}, ${colorSchemeEventDetails.color.a})` : defaultColor.eventDetails.color,
                                            background: colorSchemeEventDetails.bg ? `rgba(${colorSchemeEventDetails.bg.r}, ${colorSchemeEventDetails.bg.g}, ${colorSchemeEventDetails.bg.b}, ${colorSchemeEventDetails.bg.a})` : defaultColor.eventDetails.bg
                                        }
                                        :
                                        {
                                            color: defaultColor.eventDetails.color,
                                            background: defaultColor.eventDetails.bg
                                        }
                                    }
                                >
                                    <p className='info-title'>{t('cs_event_details')}</p>
                                    {description && ((typeof description !== 'string' && descriptionText) || typeof description === 'string') ?
                                        <div className='details-line'>
                                            <p>{t('event_description')}:</p>
                                            <div className='value description'>
                                                {typeof description !== 'string' ?
                                                    (
                                                        <Editor
                                                            toolbarHidden
                                                            readOnly
                                                            initialContentState={description}
                                                        />
                                                    )
                                                    : description}
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className='details-line'>
                                        <p>{t('date')}:</p>
                                        <p className='value'>{moment(new Date(startDate ? startDate : moment().unix())).format('L')}</p>
                                    </div>
                                    <div className='details-line'>
                                        <p>{t('time')}:</p>
                                        <p className='value'>{moment(new Date(startDate ? startDate : moment().unix())).format('HH:mm a')}, {moment.tz(momentZone.tz.guess()).zoneAbbr()}</p>
                                    </div>
                                    <div className='details-line'>
                                        <p>{t('duration')}:</p>
                                        <p className='value'>{`${getTextDuration(duration, t('hour'), t('hours'))}`}</p>
                                    </div>
                                </div>
                                <div
                                    className='body-user-details'
                                    style={colorSchemeEventRegistration && (colorSchemeEventRegistration.color || colorSchemeEventRegistration.bg) ?
                                        {
                                            color: colorSchemeEventRegistration.color ? `rgba(${colorSchemeEventRegistration.color.r}, ${colorSchemeEventRegistration.color.g}, ${colorSchemeEventRegistration.color.b}, ${colorSchemeEventRegistration.color.a})` : defaultColor.eventRegistration.color,
                                            background: colorSchemeEventRegistration.bg ? `rgba(${colorSchemeEventRegistration.bg.r}, ${colorSchemeEventRegistration.bg.g}, ${colorSchemeEventRegistration.bg.b}, ${colorSchemeEventRegistration.bg.a})` : defaultColor.eventRegistration.bg
                                        }
                                        :
                                        {
                                            color: defaultColor.eventRegistration.color,
                                            background: defaultColor.eventRegistration.bg
                                        }
                                    }
                                >
                                    <p className='info-title'>{t('event_registration')}</p>
                                    <p className='info-text'>{t('event_text')}</p>
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('your_name')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input type='text' readOnly />
                                    </div>
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('title')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input type='text' readOnly />
                                    </div>
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('company')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input type='text' readOnly />
                                    </div>
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('email')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input type='email' readOnly />
                                    </div>

                                    <button
                                        className='btn btn-join'
                                        style={colorSchemeEventRegistration && colorSchemeEventRegistration.btn ?
                                            {
                                                background: colorSchemeEventRegistration.btn ? `rgba(${colorSchemeEventRegistration.btn.r}, ${colorSchemeEventRegistration.btn.g}, ${colorSchemeEventRegistration.btn.b}, ${colorSchemeEventRegistration.btn.a})` : defaultColor.eventRegistration.btn
                                            }
                                            :
                                            {
                                                background: defaultColor.eventRegistration.btn
                                            }
                                        }
                                    >
                                        {t('join')}
                                    </button>
                                </div>
                            </div>
                            {tab && tab === 'mobile' ?
                                narrowSupplementaryPicture ?
                                    <img
                                        src={narrowSupplementaryPicture}
                                        className='branding-supplementary'
                                        alt='branding-supplementary'
                                        loading='lazy'
                                    />
                                    : null
                                : wideSupplementaryPicture ?
                                    <img
                                        src={wideSupplementaryPicture}
                                        className='branding-supplementary'
                                        alt='branding-supplementary'
                                        loading='lazy'
                                    />
                                    : null
                            }
                            {tab && tab === 'mobile' ?
                                narrowFooterBanner ?
                                    <img
                                        src={narrowFooterBanner}
                                        className='branding-footer'
                                        alt='branding-footer'
                                        loading='lazy'
                                    />
                                    : null
                                : wideFooterBanner ?
                                    <img
                                        src={wideFooterBanner}
                                        className='branding-footer'
                                        alt='branding-footer'
                                        loading='lazy'
                                    />
                                    : null
                            }
                        </div>
                        : preview && preview === 'lobby' ?
                            <div className={classNames('preview room', { 'mobile': tab && tab === 'mobile' })}>
                                <div className='preview-lobby-img'>
                                    <img
                                        src={backgroundLobbyAudience ? backgroundLobbyAudience : poster}
                                        className='preview-poster'
                                        alt='poster'
                                        loading='lazy'
                                    />
                                </div>
                                <div className='preview-lobby-buttons'>
                                    <div className='lobby-left'>
                                        <div className='bottom-bar-details'>
                                            <img src={logo ? logo : strmzDefault} alt='img' />
                                            <p>{title ? title : ''}</p>
                                        </div>
                                    </div>
                                    <div className='lobby-center'>
                                        <div className='button'>
                                            <img src={cameraIcon} alt='inbound' loading='lazy' />
                                            <p>{t('inbound_video')}</p>
                                        </div>
                                        <div className='button'>
                                            <img src={exitIcon} alt='hangup' loading='lazy' />
                                            <p>{t('leave')}</p>
                                        </div>
                                        <div className='button'>
                                            <img src={handIcon} alt='knock' loading='lazy' />
                                            <p>{t('knock')}</p>
                                        </div>
                                    </div>
                                    <div className='lobby-right'>
                                        <div className='button'>
                                            <img src={hostsIcon} alt='hosts' loading='lazy' />
                                            <p>{t('hosts')}</p>
                                        </div>
                                        <div className='button'>
                                            <img src={chatIcon} alt='chat' loading='lazy' />
                                            <p>{t('chat')}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            <div />
                    }
                </ClickOutside>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Preview));