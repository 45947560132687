import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import firebaseClient from '../../utils/FirebaseClient';
import {
    withRouter,
    parseRole
} from '../../utils/HelperFunctions';
import ClickOutside from './ClickOutside';
import Loader from './Loader';
import { parse } from 'json2csv';
import closeIcon from '../../resources/icons/x.svg';
import downloadIcon from '../../resources/icons/download-blue.svg';

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            analytics: null,
            analyticsLoaded: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.transformsItem = this.transformsItem.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keyPress);

        const { analyticsItem } = this.props;

        if (analyticsItem && analyticsItem.id) {
            firebaseClient.getEventStats(analyticsItem.id, analyticsItem.tenantId).then((res) => {
                this.setState({
                    analytics: res.data,
                    analyticsLoaded: true
                }, () => {
                    const { analytics } = this.state;

                    if (!analytics) {
                        this.analyticsTimeout = setTimeout(() => {
                            this.handleClose();
                        }, 5000);
                    }
                });
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyPress);

        if (this.analyticsTimeout) {
            clearTimeout(this.analyticsTimeout)
        }
    }

    getAnalytics(type) {
        const { analytics } = this.state;
        const { t, analyticsItem } = this.props;

        if (analyticsItem && analyticsItem.id && analyticsItem.title && analytics) {
            const fields = [
                { label: t('name'), value: 'name' },
                { label: t('role'), value: 'role' },
                { label: t('email'), value: 'email' },
                { label: t('company'), value: 'company' },
                { label: t('title'), value: 'title' },
                { label: t('hand'), value: 'spoke' },
                { label: t('banned'), value: 'banned' },
                { label: t('time_in_event'), value: 'total' },
                { label: t('registration_time'), value: 'registered' },
                { label: t('first_join'), value: 'firstJoin' },
                { label: t('last_leave'), value: 'lastLeave' }
            ];

            let users = null, banned = {}, spoke = {};

            if (analytics.users)
                users = Object.values(analytics.users);

            if (analytics.banned)
                banned = analytics.banned;

            if (analytics.spoke)
                spoke = analytics.spoke;

            const opts = {
                fields,
                transforms: (item) => this.transformsItem(item, banned, spoke, type)
            };

            let docName = `${type && type === 'all' ? t('participants_analytics') : type && type === 'audience' ? t('audience_analytics') : t('audience_attendance')} - ${analyticsItem.title}.csv`;

            try {
                const csv = parse(users, opts);
                this.downloadFile(docName, csv);
            } catch (err) {
                console.error(err);
            }
        }
    }

    transformsItem(item, banned, spoke, type) {
        const { t } = this.props;

        let retVal = {};

        if (item && type && ((type === 'all') || (type === 'audience' && item.role && item.role === 'audience'))) {
            if (item.name) {
                retVal.name = item.name;
            }
            if (item.title) {
                retVal.title = item.title;
            }
            if (item.company) {
                retVal.company = item.company;
            }
            if (item.role) {
                retVal.role = parseRole(item.role);
            }
            if (item.email) {
                retVal.email = item.email;
                retVal.banned = banned[window.btoa(item.email)] ? t('yes') : t('no');
                retVal.spoke = spoke[window.btoa(item.email)] ? t('yes') : t('no');
            }
            if (item.total) {
                retVal.total = new Date(item.total * 1000).toISOString().substr(11, 8);
            }
            if (item.registered) {
                retVal.registered = moment(new Date(item.registered ? item.registered : moment().unix())).format('L HH:mm:ss');
            }
            if (item.firstJoin) {
                retVal.firstJoin = moment(new Date(item.firstJoin ? item.firstJoin : moment().unix())).format('L HH:mm:ss');
            }
            if (item.lastLeave) {
                retVal.lastLeave = moment(new Date(item.lastLeave ? item.lastLeave : moment().unix())).format('L HH:mm:ss');
            }
        }

        return retVal;
    }

    downloadFile(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    handleClose() {
        const { close } = this.props;

        if (close)
            close();
    }

    keyPress(e) {
        if (e.key === "Escape") {
            this.handleClose();
        }
    }

    render() {
        const { analytics, analyticsLoaded } = this.state;
        const { t, analyticsItem } = this.props;

        return (
            <div className='analytics-wrapper'>
                {!analyticsLoaded ?
                    <Loader
                        text={t('loading_analytics')}
                        dots
                    />
                    : analytics ?
                        <ClickOutside
                            className='analytics-div'
                            clickOutside={this.handleClose}
                        >
                            <img
                                src={closeIcon}
                                alt='x'
                                loading='lazy'
                                className='close'
                                onClick={this.handleClose}
                            />
                            <p className='analytics-title'>{`${t('analytics')} - ${analyticsItem.title ? analyticsItem.title : ''}`}</p>
                            <div className='analytics-line'>
                                <span>{t('total_audience_registrations')}:</span>
                                <span className='line-value download'>
                                    {analytics.total_registered ? analytics.total_registered : '0'}
                                </span>
                                <img
                                    src={downloadIcon}
                                    loading='lazy'
                                    alt='download'
                                    className='download-icon'
                                    onClick={() => this.getAnalytics('all')}
                                />
                            </div>
                            <div className='analytics-line'>
                                <span>{t('max_count')}:</span>
                                <span className='line-value'>{analytics.max_users ? analytics.max_users : '0'}</span>
                            </div>
                            <div className='analytics-line'>
                                <span>{t('total_count')}:</span>
                                <span className='line-value download'>
                                    {analytics.total_participants ? analytics.total_participants : (analytics.total_participants === null || analytics.total_participants === undefined) && analytics.total_users ? analytics.total_users : '0'}
                                </span>
                                <img
                                    src={downloadIcon}
                                    loading='lazy'
                                    alt='download'
                                    className='download-icon'
                                    onClick={() => this.getAnalytics('all')}
                                />
                            </div>
                            <div className='analytics-line'>
                                <span>{t('max_audience')}:</span>
                                <span className='line-value'>{analytics.max_audience ? analytics.max_audience : '0'}</span>
                            </div>
                            <div className='analytics-line'>
                                <span>{t('total_audience')}:</span>
                                <span className='line-value download'>
                                    {analytics.total_audience ? analytics.total_audience : '0'}
                                </span>
                                <img
                                    src={downloadIcon}
                                    loading='lazy'
                                    alt='download'
                                    className='download-icon'
                                    onClick={() => this.getAnalytics('audience')}
                                />
                            </div>
                            <div className='analytics-line'>
                                <span>{t('started')}:</span>
                                <span className='line-value'>{analytics.started ? moment(new Date(analytics.started)).format('L HH:mm:ss') : '0'}</span>
                            </div>
                            <div className='analytics-line'>
                                <span>{t('stopped')}:</span>
                                <span className='line-value'>{analytics.stopped ? moment(new Date(analytics.stopped)).format('L HH:mm:ss') : '0'}</span>
                            </div>
                            <div className='analytics-line'>
                                <span>{t('duration')}:</span>
                                <span className='line-value'>{analytics.duration ? new Date(analytics.duration).toISOString().substr(11, 8) : '0'}</span>
                            </div>
                        </ClickOutside>
                        :
                        <ClickOutside
                            className='analytics-div'
                            clickOutside={this.handleClose}
                        >
                            <img
                                src={closeIcon}
                                alt='x'
                                loading='lazy'
                                className='close'
                                onClick={this.handleClose}
                            />
                            <p className='analytics-title no-margin'>{`${t('no_analytics')} ${analyticsItem.title ? analyticsItem.title : ''}`}</p>
                        </ClickOutside>
                }
            </div>
        )
    }
}

export default withTranslation()(withRouter(Analytics));