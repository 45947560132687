import classNames from "classnames";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as appActions from "../../reducers/app";
import uploadIcon from "../../resources/icons/upload.svg";
import firebaseClient from "../../utils/FirebaseClient";
import { withRouter } from "../../utils/HelperFunctions";
import Loader from "../Widget/Loader";
import config from '../../config';

class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changes: false,
            processing: false,
            username: "",
            firstName: "",
            lastName: "",
            title: "",
            email: "",
            phoneNumber: "",
            companyName: "",
            companyAddress: "",
            companySize: "",
            errorText: "",
            successText: "",
            logo: null,
            addFileDisabled: false
        };

        this.addFileRef = React.createRef();

        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleClickAddImage = this.handleClickAddImage.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    setUserInfo() {
        const { user } = this.props;

        this.setState({
            username: user && user.username ? user.username : "",
            firstName: user && user.firstName ? user.firstName : "",
            lastName: user && user.lastName ? user.lastName : "",
            title: user && user.title ? user.title : "",
            email: user && user.email ? user.email : "",
            companyName: user && user.companyName ? user.companyName : "",
            companyAddress: user && user.companyAddress ? user.companyAddress : "",
            companySize: user && user.companySize ? user.companySize : "",
            phoneNumber: user && user.phoneNumber ? user.phoneNumber : "",
            logo: user ? user.profile_picture1 ? user.profile_picture1 : user.profile_picture ? user.profile_picture : null : null
        });
    }

    componentDidMount() {
        const { user, userLoaded, showSignIn, navigate } = this.props;

        if (!user && userLoaded && showSignIn) {
            showSignIn(true);
        } else if (user) {
            if (user && user.eventAdmin) {
                this.setUserInfo();
            } else if (user && !user.eventAdmin) {
                navigate("/");
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { user, userLoaded, showSignIn, navigate } = this.props;

        if (!user && userLoaded && prevProps.userLoaded !== userLoaded && showSignIn) {
            showSignIn(true);
        }

        if (user && user !== prevProps.user) {
            if (user.eventAdmin) {
                this.setUserInfo();
            } else {
                navigate("/");
            }
        }
    }

    validatePhoneNumber(number) {
        const re = /^\+[1]\d{10}$/;
        return re.test(String(number).toLowerCase());
    }

    checkUserForm() {
        const { username, firstName, lastName, title, phoneNumber, logo } = this.state;
        const { t } = this.props;

        let message = "";

        if (phoneNumber && !this.validatePhoneNumber(phoneNumber)) {
            message = t("valid_20");
        } else if (!username) {
            message = t("valid_8");
        } else if (username && username.length < 2) {
            message = t("valid_9");
        } else if (firstName && firstName.length < 2) {
            message = t("valid_11");
        } else if (lastName && lastName.length < 2) {
            message = t("valid_13");
        } else if (title && title.length < 2) {
            message = t("valid_15");
        } else if (!logo) {
            message = t("valid_21");
        }

        this.setState({
            errorText: message,
        });
    }

    handleSubmitForm() {
        const { username, firstName, lastName, title, phoneNumber, logo, changes } = this.state;
        const { t, user } = this.props;

        if (!username || (username && username.length < 2)
            || (firstName && firstName.length < 2)
            || (lastName && lastName.length < 2)
            || (title && title.length < 2)
            || !logo
            || (phoneNumber && !this.validatePhoneNumber(phoneNumber))) {
            this.checkUserForm();
        } else if (user && user.uid) {
            this.setState({ processing: true });

            if (changes) {
                let updateData = {
                    username,
                    username_lc: username.toLowerCase(),
                    firstName,
                    lastName,
                    title,
                    phoneNumber,
                    profile_picture1: logo
                };

                firebaseClient.updateUser(user.uid, updateData).then(() => {
                    this.setState({
                        processing: false,
                        errorText: "",
                        successText: t("success_participant_message")
                    });
                }).catch((error) => {
                    this.setState({
                        processing: false,
                        successText: "",
                        errorText: t("update_user_error"),
                        changes: false
                    }, () => {
                        this.setUserInfo();
                    });
                });
            } else {
                this.setState({
                    processing: false,
                    errorText: "",
                    successText: t("success_participant_message")
                });
            }
        }
    }

    handleImageChange(event) {
        const { user } = this.props;

        if (event.target.files && event.target.files[0] && user && user.uid) {
            this.setState({
                addFileDisabled: true,
                changes: true
            });
            let img = event.target.files[0];
            let id = `${user.uid}/${uuidv4()}`;
            firebaseClient.addImageToStorage(img, "profile", id).then((res) => {
                this.setState({
                    logo: res,
                    addFileDisabled: false
                });
            });
        }
    }

    handleClickAddImage() {
        if (this.addFileRef && this.addFileRef.current && this.addFileRef.current.click) {
            this.addFileRef.current.click();
        }
    }

    render() {
        const { username, changes, email, firstName, lastName, title, companyName, companyAddress, companySize,
            errorText, successText, processing, phoneNumber, logo, addFileDisabled } = this.state;
        const { t, user } = this.props;

        return (
            <div>
                {user && user.eventAdmin ?
                    <div className="event-scheduler">
                        <div className="event-scheduler-items">
                            <p className="event-scheduler-title">
                                {t("my_profile")}
                            </p>
                            <div className="scheduler-item">
                                <div className="item-header show">
                                    <p>{t("cet_6")}</p>
                                </div>
                                <div className="item-body show">
                                    <div className="section-flex">
                                        <div className="section-left">
                                            <div className="select-dual-wrapper">
                                                <p
                                                    onClick={this.handleClickAddImage}
                                                    className={classNames("select-add-text", { 'disabled': addFileDisabled || processing })}
                                                >
                                                    {logo ?
                                                        <span>
                                                            <img
                                                                src={logo}
                                                                className="event-logo"
                                                                alt="img"
                                                                loading="lazy"
                                                            />
                                                            {t("replace_image")}
                                                        </span>
                                                        :
                                                        <span>
                                                            <img
                                                                src={uploadIcon}
                                                                alt="download"
                                                                loading="lazy"
                                                            />
                                                            {t("image_upload")}
                                                        </span>
                                                    }
                                                    <input
                                                        type="file"
                                                        className="add-file"
                                                        ref={this.addFileRef}
                                                        accept="image/png,image/jpeg,image/svg+xml"
                                                        onChange={this.handleImageChange}
                                                    />
                                                </p>
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("username")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_username")}
                                                    disabled={processing}
                                                    onChange={(e) => this.setState({ username: e.target.value, errorText: "", successText: "", changes: true })}
                                                    value={username}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("first_name")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_fn")}
                                                    disabled={processing}
                                                    onChange={(e) => this.setState({ firstName: e.target.value, errorText: "", successText: "", changes: true })}
                                                    value={firstName}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("last_name")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_ln")}
                                                    disabled={processing}
                                                    onChange={(e) => this.setState({ lastName: e.target.value, errorText: "", successText: "", changes: true })}
                                                    value={lastName}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("title")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_title")}
                                                    disabled={processing}
                                                    onChange={(e) => this.setState({ title: e.target.value, errorText: "", successText: "", changes: true })}
                                                    value={title}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("phoneNumber_text")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_pn")}
                                                    disabled={processing}
                                                    onChange={(e) => this.setState({ phoneNumber: e.target.value, errorText: "", successText: "", changes: true })}
                                                    value={phoneNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="section-right">
                                            <div className="input-wrapper disabled">
                                                <p>{t("companyName")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_cn")}
                                                    disabled
                                                    value={companyName}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("companyAddress")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_ca")}
                                                    disabled
                                                    value={companyAddress}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("companySize")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_cs")}
                                                    disabled
                                                    value={companySize}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <p>{t("email")}</p>
                                                <input
                                                    type="text"
                                                    placeholder={t("enter_email")}
                                                    disabled
                                                    value={email}
                                                />
                                            </div>
                                            <p className="info-text">
                                                {t("mp_1")}
                                                <a
                                                    href={`https://${config.homeSiteDomain}/help#ContactUsform`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t("customer_support")}
                                                </a>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                    {errorText ? <p className="error-text">{errorText}</p> : null}
                                    {successText ? <p className="success-text">{successText}</p> : null}
                                    <div className="buttons">
                                        <button
                                            className="btn btn-save"
                                            onClick={this.handleSubmitForm}
                                            disabled={processing || !changes}
                                        >
                                            {t("save_changes")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Loader
                        text={t('Loading')}
                        dots
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignIn: (value) => {
            dispatch(appActions.showSignIn({ signIn: value, signUp: false }));
        }
    };
};

const MyProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProfile);

export default withTranslation()(withRouter(MyProfileContainer));
