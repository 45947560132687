import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import moment from "moment";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ZH } from "./zh/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_FR } from "./fr/translations";

const options = {
    order: ['navigator']
};

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            zh: {
                translation: TRANSLATIONS_ZH
            },
            es: {
                translation: TRANSLATIONS_ES
            },
            fr: {
                translation: TRANSLATIONS_FR
            }
        },
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (value instanceof Date) return moment(value).format(format);
                if (typeof value === "number") return new Intl.NumberFormat().format(value);
                return value;
            }
        },
        fallbackLng: "en",
        preload: true,
        cleanCode: true
    });