import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  checkPathname
} from './utils/HelperFunctions';
import { Route, Routes } from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
// import Home from './components/Pages/Home';
import NotFound from './components/Pages/NotFound';
import About from './components/Pages/About';
import Pricing from './components/Pages/Pricing';
import EventScheduler from './components/Pages/EventScheduler';
import MyEvents from './components/Pages/MyEvents';
import EventPage from './components/Pages/EventPage';
import ResetPassword from './components/Pages/ResetPassword';
import VerifyAccount from './components/Widget/VerifyAccount';
import Signup from './components/Pages/Signup';
import MyProfile from './components/Pages/MyProfile';
import MySubscription from './components/Pages/MySubscription';
import MyHosts from './components/Pages/MyHosts';

class App extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { user, userLoaded, navigate, location } = this.props;

    if (user && userLoaded && user.eventAdmin && location && checkPathname && !checkPathname('/signup', location.pathname) && !checkPathname('/scheduler', location.pathname) && !checkPathname('/my-hosts', location.pathname) && !checkPathname('/my-subscription', location.pathname) && !checkPathname('/my-profile', location.pathname)) {
      navigate("/my-events?t=upcoming");
    } else if (user && !user.eventAdmin && userLoaded) {
      navigate("/signup");
    }
  }

  componentDidUpdate(prevProps) {
    const { user, userLoaded, navigate, location } = this.props;

    if (prevProps.user !== user) {
      if (user && userLoaded && user.eventAdmin && location && checkPathname && !checkPathname('/signup', location.pathname) && !checkPathname('/scheduler', location.pathname) && !checkPathname('/my-hosts', location.pathname) && !checkPathname('/my-subscription', location.pathname) && !checkPathname('/my-profile', location.pathname)) {
        navigate("/my-events?t=upcoming");
      } else if (user && !user.eventAdmin && userLoaded) {
        navigate("/signup");
      }
    }

    if (location && location.pathname && prevProps.location && prevProps.location.pathname && prevProps.location.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className='app-wrapper'>
        <Header />
        <Routes>
          {/*<Route exact path="/" element={<Home {...this.props} />} />*/}
          <Route exact path="/" element={<MyEvents {...this.props} />} />
          <Route exact path="/about" element={<About {...this.props} />} />
          <Route exact path="/pricing" element={<Pricing {...this.props} />} />
          <Route exact path="/scheduler" element={<EventScheduler {...this.props} />} />
          <Route exact path="/scheduler/:eventId" element={<EventScheduler {...this.props} />} />
          <Route exact path="/my-events" element={<MyEvents {...this.props} />} />
          <Route exact path="/event/:eventId" element={<EventPage {...this.props} />} />
          <Route exact path="/reset-password/:pathId" element={<ResetPassword {...this.props} />} />
          <Route exact path="/signup" element={<Signup {...this.props} />} />
          <Route exact path="/my-profile" element={<MyProfile {...this.props} />} />
          <Route exact path="/my-subscription" element={<MySubscription {...this.props} />} />
          <Route exact path="/my-hosts" element={<MyHosts {...this.props} />} />
          <Route path="*" element={<NotFound {...this.props} />} />
        </Routes>
        <Footer />
        <VerifyAccount />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.firebase.user,
    signIn: state.app.signIn,
    userLoaded: state.firebase.userLoaded
  };
};

const AppContainer = connect(
  mapStateToProps
)(App);

export default withRouter(AppContainer);