import React from 'react';

function Loader(props) {
    return (
        <div className='lds-dual-ring'>
            {props.text ?
                <p className='lds-text'>{props.text}{props.dots ? <span className='dot-flashing' /> : null}</p>
                : null
            }
        </div>
    )
}

export default Loader;