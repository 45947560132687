import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import { 
    withRouter,
    validatePassword
} from '../../utils/HelperFunctions';
import Loader from '../Widget/Loader';
import firebaseClient from '../../utils/FirebaseClient';
import eyeIcon from '../../resources/icons/eye.svg';
import eyeDisableIcon from '../../resources/icons/disable_eye.svg';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            processing: false,
            password: '',
            confirmPassword: '',
            errorText: '',
            successText: '',
            pathId: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.changeInputType = this.changeInputType.bind(this);
        this.updateUserPassword = this.updateUserPassword.bind(this);
    }

    componentDidMount() {
        const { params } = this.props;

        if (params && params.pathId) {
            this.setState({
                pathId: params.pathId
            });
        }
    }

    componentWillUnmount() {
        if (this.succesTimeout) {
            clearTimeout(this.succesTimeout);
        }
    }

    updateUserPassword() {
        const { password, confirmPassword, pathId } = this.state;
        const { t } = this.props;

        if (!pathId || !password || (password && !validatePassword(password)) || !confirmPassword || (confirmPassword && !validatePassword(confirmPassword)) || password !== confirmPassword) {
            this.checkForm();
        } else {
            this.setState({ processing: true });
            firebaseClient.changeUserPassword(pathId, password).then(() => {
                this.setState({
                    error: null,
                    processing: false,
                    password: '',
                    confirmPassword: '',
                    errorText: '',
                    successText: t('success_password'),
                    inputTypeFirst: 'password',
                    inputTypeSecond: 'password'
                }, () => {
                    this.succesTimeout = setTimeout(() => {
                        const { navigate } = this.props;
                        if (navigate)
                            navigate('/');
                    }, 3000);
                });
            }).catch(error => {
                this.setState({
                    error: error,
                    processing: false,
                    password: '',
                    confirmPassword: '',
                    errorText: t('some_error'),
                    successText: '',
                    inputTypeFirst: 'password',
                    inputTypeSecond: 'password'
                });
            });
        }
    }

    reset() {
        this.setState({
            error: null,
            processing: false,
            password: '',
            confirmPassword: '',
            errorText: '',
            successText: '',
            pathId: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.updateUserPassword();
        }
    }

    changeInputType(value) {
        const { inputTypeFirst, inputTypeSecond } = this.state;

        switch (value) {
            case 1:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
            case 2:
                if (inputTypeSecond && inputTypeSecond === 'password') {
                    this.setState({
                        inputTypeSecond: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeSecond: 'password'
                    });
                }
                break;
            default:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
        }
    }

    checkForm() {
        const { password, confirmPassword } = this.state;
        const { t } = this.props;

        let message = '';

        if (!password) {
            message = t('valid_3');
        } else if (password && !validatePassword(password)) {
            message = t('valid_4');
        } else if (!confirmPassword) {
            message = t('valid_5');
        } else if (confirmPassword && !validatePassword(confirmPassword)) {
            message = t('valid_6');
        } else if (password !== confirmPassword) {
            message = t('valid_7');
        }

        this.setState({
            errorText: message
        });
    }

    render() {
        const { processing, password, confirmPassword, inputTypeFirst, inputTypeSecond, errorText, successText } = this.state;
        const { t } = this.props;

        return (
            <div>
                {!processing ?
                    <div className='event-scheduler'>
                        <div className='event-scheduler-items'>
                            <p className='event-scheduler-title'>{t('reset_password')}</p>
                            <div className='scheduler-item'>
                                <div className='item-body show'>
                                    <div className='section-flex'>
                                        <div className='section-left'>
                                            <div className='input-wrapper'>
                                                <p>{t('new_password')}</p>
                                                <input
                                                    className={classNames('password', { 'valid': password && validatePassword(password) })}
                                                    type={inputTypeFirst}
                                                    placeholder={t('enter_new_password')}
                                                    disabled={processing}
                                                    onChange={(e) => this.setState({ password: e.target.value, errorText: '', successText: '', })}
                                                    value={password}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                <img
                                                    className='eye'
                                                    onClick={() => this.changeInputType(1)}
                                                    src={inputTypeFirst && inputTypeFirst === 'password' ? eyeIcon : eyeDisableIcon}
                                                    alt='eye'
                                                    loading='lazy'
                                                />
                                            </div>
                                            <div className='input-wrapper'>
                                                <p>{t('confirm_password')}</p>
                                                <input
                                                    className={classNames('password', { 'valid': confirmPassword && validatePassword(confirmPassword) })}
                                                    type={inputTypeSecond}
                                                    placeholder={t('enter_confirm_password')}
                                                    disabled={processing}
                                                    onChange={(e) => this.setState({ confirmPassword: e.target.value, errorText: '', successText: '', })}
                                                    value={confirmPassword}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                <img
                                                    className='eye'
                                                    onClick={() => this.changeInputType(2)}
                                                    src={inputTypeSecond && inputTypeSecond === 'password' ? eyeIcon : eyeDisableIcon}
                                                    alt='eye'
                                                    loading='lazy'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {errorText ? <p className='error-text'>{errorText}</p> : null}
                                    {successText ? <p className='success-text'>{successText}</p> : null}
                                    <div className='buttons'>
                                        <button
                                            className='btn btn-save'
                                            onClick={this.updateUserPassword}
                                            disabled={processing}
                                        >
                                            {t('change_password')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Loader
                        text={t('resetting_password')}
                        dots
                    />
                }
            </div>
        )
    }
}

export default withTranslation()(withRouter(ResetPassword));