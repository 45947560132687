class AppClient {

    constructor(store) {
        this._store = store;
    }

    setStore(store) {
        this._store = store;
    }

    disableScroll(value) {
        if (value) {
            // document.body.style.overflow = "hidden";
            document.body.classList.add("no-scroll")
        } else {
            // document.body.style.overflow = "auto"
            document.body.classList.remove("no-scroll")
        }
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            // let state = getState();
            let res = next(action);
            switch (action.type) {
                case 'app/showMenu': {
                    // console.log('call showMenu action', action);
                    this.disableScroll(action.payload.menu);
                    break;
                }
                case 'app/showSignIn': {
                    // console.log('call showSignIn action', action);
                    this.disableScroll(action.payload.signIn);
                    break;
                }
                default: {
                    break;
                }
            }
            return res;
        };
    }
}

export default new AppClient();